import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SET,
  LOGOUT,
  SELECT_RESTAURANT,
  GROUBIE_DETAILS_SUCCESS,
  CLEAR_ERROR,
  VERIFY_LOGIN,
} from "../constants";

const initialState = {
  loading: false,

  token: "",
  user: null,
  restaurants: [],

  selected: null,

  total_reservations: null,
  total_discounts: null,
};
export const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case LOGIN_REQUEST:
      return { ...state, loading: true };
    case LOGIN_SUCCESS:
      return { ...state, loading: false, token: action.token };
    case LOGIN_FAIL:
      return { ...state, loading: false, error: action.error };
    case VERIFY_LOGIN:
      return { ...state, loading: true };
    case LOGIN_SET:
      return {
        ...state,
        loading: false,
        token: action.token,
        user: action.user,
        restaurants: action.restaurants,
        selected: action.selected,
      };
    case SELECT_RESTAURANT:
      return { ...state, selected: action.selected };
    case CLEAR_ERROR:
      return { ...state, error: null };
    case GROUBIE_DETAILS_SUCCESS:
      return {
        ...state,
        total_reservations: action.total_reservations,
        total_discounts: action.total_discounts,
      };
    default:
      return state;
  }
};
