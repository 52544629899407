import React, { Component } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Colors, Fonts } from "../themes";
import { ConditionsProvider } from "../context/ConditionsContext";
import { ConditionsList } from "../components/conditions/ConditionsList";
import { ConditionModal } from "../components/conditions/ConditionModal";

class ConditionsScreen extends Component {
  render() {
    return (
      <ConditionsProvider>
        <View style={styles.container}>
          <Text style={styles.title}>Dashboard de Condições</Text>
          <ConditionsList />
          <ConditionModal />
        </View>
      </ConditionsProvider>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    paddingTop: 22,
    paddingHorizontal: 22,
    paddingBottom: 100,
    overflow: "auto",
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginBottom: 30,
  },
});

export default ConditionsScreen;
