import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/table";
import { useConditions } from "../../context/ConditionsContext";
import { Button } from "../ui/button";

export const ConditionsList = () => {
  const {
    conditions,
    goConditions,
    generalConditions,
    loading,
    openModal,
    openHighlight,
    createHighlight,
  } = useConditions();

  if (loading || !conditions || !goConditions || !generalConditions)
    return <></>;

  return (
    <>
      <section className="mb-6">
        <div className="w-full flex justify-between items-end">
          <h1 className="text-lg mb-2 font-bold text-slate-600">
            Condições Gerais
          </h1>

          <Button className="mb-2" onClick={createHighlight}>
            Adicionar
          </Button>
        </div>
        <Table>
          <TableHeader>
            <TableRow head>
              <TableHead className="font-bold">Texto</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {generalConditions.highlights.map((item) => (
              <TableRow
                key={item.id}
                className="cursor-pointer"
                onClick={() => openHighlight(generalConditions, item)}
              >
                <TableCell className="text-slate-500">{item.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>

      <section className="mb-6">
        <h1 className="text-lg mb-2 font-bold text-slate-600">Desconto</h1>
        <Table>
          <TableHeader>
            <TableRow head>
              <TableHead className="w-[360px] font-bold">Condição</TableHead>
              <TableHead className="font-bold">Texto</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {conditions.map((item) => (
              <TableRow
                key={item.id}
                className="cursor-pointer"
                onClick={() => openModal(item)}
              >
                <TableCell className="text-slate-500">
                  {item.description}
                </TableCell>
                <TableCell className="text-slate-500">{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>

      <section className="mb-6">
        <h1 className="text-lg mb-2 font-bold text-slate-600">2 em 1</h1>
        <Table>
          <TableHeader>
            <TableRow head>
              <TableHead className="w-[360px] font-bold">Condição</TableHead>
              <TableHead className="font-bold">Texto</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {goConditions.map((item) => (
              <TableRow
                key={item.id}
                className="cursor-pointer"
                onClick={() => openModal(item)}
              >
                <TableCell className="text-slate-500">
                  {item.description}
                </TableCell>
                <TableCell className="text-slate-500">{item.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    </>
  );
};
