/**
 * @provides fonts
 */
import { Platform } from "react-native";
// import { normalize } from '../../utils/fontNormalize';

const type = {
  medium:
    Platform.OS === "ios" || Platform.OS === "web"
      ? "Ubuntu-Medium"
      : "ubuntu_medium",
  regular:
    Platform.OS === "ios" || Platform.OS === "web"
      ? "Ubuntu-Regular"
      : "ubuntu_regular",
  bold:
    Platform.OS === "ios" || Platform.OS === "web"
      ? "Ubuntu-Bold"
      : "ubuntu_bold",
  italic:
    Platform.OS === "ios" || Platform.OS === "web"
      ? "Ubuntu-Italic"
      : "ubuntu_italic",
  light:
    Platform.OS === "ios" || Platform.OS === "web"
      ? "Ubuntu-Light"
      : "ubuntu_light",
  emphasis: "HelveticaNeue-Italic",
};

const size = {
  title: 24,
  profile: 14,
};

const style = {
  title: {
    fontFamily: type.medium,
    fontSize: size.title,
  },
  header: {
    fontFamily: type.medium,
    fontSize: size.header,
  },
};

const Fonts = {
  type,
  size,
  style,
};

export default Fonts;
