import React, { Component } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import Input from "../components/Input";
import Restaurant from "../components/Restaurant";
import Filters from "../components/Filters";
import { Colors, Fonts } from "../themes";
import {
  RESTAURANT_LIST_REQUEST,
  RESTAURANT_SEARCH,
  RESTAURANT_PAUSE_LIST_REQUEST,
  RESTAURANT_PAUSE_CHANGE_REQUEST,
  RESTAURANT_RESERVES_LIST_REQUEST,
  DASHBOARD_SET_VALUE,
  DASHBOARD_CHANGE_FILTER,
  RESTAURANT_CHANGE_ACTIVE,
} from "../constants";

class DashboardScreen extends Component {
  static defaultProps = {
    onEditClick: () => {},
  };

  state = {
    filter: 3,
  };

  componentDidMount() {
    this.props.load();
  }

  goToPage(restaurant) {
    // const isDevelopment = process.env.NODE_ENV === "development";
    // if (isDevelopment) {
    this.props.history.push(`/restaurant/${restaurant.id}`);
    // } else {
    //   this.props.onEditClick(restaurant.id);
    // }
  }

  getRestaurants(restaurants) {
    return restaurants.filter((restaurant) => {
      var result = true;
      if (this.props.filter_city.length > 0) {
        result =
          result &&
          restaurant.city &&
          this.props.filter_city.includes(restaurant.city.id);
      }
      if (this.props.filter_active.length > 0) {
        result =
          result &&
          restaurant.active === (this.props.filter_active[0] === "Ativo");
      }
      return result;
    });
  }

  renderRestaurants() {
    if (this.props.loading) {
      return (
        <ActivityIndicator
          style={{ alignSelf: "flex-start" }}
          size="large"
          color={Colors.primary_dark}
        />
      );
    }

    return this.getRestaurants(this.props.restaurants).map((restaurant) => {
      return (
        <Restaurant
          restaurant={restaurant}
          key={restaurant.id}
          onSelectDate={(date, isGo, onSuccess) =>
            this.props.reserves_day(restaurant.id, isGo, date, onSuccess)
          }
          onPauseListRequest={(isGo, onSuccess) =>
            this.props.pausas(restaurant.id, isGo, onSuccess)
          }
          onPauseDayClick={(isGo, date, hour, onSuccess) =>
            this.props.change_pause_day(
              restaurant.id,
              isGo,
              date,
              hour,
              onSuccess
            )
          }
          onEditClick={(restaurant) => this.goToPage(restaurant)}
          onAcitveClick={(id, onSuccess, onFail) =>
            this.props.change_active(id, onSuccess, onFail)
          }
        />
      );
    });
  }

  render() {
    return (
      <View style={styles.container}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          <Text style={styles.title}>Dashboard Geral de Restaurantes</Text>
          <Input
            icon="search"
            placeholder="Nome do restaurante"
            onChangeValue={(value) => this.props.search(value, this.props.all)}
            style={{
              marginLeft: 0,
              marginTop: 0,
              marginBottom: 30,
              width: 320,
            }}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            zIndex: 5000,
            position: "relative",
          }}
        >
          <View style={{ flexDirection: "row", height: 100, marginTop: 15 }}>
            <Text style={[styles.title, { fontSize: 18, marginBottom: 0 }]}>
              Todos ({this.getRestaurants(this.props.restaurants, 3).length})
            </Text>
          </View>
          <View style={{ minWidth: 280, position: "absolute", right: 0 }}>
            {!this.props.loading && (
              <Filters
                cities={this.props.cities}
                onChangeFilters={(cities, actives) => {
                  this.props.change_filter(this.props.all, cities, actives);
                }}
              />
            )}
          </View>
        </View>
        <View
          style={[
            styles.itensContainer,
            this.props.loading
              ? { justifyContent: "center", alignItems: "center" }
              : {},
          ]}
        >
          {this.renderRestaurants()}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 22,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginBottom: 30,
  },
  itensContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  filterButton: {
    marginRight: 30,
  },
  filterLine: {
    marginTop: 5,
    height: 3,
    backgroundColor: Colors.primary,
    borderRadius: 2,
  },
});

const mapStateToProps = (state) => ({
  loading: state.dashboard.loading,
  all: state.dashboard.all,
  restaurants: state.dashboard.restaurants,
  filter_city: state.dashboard.filter_city,
  filter_active: state.dashboard.filter_active,
  cities: state.dashboard.cities,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setValue: (key, value) => {
      dispatch({ type: DASHBOARD_SET_VALUE, key, value });
    },
    load: () => {
      dispatch({ type: RESTAURANT_LIST_REQUEST });
    },
    change_filter: (restaurants, filter_city, filter_active) => {
      dispatch({
        type: DASHBOARD_CHANGE_FILTER,
        restaurants,
        filter_city,
        filter_active,
      });
    },
    search: (name, restaurants) => {
      dispatch({ type: RESTAURANT_SEARCH, name, restaurants });
    },
    pausas: (id, isGo, onSuccess) => {
      dispatch({ type: RESTAURANT_PAUSE_LIST_REQUEST, id, isGo, onSuccess });
    },
    change_pause_day: (id, isGo, date, hour, onSuccess) => {
      dispatch({
        type: RESTAURANT_PAUSE_CHANGE_REQUEST,
        id,
        isGo,
        date,
        hour,
        onSuccess,
      });
    },
    reserves_day: (id, isGo, date, onSuccess) => {
      dispatch({
        type: RESTAURANT_RESERVES_LIST_REQUEST,
        id,
        isGo,
        date,
        onSuccess,
      });
    },
    change_active: (id, onSuccess, onFail) => {
      dispatch({ type: RESTAURANT_CHANGE_ACTIVE, id, onSuccess, onFail });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardScreen);
