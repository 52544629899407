import { call, put } from "redux-saga/effects";
import {
  FEED_SUCCESS,
  FEED_FAIL,
  OPTIONS_SUCCESS,
  FEED_REQUEST,
  HISTORICO_SUCCESS,
  HISTORICO_FAIL,
  OPTIONS_FAIL,
  AVALIACAO_SUCCESS,
  AVALIACAO_FAIL,
  // AVALIACAO_COMMENT_SUCCESS,
  // AVALIACAO_COMMENT_FAIL,
  HISTORICO_REQUEST,
  AVALIACAO_REQUEST,
} from "../constants";

export function* options(api, action) {
  try {
    const { restaurant_id, screen } = action;
    const response = yield call(api.options, restaurant_id, screen);
    if (response.problem !== null) {
      throw new Error("");
    }
    const options = response.data.options;
    yield put({ type: OPTIONS_SUCCESS, options: options, screen });
    if (screen === "feed") {
      yield put({
        type: FEED_REQUEST,
        restaurant_id,
        start: options[0],
        end: options[0],
        includeGo: true,
      });
    }
    if (screen === "historico") {
      yield put({
        type: HISTORICO_REQUEST,
        restaurant_id,
        start: options[0].initial_date,
        end: options[0].final_date,
        includeGo: false,
      });
    }
    if (screen === "avaliacao") {
      yield put({
        type: AVALIACAO_REQUEST,
        restaurant_id,
        start: options[0].initial_date,
        end: options[0].final_date,
        includeGo: true,
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: OPTIONS_FAIL, screen: action.screen });
  }
}

export function* feed(api, action) {
  try {
    const { restaurant_id, start, end, includeGo } = action;
    const response = yield call(api.feed, restaurant_id, start, end, includeGo);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: FEED_SUCCESS,
      reservations: response.data.reservations,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: FEED_FAIL });
  }
}

export function* historico(api, action) {
  try {
    const { restaurant_id, start, end, includeGo } = action;
    const response = yield call(api.feed, restaurant_id, start, end, includeGo);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: HISTORICO_SUCCESS,
      reservations: response.data.reservations,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: HISTORICO_FAIL });
  }
}

export function* avaliacao(api, action) {
  try {
    const { restaurant_id, start, end, nao_respondidas } = action;
    const response = yield call(
      api.avaliacao,
      restaurant_id,
      start,
      end,
      nao_respondidas
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: AVALIACAO_SUCCESS, scores: response.data.scores });
  } catch (e) {
    console.error(e);
    yield put({ type: AVALIACAO_FAIL });
  }
}

export function* avaliacao_comment(api, action) {
  try {
    const { id, from, comment, onSuccess } = action;
    const response = yield call(api.post_comment, id, from, comment);
    if (response.problem !== null) {
      throw new Error("");
    }
    if (onSuccess) {
      onSuccess(response.data);
    }
  } catch (e) {
    console.error(e);
    if (action.onFail) {
      action.onFail();
    }
  }
}
