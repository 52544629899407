// import { Platform } from "react-native";
import { call, put } from "redux-saga/effects";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SET,
  GROUBIE_DETAILS_SUCCESS,
} from "../constants";
// import messaging from '@react-native-firebase/messaging';
import AsyncStorage from "@react-native-community/async-storage";

export function* logout(api, action) {
  yield AsyncStorage.removeItem("token");
  yield AsyncStorage.removeItem("restaurant_id");
}

export function* auth(api, action) {
  try {
    const { email, password } = action;

    const response = yield call(api.auth, email, password);
    if (response.problem !== null) {
      throw new Error("");
    }
    const token = response.data.token;
    yield AsyncStorage.setItem("token", token);
    yield call(api.setHeader, "Authorization", `Bearer ${token}`);
    yield put({ type: LOGIN_SUCCESS, token: token });
  } catch (e) {
    yield put({ type: LOGIN_FAIL, error: { type: "AUTHENTICATION" } });
  }
}

export function* verificar_login(api, action) {
  try {
    const token = yield AsyncStorage.getItem("token");
    if (token) {
      yield call(api.setHeader, "Authorization", `Bearer ${token}`);
      const response = yield call(api.account);

      if (response.data.id && response.data.is_superuser) {
        yield put({
          type: LOGIN_SET,
          token,
          user: response.data,
        });
      }
    } else {
      throw new Error("Usuário inválido");
    }
  } catch (e) {
    yield put({
      type: LOGIN_SET,
      token: null,
      user: null,
      restaurants: [],
      selected: null,
    });
  }
}

export function* verifyGroubieDetails(api, action) {
  var total_reservations = "0";
  var total_discounts = "0";
  try {
    total_reservations = yield AsyncStorage.getItem("total_reservations");
    total_discounts = yield AsyncStorage.getItem("total_discounts");

    if (total_reservations === null) {
      total_reservations = "153000";
    }
    if (total_discounts) {
      total_discounts = "2000000";
    }

    const response = yield call(api.groubieDetails);
    if (response.problem !== null) {
      throw new Error("");
    }

    total_reservations = response.data.total_reservations.toString();
    total_discounts = response.data.total_discounts.toString();

    yield AsyncStorage.setItem("total_reservations", total_reservations);
    yield AsyncStorage.setItem("total_discounts", total_discounts);

    yield put({
      type: GROUBIE_DETAILS_SUCCESS,
      // total_reservations: `${total_reservations.replace(
      //   /\B(?=(\d{3})+(?!\d))/g,
      //   "."
      // )}`,
      // total_discounts: `${total_discounts.replace(
      //   /\B(?=(\d{3})+(?!\d))/g,
      //   "."
      // )}`,
      total_reservations: "1",
      total_discounts: "1",
    });
  } catch (e) {
    yield put({
      type: GROUBIE_DETAILS_SUCCESS,
      // total_reservations: `${total_reservations.replace(
      //   /\B(?=(\d{3})+(?!\d))/g,
      //   "."
      // )}`,
      // total_discounts: `${total_discounts.replace(
      //   /\B(?=(\d{3})+(?!\d))/g,
      //   "."
      // )}`,
      total_reservations: "1",
      total_discounts: "1",
    });
  }
}
