import React, { Component } from "react";
import { StyleSheet, View, Text, ActivityIndicator } from "react-native";
import { connect } from "react-redux";
import { Colors, Fonts } from "../themes";
// import Calendar from 'react-calendar';
import Block from "../components/Block";
import Contestacao from "../components/Contestacao";
// import moment from "moment";

import {
  RESERVATION_LIST_REQUEST,
  RESERVATION_SAVE_REQUEST,
} from "../constants";

class ContestacaoScreen extends Component {
  state = {
    selectedDates: [],
  };

  componentDidMount() {
    this.props.load("");
  }

  onSelectDate(date) {
    const dates = this.state.selectedDates;
    if (dates.includes(date)) {
      dates.splice(dates.indexOf(date), 1);
    } else {
      dates.push(date);
    }

    this.setState({ selectedDates: [...dates] }, () => {
      this.props.load(this.state.selectedDates.join(","));
    });
  }

  renderCalendar() {
    return (
      <View style={{ padding: 15 }}>
        {/*<Calendar*/}
        {/*    className={['calendar']}*/}
        {/*    locale="pt-BR"*/}
        {/*    tileClassName={({ date, view }) => {*/}
        {/*        const dateString = moment(date).format('YYYY-MM-DD');*/}
        {/*        if (this.state.selectedDates.includes(dateString)) {*/}
        {/*            return ['date_selected2'];*/}
        {/*        }*/}

        {/*        return ['date_unselected'];*/}
        {/*    }}*/}
        {/*    onClickDay={(date) => {*/}
        {/*        const dateString = moment(date).format('YYYY-MM-DD');*/}
        {/*        this.onSelectDate(dateString);*/}
        {/*    }}*/}
        {/*/>*/}
      </View>
    );
  }

  renderReservations() {
    return Object.keys(this.props.reservations).map((key) => {
      const reservation_date = this.props.reservations[key];

      if (reservation_date.restaurants.length > 0) {
        return (
          <Block
            key={key}
            title={reservation_date.description}
            style={{ maxWidth: "auto" }}
          >
            {reservation_date.restaurants.map((restaurant, restaurantIndex) => {
              return (
                <React.Fragment key={restaurantIndex}>
                  <Text
                    style={[
                      styles.title,
                      { fontSize: 14, marginBottom: 5, marginTop: 30 },
                    ]}
                  >
                    {restaurant.description}
                  </Text>
                  {restaurant.reservations.map(
                    (reservation, reservationIndex) => {
                      return (
                        <Contestacao
                          key={reservationIndex}
                          restaurant={restaurant}
                          reservation={reservation}
                          onSave={(
                            id,
                            problem,
                            seats,
                            contacted,
                            image,
                            onSuccess,
                            onFail
                          ) =>
                            this.props.save_contestacao(
                              id,
                              problem,
                              seats,
                              contacted,
                              image,
                              onSuccess,
                              onFail
                            )
                          }
                        />
                      );
                    }
                  )}
                </React.Fragment>
              );
            })}
          </Block>
        );
      }
      return null;
    });
  }

  render() {
    return (
      <View style={styles.container}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Text style={styles.title}>Constestação Geral de Restaurantes</Text>
        </View>
        <View
          style={[
            styles.divider_vertical,
            { position: "absolute", right: "35%", top: 0 },
          ]}
        />
        <View style={{ flex: 1, flexDirection: "row" }}>
          <View style={{ flex: 2 }}>
            {this.props.loading && (
              <ActivityIndicator
                style={{ alignSelf: "center" }}
                size="large"
                color={Colors.primary_dark}
              />
            )}
            {!this.props.loading && (
              <Text style={[styles.title, { fontSize: 18 }]}>Timeline</Text>
            )}
            {!this.props.loading && this.renderReservations()}
          </View>
          <View style={{ flex: 1, paddingLeft: 50 }}>
            <Text style={[styles.title, { fontSize: 18 }]}>Calendário</Text>
            <View
              className="block"
              style={{ width: 272, borderRadius: 5, overflow: "hidden" }}
            >
              {this.renderCalendar()}
              <View style={styles.divider} />
              <View style={{ padding: 15 }}>
                <Text
                  style={[
                    styles.title,
                    { fontSize: 13, alignSelf: "center", marginBottom: 15 },
                  ]}
                >
                  ÚLTIMOS 7 DIAS
                </Text>
                {this.props.last_days.map((day, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        marginBottom: 5,
                      }}
                    >
                      <View style={styles.day}>
                        <Text style={styles.dayText}>{day.day}</Text>
                      </View>
                      <Text
                        style={[
                          styles.dayText,
                          { fontSize: 13, alignSelf: "center", marginLeft: 15 },
                        ]}
                      >
                        {day.description}
                      </Text>
                    </View>
                  );
                })}
              </View>
              <View style={styles.divider} />
              <View style={{ padding: 15 }}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={[styles.day, { backgroundColor: Colors.accent }]}
                  >
                    <Text style={[styles.dayText, { color: Colors.white }]}>
                      30
                    </Text>
                  </View>
                  <Text
                    style={[
                      styles.dayText,
                      { fontSize: 13, alignSelf: "center", marginLeft: 15 },
                    ]}
                  >
                    Dia final do acompanhamento
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 22,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginBottom: 30,
  },
  divider: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.divider,
  },
  divider_vertical: {
    height: "100%",
    width: 1,
    backgroundColor: Colors.divider,
  },
  day: {
    width: 25,
    height: 25,
    borderRadius: 2,
    backgroundColor: Colors.fill,
    justifyContent: "center",
    alignItems: "center",
  },
  dayText: {
    fontFamily: Fonts.type.regular,
    fontSize: 11,
    color: Colors.text,
  },
});

const mapStateToProps = (state) => ({
  loading: state.reservation.loading,
  reservations: state.reservation.reservations,
  last_days: state.reservation.last_days,
});

const mapDispatchToProps = (dispatch) => {
  return {
    load: (dates) => {
      dispatch({ type: RESERVATION_LIST_REQUEST, dates });
    },
    save_contestacao: (
      id,
      problem,
      seats,
      contacted,
      image,
      onSuccess,
      onFail
    ) => {
      dispatch({
        type: RESERVATION_SAVE_REQUEST,
        id,
        problem,
        seats,
        contacted,
        image,
        onSuccess,
        onFail,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContestacaoScreen);
