import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import RestaurantScreen from "./RestaurantScreen";
import DashboardScreen from "./DashboardScreen";
import ContestacaoScreen from "./ContestacaoScreen";
import { Colors, Images } from "../themes";
import AcompanhamentoScreen from "./AcompanhamentoScreen";
import DynamicLinksScreen from "./DynamicLinksScreen";
import ConditionsScreen from "./ConditionsScreen";

// const DASHBOARD_URL = "/#/dashboard";
// const CADASTRO_URL = "/#/cadastro";
// const CONTESTACAO_URL = "/#/contestacao";
// const RESTAURANTE_URL = "/#/restaurante";
// const ACOMPANHAMENTO_URL = "/#/acompanhamento";
// const LINKS_URL = "/#/links";

class NavigatorScreen extends Component {
  state = {
    page: "",
    force_render: false,
  };

  componentDidMount() {
    // const isDevelopment = process.env.NODE_ENV === "development";
    // console.log(window.location.href);
    // if (Platform.OS === "web" && isDevelopment) {
    //   if (window.location.href.includes(DASHBOARD_URL)) {
    //     this.setState({ page: "dashboard", force_render: true });
    //   } else if (window.location.href.includes(CADASTRO_URL)) {
    //     this.setState({ page: "", force_render: true });
    //   } else if (window.location.href.includes(CONTESTACAO_URL)) {
    //     this.setState({ page: "contestacao", force_render: true });
    //   } else if (window.location.href.includes(RESTAURANTE_URL)) {
    //     this.setState({ page: "restaurante", force_render: true });
    //   } else if (window.location.href.includes(ACOMPANHAMENTO_URL)) {
    //     this.setState({ page: "acompanhamento", force_render: true });
    //   } else if (window.location.href.includes(LINKS_URL)) {
    //     this.setState({ page: "links", force_render: true });
    //   }
    // }
  }

  goToPage(page, restaurant_id) {
    // const isDevelopment = process.env.NODE_ENV === "development";

    // if (isDevelopment) {
    this.setState({ page, force_render: false }, () => {
      this.router.history.push(`/${page}`);
    });
    // this.setState({ page, force_render: true });
    // }
    //  else if (Platform.OS === "web") {
    //   if (page === "") {
    //     window.location.href = `http:\\\\${CADASTRO_URL}`;
    //   } else if (page === "dashboard") {
    //     window.location.href = `http:\\\\${DASHBOARD_URL}`;
    //   } else if (page === "contestacao") {
    //     window.location.href = `http:\\\\${CONTESTACAO_URL}`;
    //   } else if (page === "restaurante") {
    //     window.location.href = `http:\\\\${RESTAURANTE_URL}?restaurant_id=${restaurant_id}`;
    //   } else if (page === "acompanhamento") {
    //     window.location.href = `http:\\\\${ACOMPANHAMENTO_URL}`;
    //   } else if (page === "links") {
    //     window.location.href = `http:\\\\${LINKS_URL}`;
    //   }
    // }
  }

  renderMenu() {
    return (
      <View style={styles.menu}>
        <Image source={{ uri: Images.logo }} style={styles.logo} />
        <View style={styles.menuItemContainer}>
          <TouchableOpacity
            onPress={() => this.goToPage("")}
            style={[
              styles.menuItem,
              this.state.page === "" ? styles.menuItemSelected : {},
            ]}
          >
            <Image source={{ uri: Images.plus }} style={styles.plus} />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.goToPage("dashboard")}
            style={[
              styles.menuItem,
              this.state.page === "dashboard" ? styles.menuItemSelected : {},
            ]}
          >
            <Image
              source={{ uri: Images.restaurants }}
              style={styles.restaurants}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => this.goToPage("conditions")}
            style={[
              styles.menuItem,
              this.state.page === "conditions" ? styles.menuItemSelected : {},
            ]}
          >
            <Image
              source={{ uri: Images.reservations }}
              style={styles.reservations}
            />
          </TouchableOpacity>

          {/* <TouchableOpacity
            onPress={() => this.goToPage("contestacao")}
            style={[
              styles.menuItem,
              this.state.page === "contestacao" ? styles.menuItemSelected : {},
            ]}
          >
            <Image
              source={{ uri: Images.contestacao_icon }}
              style={styles.contestacao}
            />
          </TouchableOpacity> */}

          {/* <TouchableOpacity
            onPress={() => this.goToPage("acompanhamento")}
            style={[
              styles.menuItem,
              this.state.page === "acompanhamento"
                ? styles.menuItemSelected
                : {},
            ]}
          >
            <Image
              source={{ uri: Images.reservations }}
              style={styles.reservations}
            />
          </TouchableOpacity> */}
          {/* <TouchableOpacity
            onPress={() => this.goToPage("links")}
            style={[
              styles.menuItem,
              this.state.page === "links" ? styles.menuItemSelected : {},
            ]}
          >
            <Image
              source={{ uri: Images.expand }}
              style={[styles.reservations, { tintColor: Colors.white }]}
            />
          </TouchableOpacity> */}
        </View>
      </View>
    );
  }

  renderNavigator() {
    const { page, force_render } = this.state;

    if (force_render && page === "") {
      return <RestaurantScreen />;
    }
    if (force_render && page === "dashboard") {
      return (
        <DashboardScreen
          onEditClick={(id) => this.goToPage("restaurante", id)}
        />
      );
    }
    if (force_render && page === "conditions") {
      return <ConditionsScreen />;
    }
    if (force_render && page === "restaurante") {
      const search = window.location.search;
      return (
        <RestaurantScreen restaurant_id={parseInt(search.replace(/\D/g, ""))} />
      );
    }
    if (force_render && page === "contestacao") {
      return <ContestacaoScreen />;
    }
    if (force_render && page === "acompanhamento") {
      return <AcompanhamentoScreen />;
    }
    if (force_render && page === "links") {
      return <DynamicLinksScreen />;
    }

    if (Platform.OS === "web" && !this.state.force_render) {
      return (
        <BrowserRouter style={{ flex: 1 }} ref={(r) => (this.router = r)}>
          <Switch>
            <Route path="/dashboard" component={DashboardScreen} />
            <Route path="/conditions" component={ConditionsScreen} />
            <Route
              path="/restaurant/:restaurant_id?"
              exact={true}
              component={RestaurantScreen}
            />
            <Route path="/contestacao" component={ContestacaoScreen} />
            <Route path="/acompanhamento" component={AcompanhamentoScreen} />
            <Route path="/links" component={DynamicLinksScreen} />
            <Route path="/" exact={true} component={RestaurantScreen} />
          </Switch>
        </BrowserRouter>
      );
    }

    return <RestaurantScreen />;
  }

  render() {
    return (
      <View style={styles.container}>
        <View style={styles.contentContainer}>{this.renderNavigator()}</View>
        {this.renderMenu()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "center",
  },
  contentContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    overflowY: "scroll",
    paddingLeft: 62,
  },
  menu: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    paddingTop: 22,
    width: 62,
    backgroundColor: Colors.primary,
  },
  menuItemContainer: {
    paddingTop: 30,
  },
  menuItem: {
    display: "flex",
    marginTop: 15,
    borderRadius: 18,
    width: 32,
    height: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  menuItemSelected: {
    backgroundColor: Colors.primary_dark,
  },
  logo: {
    width: 29,
    height: 25,
  },
  plus: {
    width: 15,
    height: 15,
  },
  restaurants: {
    width: 22,
    height: 15,
  },
  contestacao: {
    width: 22,
    height: 14,
  },
  reservations: {
    width: 16,
    height: 15,
  },
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigatorScreen);
