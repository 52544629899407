import { call, put } from "redux-saga/effects";
import {
  RESTAURANT_LIST_SUCCESS,
  RESTAURANT_LIST_FAIL,
  RESTAURANT_LIST_SET,
} from "../constants";

export function* restaurants(api, action) {
  try {
    const response = yield call(api.restaurants);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: RESTAURANT_LIST_SUCCESS,
      restaurants: response.data.restaurants,
    });
  } catch (e) {
    yield put({ type: RESTAURANT_LIST_FAIL });
  }
}

export function* list_restaurants(api, action) {
  try {
    const response = yield call(api.restaurants);
    if (response.problem !== null) {
      throw new Error("");
    }

    if (action.onSuccess) {
      action.onSuccess(response.data.restaurants);
    }
  } catch (e) {}
}

export function* search(api, action) {
  try {
    const restaurants = action.restaurants.filter((restaurant) => {
      return restaurant.name
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          action.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
    });
    yield put({ type: RESTAURANT_LIST_SET, restaurants });
  } catch (e) {
    console.error(e);
  }
}

export function* filter(api, action) {
  try {
    const restaurants = action.restaurants.filter((restaurant) => {
      var result = true;
      if (action.filter_city.length > 0) {
        result =
          result &&
          restaurant.city &&
          action.filter_city.includes(restaurant.city.id);
      }
      if (action.filter_active.length > 0) {
        result =
          result && restaurant.active === (action.filter_active[0] === "Ativo");
      }

      return result;
    });
    yield put({ type: RESTAURANT_LIST_SET, restaurants });
  } catch (e) {
    console.error(e);
  }
}
