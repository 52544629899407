import React, { useEffect, useState } from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { useConditions } from "../../context/ConditionsContext";
import Modal from "../Modal";
import { Textarea } from "../ui/textarea";
import { Colors, Fonts } from "../../themes";

export const ConditionModal = () => {
  const {
    currentCondition,
    currentHighlight,
    showConditionModal,
    closeModal,
    updateCondition,
  } = useConditions();

  const [saveDisabled, setSaveDisabled] = useState(false);

  const [text, setText] = useState(currentCondition?.value || "");

  useEffect(() => {
    setText(currentHighlight ? currentHighlight.name : currentCondition?.value);
  }, [currentCondition?.value, currentHighlight, currentHighlight?.name]);

  useEffect(() => {
    setSaveDisabled(!text);
  }, [text]);

  const handleUpdateCondition = () => {
    if (!currentHighlight) {
      updateCondition({ value: text });
      return;
    }

    if (!currentHighlight.id) {
      updateCondition({
        highlights: [...currentCondition.highlights, { name: text }],
      });
      return;
    }

    const highlights = currentCondition.highlights.map((h) =>
      h.id === currentHighlight.id ? { ...h, name: text } : h
    );

    updateCondition({ highlights });
  };

  return (
    <Modal
      visible={showConditionModal}
      onClosePress={() => {
        closeModal(false);
      }}
      huge={true}
    >
      <div className="p-5 w-full h-full">
        <h1 className="text-slate-600 mb-5 text-center">Editar Condição</h1>

        <h2 className="text-slate-500 mb-5 font-bold">
          {currentCondition?.description}
        </h2>

        <form onSubmit={(e) => e.preventDefault()}>
          <label className="mb-2 text-slate-500" htmlFor="condition_value">
            Texto da condição
          </label>
          <Textarea
            className="mb-2"
            id="condition_value"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Digite o texto da condição aqui."
          />
          <TouchableOpacity
            onPress={handleUpdateCondition}
            disabled={saveDisabled}
            style={[
              styles.salvarButton,
              {
                position: "relative",
                backgroundColor: Colors.primary,
                opacity: saveDisabled ? 0.6 : 1,
              },
            ]}
          >
            <Text style={styles.salvarText}>Publicar</Text>
          </TouchableOpacity>
        </form>
      </div>
    </Modal>
  );
};
const styles = StyleSheet.create({
  salvarButton: {
    position: "absolute",
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    width: 84,
    borderRadius: 3,
    backgroundColor: Colors.primary_dark,
  },
  salvarButtonDisabled: {
    opacity: 0.4,
  },
  salvarText: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.white,
  },
});
