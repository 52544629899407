/**
 * @provides colors
 */

const colors = {
  primary: "#FF472D",
  primary_dark: "#C32B15",
  primary_super_light: "#75e1ca55",
  secondary: "#FF472D",
  secondary_dark: "#7A4EC1",
  accent: "#07A0CE",
  accent_light: "#0AB1E4",
  accent_dark: "#0681A6",
  warning: "#DFA738",
  warning_dark: "#A48955",
  danger: "#D85E5E",
  danger_dark: "#A73333",
  text: "#51585E",
  text_light: "#98A3AE",
  divider: "#E5E5E5",
  divider2: "#BFBFBF",
  border: "#DFE2E5",
  fill: "#EDF1F4",
  fill2: "#FBFBFB",
  fill3: "#F2F1F1",
  fill4: "#FBFAFC",
  white: "#FFFFFF",

  score_ruim: "#FF0264",
  score_ruim_dark: "#E10548",
  score_neutro: "#FFE555",
  score_neutro_dark: "#FFBE54",
  score_bom: "#2ED7EF",
  score_bom_dark: "#07A0CE",
  score_otimo: "#FF472D",
  score_otimo_dark: "#C32B15",
};

export default colors;
