import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  Image,
  ActivityIndicator,
  Picker,
} from "react-native";
import Block from "../components/Block";
import Input from "../components/Input";
import Selector from "../components/Selector";
import Reservas from "../components/Reservas";
import Gos from "../components/Gos";
import { Fonts, Colors, Images } from "../themes";
import { connect } from "react-redux";
import {
  RESTAURANT_SET_VALUE,
  RESTAURANT_DADOS_PESSOAIS_REQUEST,
  RESTAURANT_ENDERECO_REQUEST,
  RESTAURANT_REQUEST,
  RESTAURANT_COMPLEMENTOS_REQUEST,
  RESTAURANT_RESERVAS_REQUEST,
  RESTAURANT_MIDIA_REQUEST,
  RESTAURANT_GOS_REQUEST,
  RESTAURANT_CONDITIONS_REQUEST,
  RESTAURANT_CITY_CHANGED,
  RESTAURANT_CONTATOS_REQUEST,
  RESTAURANT_PRECOS_REQUEST,
  RESTAURANT_PUBLISH_REQUEST,
} from "../constants";

class RestaurantScreen extends Component {
  static defaultProps = {
    restaurant_id: 0,
  };

  componentDidMount() {
    this.checkLoad();
  }

  componentDidUpdate() {
    this.checkLoad();
  }

  checkLoad() {
    if (this.props.restaurant_id > 0) {
      this.edicao = true;
      const restaurant_id = this.props.restaurant_id;
      const newId = restaurant_id ? restaurant_id : this.props.id;

      if (newId !== this.id) {
        this.id = newId;
        this.props.dados(newId);
      }
    } else {
      const restaurant_id = this.props.match
        ? this.props.match.params.restaurant_id
        : undefined;
      const newId = restaurant_id ? restaurant_id : this.props.id;

      if (newId !== this.id) {
        this.id = newId;
        this.props.dados(newId);
      }
    }
  }

  fileChangedHandler = (event, key) => {
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("media", file);

    this.props.setValue(key, formData);
    this.props.setValue("midia_touched", true);

    // if (file) {
    //   var reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     this.props.setValue(key, reader.result);
    //     this.props.setValue("midia_touched", true);
    //   };
    //   reader.onerror = (error) => {
    //     console.log("Error: ", error);
    //   };
    // }
  };

  adicionarContato() {
    const { contatos } = this.props;
    const novoContato = { nome: "", email: "", senha: "", permissoes: [] };
    contatos.push(novoContato);
    this.props.setValue("contatos", [...contatos]);
  }

  changeContato(index, key, value) {
    const { contatos } = this.props;
    const contato = contatos[index];
    if (key === "permissoes") {
      const list = contato[key];
      if (list.includes(value)) {
        list.splice(list.indexOf(value), 1);
      } else {
        list.push(value);
      }

      value = [...list];
    }
    contato[key] = value;
    this.props.setValue("contatos", [...contatos]);
    this.props.setValue("contatos_touched", true);
  }

  renderDadosPessoais() {
    this.allStatus = [];
    const { id, nome, url, razao, cnpj } = this.props;

    const nomeStatus = nome
      ? nome.length
        ? nome.length > 3
          ? "checked"
          : "warning"
        : ""
      : "";
    const urlStatus = url
      ? url.length
        ? url.length > 3
          ? "checked"
          : ""
        : ""
      : "";
    const razaoStatus = razao
      ? razao.length
        ? razao.length > 3
          ? "checked"
          : "warning"
        : ""
      : "";
    const cnpjStatus = cnpj
      ? cnpj.length
        ? cnpj.length > 3
          ? "checked"
          : "warning"
        : ""
      : "";

    const status = [nomeStatus, urlStatus, razaoStatus, cnpjStatus];
    const percentage =
      (status.filter((item) => item !== "warning").length * 100) /
      status.length;
    this.allStatus.push(
      percentage === 100 && !this.props.dados_pessoais_touched ? 1 : 0
    );
    const saveDisabled = percentage < 100 || !this.props.dados_pessoais_touched;

    return (
      <Block
        title="DADOS BÁSICOS"
        height={450}
        color={
          this.props.dados_pessoais_error
            ? Colors.danger
            : this.props.dados_pessoais_touched
            ? Colors.text
            : status.includes("warning")
            ? Colors.warning
            : Colors.primary
        }
      >
        <Input
          title="Nome do Estabelecimento"
          initial={nome}
          status={nomeStatus}
          onChangeValue={(value) => {
            this.props.setValue("nome", value);
            this.props.setValue("dados_pessoais_touched", true);
          }}
        />
        <Input
          title="URL do Estabelecimento"
          initial={url}
          status={urlStatus}
          onChangeValue={(value) => {
            this.props.setValue("url", value);
            this.props.setValue("dados_pessoais_touched", true);
          }}
        />
        <Input
          title="Razão Social"
          initial={razao}
          status={razaoStatus}
          onChangeValue={(value) => {
            this.props.setValue("razao", value);
            this.props.setValue("dados_pessoais_touched", true);
          }}
        />
        <Input
          title="CNPJ ou CPF"
          maxLength={18}
          initial={cnpj}
          status={cnpjStatus}
          onChangeValue={(value) => {
            this.props.setValue("cnpj", value);
            this.props.setValue("dados_pessoais_touched", true);
          }}
        />
        {this.props.dados_pessoais_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Dados Pessoais
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.dados_pessoais_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              if (!saveDisabled)
                this.props.save_dados_pessoais(id, nome, url, razao, cnpj);
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.dados_pessoais_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderEndereco() {
    const { id, endereco, cidade, bairro } = this.props;
    const enderecoStatus = endereco
      ? endereco.length === 0
        ? ""
        : endereco.length > 3
        ? "checked"
        : "warning"
      : "";
    const bairroStatus = bairro ? (bairro.length === 0 ? "" : "checked") : "";

    const status = [enderecoStatus, bairroStatus];
    const percentage =
      (status.filter((item) => item === "checked").length * 100) /
      status.length;
    this.allStatus.push(
      percentage === 100 && !this.props.endereco_touched ? 1 : 0
    );
    const saveDisabled = percentage < 100 || !this.props.endereco_touched;

    return (
      <Block
        title="ENDEREÇO"
        height={450}
        color={
          this.props.endereco_error
            ? Colors.danger
            : status.includes("") || this.props.endereco_touched
            ? Colors.text
            : status.includes("warning")
            ? Colors.warning
            : Colors.primary
        }
      >
        <Input
          title="Endereço"
          initial={endereco}
          status={enderecoStatus}
          onChangeValue={(value) => {
            this.props.setValue("endereco", value);
            this.props.setValue("endereco_touched", true);
          }}
        />
        <Text style={[styles.section, { marginTop: 20 }]}>Cidade</Text>
        <Picker
          style={{ fontSize: 14 }}
          selectedValue={this.props.cidade}
          onValueChange={(value) => {
            this.props.city_changed(value);
            this.props.setValue("endereco_touched", true);
          }}
        >
          <Picker.Item
            label={"Selecione..."}
            value={0}
            style={{ fontSize: 14 }}
          />
          {this.props.cities_list.map((city, index) => {
            return (
              <Picker.Item
                key={index}
                label={city.name}
                value={city.id}
                style={{ fontSize: 14 }}
              />
            );
          })}
        </Picker>
        <Text style={[styles.section, { marginTop: 20 }]}>Bairro</Text>
        <Picker
          selectedValue={this.props.bairro}
          onValueChange={(value) => {
            this.props.setValue("bairro", value);
            this.props.setValue("endereco_touched", true);
          }}
          style={{ fontSize: 14 }}
        >
          <Picker.Item
            label={"Selecione..."}
            value={0}
            style={{ fontSize: 14 }}
          />
          {this.props.bairros_list.map((bairro, index) => {
            return (
              <Picker.Item
                key={index}
                label={bairro.name}
                value={bairro.id}
                style={{ fontSize: 14 }}
              />
            );
          })}
        </Picker>
        {this.props.endereco_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Endereço
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.endereco_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              if (!saveDisabled)
                this.props.save_endereco(id, endereco, cidade, bairro);
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.endereco_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderMidia() {
    const { id, cover, cover2, cover3, cover4, cover5 } = this.props;

    var height = 535;
    const covers = [cover, cover2, cover3, cover4, cover5];
    covers.forEach((c) => {
      if (c && c.length > 0) {
        height += 206;
      }
    });
    const percentage =
      (covers.filter((c) => c && c.length > 0).length * 100) / covers.length;
    this.allStatus.push(
      cover && cover.length > 0 && !this.props.midia_touched ? 1 : 0
    );
    const saveDisabled = !cover || !this.props.midia_touched;

    return (
      <Block
        title="MÍDIA"
        height={height}
        color={
          this.props.midia_error
            ? Colors.danger
            : cover && cover.length > 0 && !this.props.midia_touched
            ? Colors.primary
            : Colors.text
        }
      >
        <Text style={[styles.section, { marginTop: 20 }]}>
          Foto de Capa Principal
        </Text>
        <TouchableOpacity
          style={[
            styles.photoButton,
            cover && cover.length > 0 ? { height: 250 } : {},
          ]}
          onPress={() => this.fileCover1.click()}
        >
          {Platform.OS === "web" && (
            <input
              style={{ display: "none" }}
              ref={(ref) => (this.fileCover1 = ref)}
              type="file"
              className={styles.photoButton}
              onChange={(event) => this.fileChangedHandler(event, "cover")}
            ></input>
          )}
          {!cover && <Text style={styles.photoText}>Adicionar foto</Text>}
          {cover && cover.length > 0 && (
            <Image source={{ uri: cover }} style={styles.photo} />
          )}
        </TouchableOpacity>
        <Text style={[styles.section, { marginTop: 20 }]}>
          Foto de Capa Opcional 1
        </Text>
        <TouchableOpacity
          style={[
            styles.photoButton,
            cover2 && cover2.length > 0 ? { height: 250 } : {},
          ]}
          onPress={() => this.fileCover2.click()}
        >
          {Platform.OS === "web" && (
            <input
              style={{ display: "none" }}
              ref={(ref) => (this.fileCover2 = ref)}
              type="file"
              className={styles.photoButton}
              onChange={(event) => this.fileChangedHandler(event, "cover2")}
            ></input>
          )}
          {!cover2 && <Text style={styles.photoText}>Adicionar foto</Text>}
          {cover2 && cover2.length > 0 && (
            <Image source={{ uri: cover2 }} style={styles.photo} />
          )}
        </TouchableOpacity>
        <Text style={[styles.section, { marginTop: 20 }]}>
          Foto de Capa Opcional 2
        </Text>
        <TouchableOpacity
          // style={styles.photoButton}
          style={[
            styles.photoButton,
            cover3 && cover3.length > 0 ? { height: 250 } : {},
          ]}
          onPress={() => this.fileCover3.click()}
        >
          {Platform.OS === "web" && (
            <input
              style={{ display: "none" }}
              ref={(ref) => (this.fileCover3 = ref)}
              type="file"
              className={styles.photoButton}
              onChange={(event) => this.fileChangedHandler(event, "cover3")}
            ></input>
          )}
          {!cover3 && <Text style={styles.photoText}>Adicionar foto</Text>}
          {cover3 && cover3.length > 0 && (
            <Image source={{ uri: cover3 }} style={styles.photo} />
          )}
        </TouchableOpacity>
        <Text style={[styles.section, { marginTop: 20 }]}>
          Foto de Capa Opcional 3
        </Text>
        <TouchableOpacity
          // style={styles.photoButton}
          style={[
            styles.photoButton,
            cover4 && cover4.length > 0 ? { height: 250 } : {},
          ]}
          onPress={() => this.fileCover4.click()}
        >
          {Platform.OS === "web" && (
            <input
              style={{ display: "none" }}
              ref={(ref) => (this.fileCover4 = ref)}
              type="file"
              className={styles.photoButton}
              onChange={(event) => this.fileChangedHandler(event, "cover4")}
            ></input>
          )}
          {!cover4 && <Text style={styles.photoText}>Adicionar foto</Text>}
          {cover4 && cover4.length > 0 && (
            <Image source={{ uri: cover4 }} style={styles.photo} />
          )}
        </TouchableOpacity>
        <Text style={[styles.section, { marginTop: 20 }]}>
          Foto de Capa Opcional 4
        </Text>
        <TouchableOpacity
          // style={styles.photoButton}
          style={[
            styles.photoButton,
            cover5 && cover5.length > 0 ? { height: 250 } : {},
          ]}
          onPress={() => this.fileCover5.click()}
        >
          {Platform.OS === "web" && (
            <input
              style={{ display: "none" }}
              ref={(ref) => (this.fileCover5 = ref)}
              type="file"
              className={styles.photoButton}
              onChange={(event) => this.fileChangedHandler(event, "cover5")}
            ></input>
          )}
          {!cover5 && <Text style={styles.photoText}>Adicionar foto</Text>}
          {cover5 && cover5.length > 0 && (
            <Image source={{ uri: cover5 }} style={styles.photo} />
          )}
        </TouchableOpacity>
        {this.props.midia_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Mídias
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.midia_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              if (!saveDisabled)
                this.props.save_midia(
                  id,
                  cover,
                  cover2,
                  cover3,
                  cover4,
                  cover5
                );
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.midia_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderComplementos() {
    const {
      id,
      descricao,
      especialidade_principal,
      especialidades,
      food_types,
      menu,
      ticket,
      instagram,
    } = this.props;

    const descricaoStatus = descricao
      ? descricao.length === 0
        ? ""
        : "checked"
      : "";
    const principalStatus = especialidade_principal
      ? especialidade_principal.length === 0
        ? ""
        : "checked"
      : "";
    const especialidadesStatus = especialidades
      ? especialidades.length === 0
        ? ""
        : "checked"
      : "";
    const foodStatus = food_types
      ? food_types.length === 0
        ? ""
        : "checked"
      : "";
    const menuStatus = menu ? (menu.length === 0 ? "" : "checked") : "";
    const ticketStatus = ticket ? (ticket.length === 0 ? "" : "checked") : "";
    const instragramStatus = instagram
      ? instagram.length === 0
        ? ""
        : "checked"
      : "";

    const status = [
      descricaoStatus,
      principalStatus,
      especialidadesStatus,
      foodStatus,
      menuStatus,
      ticketStatus,
      instragramStatus,
    ];
    const percentage = parseInt(
      (status.filter((item) => item === "checked").length * 100) / status.length
    );
    const saveDisabled =
      (descricao && descricao.length === 0) ||
      (menu && menu.length === 0) ||
      (ticket && ticket.length === 0) ||
      (instagram && instagram.length === 0) ||
      (especialidade_principal && especialidade_principal.length === 0);

    this.allStatus.push(
      (descricao && descricao.length === 0) ||
        (menu && menu.length === 0) ||
        (ticket && ticket.length === 0) ||
        (instagram && instagram.length === 0) ||
        (especialidade_principal && especialidade_principal.length === 0) ||
        this.props.complemento_touched
        ? 0
        : 1
    );

    return (
      <Block
        title="COMPLEMENTOS"
        color={
          saveDisabled || this.props.complemento_touched
            ? Colors.text
            : Colors.primary
        }
      >
        <Text style={[styles.section, { marginTop: 20 }]}>
          Especialidade Principal
        </Text>
        <Selector
          options={this.props.especialidades_list}
          selected={this.props.especialidade_principal}
          onClick={(option) => {
            this.props.setValue("especialidade_principal", option);
            this.props.setValue("complemento_touched", true);
          }}
        />
        <Text style={[styles.section, { marginTop: 20 }]}>
          Especialidades Adicionais
        </Text>
        <Selector
          options={this.props.especialidades_list}
          selected={this.props.especialidades}
          onClick={(option) => {
            this.props.setValue("especialidades", option);
            this.props.setValue("complemento_touched", true);
          }}
        />
        <Text style={[styles.section, { marginTop: 20 }]}>
          Pode ser classificado como...
        </Text>
        <Selector
          options={this.props.food_types_list}
          selected={this.props.food_types}
          onClick={(option) => {
            this.props.setValue("food_types", option);
            this.props.setValue("complemento_touched", true);
          }}
        />
        <Input
          multiline={true}
          title="Sobre o estabelecimento"
          initial={descricao}
          status={descricaoStatus}
          onChangeValue={(value) => {
            this.props.setValue("descricao", value);
            this.props.setValue("complemento_touched", true);
          }}
        />
        <Input
          multiline={true}
          title="Sobre o menu"
          initial={menu}
          status={menuStatus}
          onChangeValue={(value) => {
            this.props.setValue("menu", value);
            this.props.setValue("complemento_touched", true);
          }}
        />
        <Input
          title="Ticket médio"
          type="money"
          initial={ticket}
          status={ticketStatus}
          onChangeValue={(value) => {
            this.props.setValue("ticket", value);
            this.props.setValue("complemento_touched", true);
          }}
        />
        <Input
          title="Instagram"
          initial={instagram}
          status={instragramStatus}
          onChangeValue={(value) => {
            this.props.setValue("instagram", value);
            this.props.setValue("complemento_touched", true);
          }}
        />
        {this.props.complemento_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Complemento
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.complemento_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              if (!saveDisabled)
                this.props.save_complementos(
                  id,
                  descricao,
                  especialidade_principal,
                  especialidades,
                  food_types,
                  menu,
                  ticket,
                  instagram
                );
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.complemento_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderPrecos() {
    const { id, ticket, taxa, validade_taxa, renovacao_taxa, faixa_preco } =
      this.props;
    const ticketStatus = ticket === 0 ? "warning" : "checked";
    const taxaStatus = taxa === 0 ? "warning" : "checked";
    const validadeStatus =
      validade_taxa && validade_taxa.length === 0 ? "" : "checked";
    const renovacaoStatus = renovacao_taxa === 0 ? "" : "checked";

    const status = [
      "checked",
      ticketStatus,
      taxaStatus,
      validadeStatus,
      renovacaoStatus,
    ];
    const percentage = parseInt(
      (status.filter((item) => item === "checked").length * 100) / status.length
    );
    const saveDisabled = ticket === 0 || taxa === 0;

    this.allStatus.push(
      ticket === 0 || taxa === 0 || this.props.precos_touched ? 0 : 1
    );

    const options = [
      "Média gasta por pessoa de R$ 20 (aproximadamente) ($)",
      "Média gasta por pessoa de R$ 21 até R$ 40 (aproximadamente) ($$)",
      "Média gasta por pessoa de R$ 41 até R$ 60 (aproximadamente) ($$$)",
      "Média gasta por pessoa de R$ 41 até R$ 60 (aproximadamente) ($$$$)",
      "Média gasta por pessoa de R$ 61 até R$ 80 (aproximadamente) ($$$$$)",
      "Média gasta por pessoa acima de R$ 81 ($$$$$$)",
    ];
    return (
      <Block
        title="DADOS DE PRECIFICAÇÃO"
        color={
          this.props.precos_error
            ? Colors.danger
            : saveDisabled || this.props.precos_touched
            ? Colors.text
            : Colors.primary
        }
      >
        <Input
          title="Ticket médio"
          initial={ticket}
          status={ticketStatus}
          type="money"
          onChangeValue={(value) => {
            this.props.setValue("ticket", value);
            this.props.setValue("precos_touched", true);
          }}
        />
        <Input
          title="Taxa Acordada"
          initial={taxa}
          status={taxaStatus}
          type="money"
          onChangeValue={(value) => {
            this.props.setValue("taxa", value);
            this.props.setValue("precos_touched", true);
          }}
        />
        {/* <Input title="Validade de Taxa Acordada" status={validadeStatus} initial={validade_taxa} onChangeValue={(value) => { this.props.setValue('validade_taxa', value); this.props.setValue('precos_touched', true) }} /> */}
        <Text style={[styles.section, { marginTop: 20 }]}>
          Validade de Taxa Acordada
        </Text>
        <input
          type="date"
          onChange={(event) => {
            this.props.setValue("validade_taxa", event.target.value);
            this.props.setValue("precos_touched", true);
          }}
        />
        <Input
          title="Renovação da Taxa"
          initial={renovacao_taxa}
          status={renovacaoStatus}
          type="money"
          onChangeValue={(value) => {
            this.props.setValue("renovacao_taxa", value);
            this.props.setValue("precos_touched", true);
          }}
        />
        <Text style={[styles.section, { marginTop: 20 }]}>
          Faixa de Preço do Restaurante
        </Text>
        <View style={styles.boxContainer}>
          {options.map((option, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={styles.selectBoxContainer}
                onPress={() => {
                  this.props.setValue("faixa_preco", index + 1);
                  this.props.setValue("precos_touched", true);
                }}
              >
                <View
                  style={[
                    styles.radio,
                    faixa_preco === index + 1 ? styles.radioSelected : {},
                  ]}
                >
                  {faixa_preco === index + 1 && (
                    <View style={styles.radioSelectedInside} />
                  )}
                </View>
                <Text style={[styles.subtitle, { marginTop: 0 }]}>
                  {option}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
        {this.props.precos_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Dados de Precificação
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.precos_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              if (!saveDisabled)
                this.props.save_precos(
                  id,
                  ticket,
                  taxa,
                  validade_taxa,
                  renovacao_taxa,
                  faixa_preco
                );
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.precos_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderReservas() {
    const { id, reservas, condicoes_extras, is_free } = this.props;
    const reservasStatus = "checked";
    const condicoesStatus = condicoes_extras
      ? condicoes_extras.length === 0
        ? ""
        : condicoes_extras.length > 1
        ? "checked"
        : "warning"
      : "";

    const status = [reservasStatus, condicoesStatus];
    const percentage = parseInt(
      (status.filter((item) => item === "checked").length * 100) / status.length
    );
    const saveDisabled = reservasStatus !== "checked";

    this.allStatus.push(
      reservasStatus !== "checked" || this.props.reservas_touched ? 0 : 1
    );

    return (
      <Block
        title="BOARD DE DESCONTOS"
        color={
          this.props.reservas_error
            ? Colors.danger
            : saveDisabled || this.props.reservas_touched
            ? Colors.text
            : Colors.primary
        }
      >
        <Reservas
          initial={reservas}
          onChange={(days) => {
            this.props.setValue("reservas", days);
            this.props.setValue("reservas_touched", true);
          }}
        />
        <Input
          multiline={true}
          title="Condições extras de Desconto"
          initial={condicoes_extras}
          status={condicoesStatus}
          onChangeValue={(value) => {
            this.props.setValue("condicoes_extras", value);
            this.props.setValue("reservas_touched", true);
          }}
        />
        {/* <View
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            onPress={() => {
              this.props.setValue("is_free", !is_free);
              this.props.setValue("reservas_touched", true);
            }}
            style={[
              styles.checkButton,
              is_free ? styles.checkButtonSelected : {},
            ]}
          >
            {is_free && (
              <Image
                source={{ uri: Images.check }}
                style={styles.check}
              />
            )}
          </TouchableOpacity>
          <Text style={[styles.subtitle, { marginTop: 0 }]}>
            Aceita reservas pelo Groubie Free
          </Text>
        </View> */}
        {this.props.reservas_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Reservas
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.reservas_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              this.props.save_reservas(id, reservas, condicoes_extras, is_free);
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.reservas_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderGos() {
    const { id, gos, go_conditions, is_premium } = this.props;
    const reservasStatus = "checked";
    const condicoesStatus = go_conditions
      ? go_conditions.length === 0
        ? ""
        : go_conditions.length > 1
        ? "checked"
        : "warning"
      : "";

    const status = [reservasStatus, condicoesStatus];
    const percentage = parseInt(
      (status.filter((item) => item === "checked").length * 100) / status.length
    );
    const saveDisabled = reservasStatus !== "checked";
    this.allStatus.push(
      reservasStatus !== "checked" || this.props.gos_touched ? 0 : 1
    );

    return (
      <Block
        title="BOARD DE 2 POR 1"
        color={
          this.props.gos_error
            ? Colors.danger
            : saveDisabled || this.props.gos_touched
            ? Colors.text
            : Colors.primary
        }
      >
        <Gos
          initial={gos}
          onChange={(days) => {
            this.props.setValue("gos", days);
            this.props.setValue("gos_touched", true);
          }}
        />
        <Input
          multiline={true}
          title="Condições extras de 2 por 1"
          initial={go_conditions}
          status={condicoesStatus}
          onChangeValue={(value) => {
            this.props.setValue("go_conditions", value);
            this.props.setValue("gos_touched", true);
          }}
        />
        {this.props.gos_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar 2 por 1
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.gos_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              this.props.save_gos(id, gos, go_conditions, is_premium);
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.gos_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderConditions() {
    const {
      id,
      general_conditions,
      benefit_condition,
      date_condition,
      hour_condition,
      seat_condition,
      benefit_go_condition,
      date_go_condition,
      hour_go_condition,
      seat_go_condition,
    } = this.props;

    const generalConditionStatus = general_conditions
      ? general_conditions.length === 0
        ? ""
        : general_conditions.length > 1
        ? "checked"
        : "warning"
      : "";

    const status = [generalConditionStatus];
    const percentage = parseInt(
      (status.filter((item) => item === "checked").length * 100) / status.length
    );
    const saveDisabled = generalConditionStatus !== "checked";
    this.allStatus.push(
      generalConditionStatus !== "checked" || this.props.conditions_touched
        ? 0
        : 1
    );

    return (
      <Block title="CONDIÇÕES">
        <Input
          multiline={true}
          title="Condições Gerais"
          initial={general_conditions}
          status={generalConditionStatus}
          onChangeValue={(value) => {
            this.props.setValue("general_conditions", value);
            this.props.setValue("conditions_touched", true);
          }}
        />

        <View style={styles.divider} />

        <Text style={styles.label}>Condições de DESCONTOS</Text>

        <Input
          multiline
          textHeight={94}
          title="Benefício"
          initial={benefit_condition}
          onChangeValue={(value) => {
            this.props.setValue("benefit_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <Input
          multiline
          textHeight={94}
          title="Data"
          initial={date_condition}
          onChangeValue={(value) => {
            this.props.setValue("date_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <Input
          multiline
          textHeight={94}
          title="Horário"
          initial={hour_condition}
          onChangeValue={(value) => {
            this.props.setValue("hour_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <Input
          multiline
          textHeight={94}
          title="Pessoas"
          initial={seat_condition}
          onChangeValue={(value) => {
            this.props.setValue("seat_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <View style={styles.divider} />

        <Text style={styles.label}>Condições de 2 POR 1</Text>

        <Input
          multiline
          textHeight={94}
          title="Benefício"
          initial={benefit_go_condition}
          onChangeValue={(value) => {
            this.props.setValue("benefit_go_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <Input
          multiline
          textHeight={94}
          title="Data"
          initial={date_go_condition}
          onChangeValue={(value) => {
            this.props.setValue("date_go_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <Input
          multiline
          textHeight={94}
          title="Horário"
          initial={hour_go_condition}
          onChangeValue={(value) => {
            this.props.setValue("hour_go_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />
        <Input
          multiline
          textHeight={94}
          title="Pessoas"
          initial={seat_go_condition}
          onChangeValue={(value) => {
            this.props.setValue("seat_go_condition", value);
            this.props.setValue("conditions_touched", true);
          }}
        />

        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.gos_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              this.props.save_conditions(
                id,
                general_conditions,
                benefit_condition,
                date_condition,
                hour_condition,
                seat_condition,
                benefit_go_condition,
                date_go_condition,
                hour_go_condition,
                seat_go_condition
              );
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.gos_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderContatos() {
    const { id, contatos } = this.props;
    // const condicoesStatus = condicoes_extras.length===0 ? '' : condicoes_extras.length > 1 ? 'checked' : 'warning';

    const status = [];

    contatos.forEach((contato) => {
      const nomeStatus =
        contato.nome.length === 0
          ? ""
          : contato.nome.length > 3
          ? "checked"
          : "warning";
      const emailStatus =
        contato.email.length === 0
          ? ""
          : contato.email.length > 3
          ? "checked"
          : "warning";
      const senhaStatus =
        contato.senha.length === 0
          ? ""
          : contato.senha.length > 3
          ? "checked"
          : "warning";

      status.push(nomeStatus, emailStatus, senhaStatus);
    });

    const percentage =
      status.length === 0
        ? 0
        : parseInt(
            (status.filter((item) => item === "checked").length * 100) /
              status.length
          );
    const saveDisabled = status.filter((s) => s !== "checked").length > 0;
    const hasWarning = status.filter((s) => s === "warning").length > 0;

    this.allStatus.push(saveDisabled || this.props.contatos_touched ? 0 : 1);

    return (
      <Block
        title="CONTATOS E PERMISSÕES"
        color={
          this.props.reservas_error
            ? Colors.danger
            : hasWarning
            ? Colors.warning
            : saveDisabled || this.props.contatos_touched || status.length === 0
            ? Colors.text
            : Colors.primary
        }
      >
        {contatos.map((contato, contadoIndex) => {
          const nomeStatus =
            contato.nome.length === 0
              ? ""
              : contato.nome.length > 3
              ? "checked"
              : "warning";
          const emailStatus =
            contato.email.length === 0
              ? ""
              : contato.email.length > 3
              ? "checked"
              : "warning";
          const senhaStatus =
            contato.senha.length === 0
              ? ""
              : contato.senha.length > 3
              ? "checked"
              : "warning";
          return (
            <View key={contadoIndex} style={{ marginBottom: 30 }}>
              <Input
                title={`Nome do Contato #${contadoIndex + 1}`}
                initial={contato.nome}
                status={nomeStatus}
                onChangeValue={(value) => {
                  this.changeContato(contadoIndex, "nome", value);
                  this.props.setValue("contatos_touched", true);
                }}
              />
              <Input
                title={`Email do Contato #${contadoIndex + 1}`}
                initial={contato.email}
                status={emailStatus}
                onChangeValue={(value) => {
                  this.changeContato(contadoIndex, "email", value);
                  this.props.setValue("contatos_touched", true);
                }}
              />
              <Input
                title={`Senha do Contato #${contadoIndex + 1}`}
                initial={contato.senha}
                status={senhaStatus}
                onChangeValue={(value) => {
                  this.changeContato(contadoIndex, "senha", value);
                  this.props.setValue("contatos_touched", true);
                }}
              />
              <Text
                style={[styles.section, { marginTop: 20 }]}
              >{`Permissões do Contato #${contadoIndex + 1}`}</Text>
              <View style={styles.boxContainer}>
                {this.props.permissoes_list.map((permission, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                        marginTop: 10,
                        marginBottom: 10,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.changeContato(
                            contadoIndex,
                            "permissoes",
                            permission.name
                          );
                        }}
                        style={[
                          styles.checkButton,
                          contato.permissoes.includes(permission.name)
                            ? styles.checkButtonSelected
                            : {},
                        ]}
                      >
                        {contato.permissoes.includes(permission.name) && (
                          <Image
                            source={{ uri: Images.check }}
                            style={styles.check}
                          />
                        )}
                      </TouchableOpacity>
                      <Text style={[styles.subtitle, { marginTop: 0 }]}>
                        {permission.description}
                      </Text>
                    </View>
                  );
                })}
              </View>
            </View>
          );
        })}

        <TouchableOpacity
          style={styles.adicionarContatoButton}
          onPress={this.adicionarContato.bind(this)}
        >
          <Image
            source={{ uri: Images.contact_add }}
            style={styles.adicionarContatoImage}
          />
          <Text style={styles.section}>{`Adicionar Contato #${
            contatos.length + 1
          }`}</Text>
        </TouchableOpacity>

        {this.props.contatos_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao salvar Contatos
          </Text>
        )}
        <View style={{ height: 60 }}>
          <Text style={styles.subtitle}>{`${percentage}% completo`}</Text>
          {this.props.contatos_saving && (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={styles.loading}
            />
          )}
          <TouchableOpacity
            onPress={() => {
              this.props.save_contatos(id, contatos);
            }}
            style={[
              styles.salvarButton,
              saveDisabled ? styles.salvarButtonDisabled : {},
            ]}
            disabled={saveDisabled}
          >
            <Text style={styles.salvarText}>
              {this.props.contatos_touched || this.props.id === null
                ? "Salvar"
                : "Salvo"}
            </Text>
          </TouchableOpacity>
        </View>
      </Block>
    );
  }

  renderPublicar() {
    const { id } = this.props;
    const count = this.allStatus.filter((item) => item === 1).length;

    return (
      <React.Fragment>
        {this.props.publish_error && (
          <Text style={[styles.subtitle, { color: Colors.danger }]}>
            Erro ao Publicar Restaurante
          </Text>
        )}
        <View style={styles.publishContainer}>
          <View style={styles.publishBar}>
            <View style={[styles.publishProgress, { flex: count }]} />
            <View style={{ flex: this.allStatus.length - count }} />
          </View>
          {this.props.publish_saving ? (
            <ActivityIndicator
              size="large"
              color={Colors.accent_dark}
              style={{ marginLeft: 34, marginRight: 34 }}
            />
          ) : (
            <TouchableOpacity
              onPress={() => this.props.publicar(id)}
              disabled={count < this.allStatus.length}
              style={[
                styles.salvarButton,
                {
                  position: "relative",
                  backgroundColor: Colors.primary,
                  marginLeft: 20,
                  opacity: count < this.allStatus.length ? 0.6 : 1,
                },
              ]}
            >
              <Text style={styles.salvarText}>Publicar</Text>
            </TouchableOpacity>
          )}
        </View>
      </React.Fragment>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>
          {this.edicao === true
            ? `Edição de '${this.props.nome}'`
            : "Cadastro de Novo Estabelecimento"}
        </Text>
        {this.renderDadosPessoais()}
        <View>
          {this.renderEndereco()}
          {this.renderMidia()}
          {this.renderComplementos()}
          {this.renderReservas()}
          {this.renderGos()}
          {this.renderConditions()}
          {this.renderContatos()}
          {this.renderPublicar()}
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 22,
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.divider,
    marginVertical: 20,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginBottom: 30,
  },
  label: {
    fontFamily: Fonts.type.bold,
    fontSize: 14,
    color: Colors.text,
    marginBottom: 5,
  },
  section: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.text,
    marginBottom: 5,
  },
  subtitle: {
    fontFamily: Fonts.type.regular,
    fontSize: 14,
    color: Colors.text,
    marginTop: 30,
  },
  photoButton: {
    display: "flex",
    justifyContent: "center",
    height: 45,
    borderWidth: 1,
    borderRadius: 3,
    borderColor: Colors.divider,
    paddingLeft: 10,
    paddingRight: 10,
  },
  photoText: {
    fontFamily: Fonts.type.italic,
    fontSize: 14,
    color: Colors.text,
  },
  photo: {
    height: 249,
    width: "100%",
  },
  checkButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    width: 18,
    height: 18,
    borderRadius: 3,
    backgroundColor: Colors.fill,
  },
  checkButtonSelected: {
    backgroundColor: Colors.primary,
  },
  check: {
    width: 11,
    height: 8,
  },
  salvarButton: {
    position: "absolute",
    right: 0,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 36,
    width: 84,
    borderRadius: 3,
    backgroundColor: Colors.primary_dark,
  },
  salvarButtonDisabled: {
    opacity: 0.4,
  },
  salvarText: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.white,
  },
  loading: {
    position: "absolute",
    right: 100,
    bottom: 0,
  },
  adicionarContatoButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 20,
  },
  adicionarContatoImage: {
    width: 41,
    height: 41,
    marginRight: 10,
  },
  boxContainer: {
    display: "flex",
    flexWrap: "wrap",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderRadius: 3,
    borderColor: Colors.divider,
    padding: 10,
  },
  selectBoxContainer: {
    flexDirection: "row",
    marginTop: 10,
    marginBottom: 10,
  },
  radio: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 10,
    width: 18,
    height: 18,
    borderRadius: 9,
    backgroundColor: Colors.fill,
  },
  radioSelected: {
    backgroundColor: Colors.primary,
  },
  radioSelectedInside: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: Colors.fill,
  },
  publishContainer: {
    backgroundColor: "#fff",
    height: 67,
    paddingLeft: 20,
    paddingRight: 20,
    maxWidth: 600,
    borderRadius: 5,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 24,
    shadowColor: Colors.text,
    shadowOpacity: 0.2,
    overflow: "hidden",
    marginBottom: 30,
    alignItems: "center",
    flexDirection: "row",
  },
  publishBar: {
    flex: 1,
    height: 20,
    borderRadius: 10,
    backgroundColor: Colors.fill,
    flexDirection: "row",
  },
  publishProgress: {
    height: 20,
    borderRadius: 10,
    backgroundColor: Colors.primary,
  },
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  especialidades_list: state.restaurant.especialidades_list,
  food_types_list: state.restaurant.food_types_list,
  permissoes_list: state.restaurant.permissoes_list,
  cities_list: state.restaurant.cities_list,
  bairros_list: state.restaurant.bairros_list,

  loading: state.restaurant.loading,
  dados_pessoais_touched: state.restaurant.dados_pessoais_touched,
  dados_pessoais_saving: state.restaurant.dados_pessoais_saving,
  dados_pessoais_error: state.restaurant.dados_pessoais_error,
  endereco_touched: state.restaurant.endereco_touched,
  endereco_saving: state.restaurant.endereco_saving,
  endereco_error: state.restaurant.endereco_error,
  midia_touched: state.restaurant.midia_touched,
  midia_saving: state.restaurant.midia_saving,
  midia_error: state.restaurant.midia_error,
  complemento_touched: state.restaurant.complemento_touched,
  complemento_saving: state.restaurant.complemento_saving,
  complemento_error: state.restaurant.complemento_error,
  precos_touched: state.restaurant.precos_touched,
  precos_saving: state.restaurant.precos_saving,
  precos_error: state.restaurant.precos_error,
  reservas_touched: state.restaurant.reservas_touched,
  reservas_saving: state.restaurant.reservas_saving,
  reservas_error: state.restaurant.reservas_error,
  gos_touched: state.restaurant.gos_touched,
  gos_saving: state.restaurant.gos_saving,
  gos_error: state.restaurant.gos_error,
  contatos_touched: state.restaurant.contatos_touched,
  contatos_saving: state.restaurant.contatos_saving,
  contatos_error: state.restaurant.contatos_error,
  publish_saving: state.restaurant.publish_saving,
  publish_error: state.restaurant.publish_error,

  id: state.restaurant.id,
  nome: state.restaurant.nome,
  url: state.restaurant.url,
  razao: state.restaurant.razao,
  cnpj: state.restaurant.cnpj,
  endereco: state.restaurant.endereco,
  cidade: state.restaurant.cidade,
  bairro: state.restaurant.bairro,
  cover: state.restaurant.cover,
  cover2: state.restaurant.cover2,
  cover3: state.restaurant.cover3,
  cover4: state.restaurant.cover4,
  cover5: state.restaurant.cover5,
  descricao: state.restaurant.descricao,
  menu: state.restaurant.menu,
  instagram: state.restaurant.instagram,
  ticket: state.restaurant.ticket,
  taxa: state.restaurant.taxa,
  validade_taxa: state.restaurant.validade_taxa,
  renovacao_taxa: state.restaurant.renovacao_taxa,
  faixa_preco: state.restaurant.faixa_preco,
  especialidade_principal: state.restaurant.especialidade_principal,
  especialidades: state.restaurant.especialidades,
  food_types: state.restaurant.food_types,
  is_free: state.restaurant.is_free,
  is_premium: state.restaurant.is_premium,
  condicoes_extras: state.restaurant.condicoes_extras,
  go_conditions: state.restaurant.go_conditions,
  general_conditions: state.restaurant.general_conditions,
  benefit_condition: state.restaurant.benefit_condition,
  date_condition: state.restaurant.date_condition,
  hour_condition: state.restaurant.hour_condition,
  seat_condition: state.restaurant.seat_condition,
  benefit_go_condition: state.restaurant.benefit_go_condition,
  date_go_condition: state.restaurant.date_go_condition,
  hour_go_condition: state.restaurant.hour_go_condition,
  seat_go_condition: state.restaurant.seat_go_condition,
  reservas: state.restaurant.reservas,
  gos: state.restaurant.gos,
  contatos: state.restaurant.contatos || [],
});

const mapDispatchToProps = (dispatch) => {
  return {
    setValue: (key, value) => {
      dispatch({ type: RESTAURANT_SET_VALUE, key, value });
    },
    city_changed: (id) => {
      dispatch({ type: RESTAURANT_CITY_CHANGED, id });
    },
    dados: (id) => {
      dispatch({ type: RESTAURANT_REQUEST, id });
    },
    save_dados_pessoais: (id, nome, url, razao, cnpj) => {
      dispatch({
        type: RESTAURANT_DADOS_PESSOAIS_REQUEST,
        id,
        nome,
        url,
        razao,
        cnpj,
      });
    },
    save_endereco: (id, endereco, cidade, bairro) => {
      dispatch({
        type: RESTAURANT_ENDERECO_REQUEST,
        id,
        endereco,
        cidade,
        bairro,
      });
    },
    save_midia: (id, cover, cover2, cover3, cover4, cover5) => {
      dispatch({
        type: RESTAURANT_MIDIA_REQUEST,
        id,
        cover,
        cover2,
        cover3,
        cover4,
        cover5,
      });
    },
    save_complementos: (
      id,
      descricao,
      especialidade_principal,
      especialidades,
      food_types,
      menu,
      ticket,
      instagram
    ) => {
      dispatch({
        type: RESTAURANT_COMPLEMENTOS_REQUEST,
        id,
        descricao,
        especialidade_principal,
        especialidades,
        food_types,
        menu,
        ticket,
        instagram,
      });
    },
    save_precos: (
      id,
      ticket,
      taxa,
      validade_taxa,
      renovacao_taxa,
      faixa_preco
    ) => {
      dispatch({
        type: RESTAURANT_PRECOS_REQUEST,
        id,
        ticket,
        taxa,
        validade_taxa,
        renovacao_taxa,
        faixa_preco,
      });
    },
    save_reservas: (id, reservas, condicoes_extras, is_free) => {
      dispatch({
        type: RESTAURANT_RESERVAS_REQUEST,
        id,
        reservas,
        condicoes_extras,
        is_free,
      });
    },
    save_gos: (id, gos, go_conditions, is_premium) => {
      dispatch({
        type: RESTAURANT_GOS_REQUEST,
        id,
        gos,
        go_conditions,
        is_premium,
      });
    },
    save_conditions: (
      id,
      general_conditions,
      benefit_condition,
      date_condition,
      hour_condition,
      seat_condition,
      benefit_go_condition,
      date_go_condition,
      hour_go_condition,
      seat_go_condition
    ) => {
      dispatch({
        type: RESTAURANT_CONDITIONS_REQUEST,
        id,
        general_conditions,
        benefit_condition,
        date_condition,
        hour_condition,
        seat_condition,
        benefit_go_condition,
        date_go_condition,
        hour_go_condition,
        seat_go_condition,
      });
    },
    save_contatos: (id, contatos) => {
      dispatch({ type: RESTAURANT_CONTATOS_REQUEST, id, contatos });
    },
    publicar: (id) => {
      dispatch({ type: RESTAURANT_PUBLISH_REQUEST, id });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RestaurantScreen);
