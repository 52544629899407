import { auth } from './auth';
import { restaurant } from './restaurant';
import { dashboard } from './dashboard';
import { reservation } from './reservation';
import { feed } from './feed';
import { historico } from './historico';
import { avaliacao } from './avaliacao';
import { combineReducers } from 'redux';


export const reducers = combineReducers({
    auth: auth,
    restaurant: restaurant,
    dashboard: dashboard,
    reservation: reservation,
    feed: feed,
    historico: historico,
    avaliacao: avaliacao
});