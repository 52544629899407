import React, { Component } from "react";
import {
  Text,
  View,
  Platform,
  TouchableOpacity,
  Animated,
  Image,
  TextInput,
  Keyboard,
  Dimensions,
} from "react-native";
import { connect } from "react-redux";
import { Images, Colors } from "./themes";
import NavigatorScreen from "./containers/NavigatorScreen";
import LoginScreen from "./containers/LoginScreen";
// import MainScreen from "./containers/MainScreen";
import AsyncStorage from "@react-native-community/async-storage";
// import messaging from '@react-native-firebase/messaging';
// import firebase from "firebase";

// const config = {
//   apiKey: "AIzaSyDfeGVnFYArHjZ1qXTA7bXtx3eTqCeFkiw",
//   authDomain: "groubie-ios.firebaseapp.com",
//   databaseURL: "https://groubie-ios.firebaseio.com",
//   projectId: "groubie-ios",
//   storageBucket: "",
//   messagingSenderId: "89146505979",
// };

class App extends Component {
  constructor(props) {
    super(props);
    try {
      Text.defaultProps.allowFontScaling = false;
      TextInput.defaultProps.allowFontScaling = false;
    } catch (e) {}

    this.position = new Animated.Value(Dimensions.get("window").height);

    if (Platform.OS === "web") {
      // firebase.initializeApp(config);
    }
  }

  async getToken() {
    let fcmToken = await AsyncStorage.getItem("fcmToken");
    if (!fcmToken) {
      // fcmToken = await messaging.getToken();
      if (fcmToken) {
        await AsyncStorage.setItem("fcmToken", fcmToken);
      }
    }
  }

  async checkPermission() {
    // const enabled = await messaging.hasPermission();
    const enabled = false;
    if (enabled) {
      this.getToken();
    } else {
      this.requestPermission();
    }
  }

  async requestPermission() {
    // try {
    //   await messaging.requestPermission();
    //   this.getToken();
    // } catch (error) {
    //   console.log('permission rejected');
    // }
  }

  async createNotificationListeners() {
    // message.notifications().onNotification(notification => {
    //   notification.android.setChannelId('insider').setSound('default')
    //   message.notifications().displayNotification(notification)
    // });
  }

  componentDidMount() {
    // const channel = new message.notifications.Android.Channel('insider', 'insider channel', message.notifications.Android.Importance.Max)
    // message.notifications().android.createChannel(channel);

    this.checkPermission();
    this.createNotificationListeners();
    if (Platform.OS !== "web") {
      this.keyboardDidShowListener = Keyboard.addListener(
        "keyboardDidShow",
        this._keyboardDidShow.bind(this)
      );
      this.keyboardDidHideListener = Keyboard.addListener(
        "keyboardDidHide",
        this._keyboardDidHide.bind(this)
      );
    }
  }

  componentWillUnmount() {
    // this.keyboardDidShowListener.remove();
    // this.keyboardDidHideListener.remove();
  }

  _keyboardDidShow(e) {
    // this.props.navigation.setParams({
    //     keyboardHeight: e.endCoordinates.height,
    //     normalHeight: Dimensions.get('window').height,
    //     shortHeight: Dimensions.get('window').height - e.endCoordinates.height,
    // });
    // this.position.setValue(e.endCoordinates.height + 20);
    this.position.setValue(20);
  }

  _keyboardDidHide() {
    this.position.setValue(Dimensions.get("window").height);
  }

  hideKeyboard() {
    Keyboard.dismiss();
  }

  renderScreen() {
    if (this.props.user === null) {
      return <LoginScreen />;
    } else {
      // return <MainScreen />;
      return <NavigatorScreen />;
    }
  }

  render() {
    return (
      <View style={{ flex: 1 }}>
        {this.renderScreen()}
        <Animated.View
          style={{
            position: "absolute",
            top: this.position,
            right: 20,
            height: 50,
            width: 50,
            borderRadius: 10,
            backgroundColor: Colors.primary,
            justifyContent: "center",
            alignItems: "center",
            display: Platform.OS === "web" ? "none" : "flex",
          }}
        >
          <TouchableOpacity
            style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
            onPress={this.hideKeyboard.bind(this)}
          >
            <Image
              source={{ uri: Images.keyboard }}
              style={{ width: 40, height: 35, tintColor: "white" }}
            />
          </TouchableOpacity>
        </Animated.View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
