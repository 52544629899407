import React, { createContext, useContext, useState, useEffect } from "react";
import InsiderApi from "../api/InsiderApi";

const ConditionsContext = createContext();

export const useConditions = () => useContext(ConditionsContext);

export const ConditionsProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [conditions, setConditions] = useState([]);
  const [goConditions, setGoConditions] = useState([]);
  const [generalConditions, setGeneralConditions] = useState(null);

  const [currentCondition, setCurrentCondition] = useState(null);
  const [currentHighlight, setCurrentHighlight] = useState(null);

  const [showConditionModal, setShowConditionModal] = useState(false);

  const fetchConditions = async () => {
    const api = await InsiderApi.initialize();

    setLoading(true);
    try {
      const { data } = await api.conditions();

      setConditions(data.conditions);
      setGoConditions(data.go_conditions);
      setGeneralConditions(data.general_conditions);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConditions();
  }, []);

  const closeModal = () => {
    setShowConditionModal(false);
    setCurrentCondition(null);
    setCurrentHighlight(null);
  };

  const openModal = (condition) => {
    setCurrentCondition(condition);
    setShowConditionModal(true);
  };

  const openHighlight = (condition, highlight) => {
    setCurrentCondition(condition);
    setCurrentHighlight(highlight);
    setShowConditionModal(true);
  };

  const createHighlight = () => {
    openHighlight(generalConditions, { name: "" });
  };

  const updateCondition = async (data) => {
    const api = await InsiderApi.initialize();

    setLoading(true);
    try {
      await api.save_condition(currentCondition.id, data);
    } catch (err) {
      setError(err.message);
    } finally {
      fetchConditions();
      setLoading(false);
      closeModal();
    }
  };

  return (
    <ConditionsContext.Provider
      value={{
        loading,
        error,
        conditions,
        goConditions,
        generalConditions,
        currentCondition,
        setCurrentCondition,
        currentHighlight,
        setCurrentHighlight,
        showConditionModal,
        setShowConditionModal,
        closeModal,
        openModal,
        updateCondition,
        openHighlight,
        createHighlight,
      }}
    >
      {children}
    </ConditionsContext.Provider>
  );
};
