import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Animated,
  TouchableOpacity,
} from "react-native";
import { Fonts, Colors, Images } from "../themes";

class Block extends Component {
  static defaultProps = {
    title: "",
    color: Colors.text,
    animation: "spring",
    onOpenClick: () => {},
    onCloseClick: () => {},
  };

  state = {
    open: new Animated.Value(0),
    height: 0,
    width: 0,
  };

  changeOpen() {
    const value = this.state.open._value === 0 ? 1 : 0;
    if (value === 1) {
      this.props.onOpenClick();
    } else {
      this.props.onCloseClick();
    }
    if (this.props.animation === "timing") {
      Animated.timing(this.state.open, {
        toValue: value,
        duration: 200,
      }).start();
    } else {
      Animated.spring(this.state.open, {
        toValue: value,
      }).start();
    }
  }

  render() {
    const { title } = this.props;

    const bodyStyle = {
      transform: [{ scaleY: this.state.open }],
    };

    const containerStyle = {
      maxHeight: this.state.open.interpolate({
        inputRange: [0, 1],
        outputRange: [60, 60 + this.state.height],
      }),
    };

    const downStyle = {
      transform: [
        {
          rotate: this.state.open.interpolate({
            inputRange: [0, 1],
            outputRange: ["0deg", "180deg"],
          }),
        },
      ],
    };

    return (
      <Animated.View
        style={[styles.container, containerStyle, this.props.style]}
        className="block"
      >
        <View style={[styles.bar, { backgroundColor: this.props.color }]} />
        <TouchableOpacity onPress={this.changeOpen.bind(this)}>
          {typeof title === "object" ? (
            title
          ) : (
            <Text style={styles.title}>{title}</Text>
          )}
          <Animated.Image
            source={{ uri: Images.down }}
            style={[
              downStyle,
              {
                width: 18,
                height: 13,
                position: "absolute",
                right: 10,
                top: 23,
              },
            ]}
          />
        </TouchableOpacity>
        <Animated.View
          style={[bodyStyle]}
          onLayout={(event) =>
            this.setState({
              width: event.nativeEvent.layout.width,
              height: event.nativeEvent.layout.height,
            })
          }
        >
          {this.props.children}
        </Animated.View>
      </Animated.View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    padding: 22,
    paddingLeft: 16,
    paddingRight: 10,
    paddingTop: 0,
    maxWidth: 600,
    borderRadius: 5,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 24,
    shadowColor: Colors.text,
    shadowOpacity: 0.2,
    overflow: "hidden",
    marginBottom: 30,
    zIndex: 1000,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.text,
    paddingTop: 22,
    paddingBottom: 12,
  },
  bar: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: 6,
    backgroundColor: Colors.text,
    opacity: 0.4,
  },
});

export default Block;
