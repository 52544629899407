import { OPTIONS_REQUEST, OPTIONS_SUCCESS, OPTIONS_FAIL, HISTORICO_REQUEST, HISTORICO_SUCCESS, HISTORICO_FAIL } from "../constants";

const initialState = {
    loading: false,
    options: [],
    reservations: [],
};

const getStateConditionalScreen = (action, state) => {
    var newState = {};
    if (action.screen === "historico") {
        newState = { ...state };
    }
    return newState;
}

export const historico = (state = initialState, action) => {
    switch (action.type) {
        case OPTIONS_REQUEST:
            return { ...state, ...getStateConditionalScreen(action, { loading: true, reservations: [], options: [] }) }
        case OPTIONS_SUCCESS:
            return { ...state, ...getStateConditionalScreen(action, { loading: false, options: action.options }) }
        case OPTIONS_FAIL:
            return { ...state, ...getStateConditionalScreen(action, { loading: false, options: [] }) }
        case HISTORICO_REQUEST:
            return { ...state, loading: true, reservations: [] }
        case HISTORICO_SUCCESS:
            return { ...state, loading: false, reservations: action.reservations }
        case HISTORICO_FAIL:
            return { ...state, loading: false, reservations: [] }
        default:
            return state;
    }
};