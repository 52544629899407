import React, { Component } from "react";
import {
  View,
  Text,
  Image,
  StatusBar,
  Animated,
  Alert,
  TouchableOpacity,
  ActivityIndicator,
  // Platform
} from "react-native";
import { connect } from "react-redux";
import Modal from "../components/Modal";
import Input from "../components/Input";
// import {  } from '../../constants';
import { StyleSheet, Dimensions } from "react-native";
import {
  // Metrics,
  ApplicationStyles,
  Colors,
  Fonts,
  Images,
} from "../themes/";
import {
  LOGIN_REQUEST,
  VERIFY_LOGIN,
  GROUBIE_DETAILS_REQUEST,
  CLEAR_ERROR,
} from "../constants";
// import messaging from '@react-native-firebase/messaging';

class LoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginVisible: false,
      esqueceuSenha: false,
      modalAutenticacao: false,
    };

    this.opacityAnimation = new Animated.Value(0);
  }

  componentDidMount() {
    // TODO
    // if (Platform.OS!=='web') {
    //     messaging.hasPermission()
    //         .then(enabled => {
    //             if (enabled) {
    //                 // user has permissions
    //             } else {
    //                 messaging.requestPermission()
    //                     .then(() => {
    //                         // User has authorised
    //                     })
    //                     .catch(error => {
    //                         // User has rejected permissions
    //                     });
    //             }
    //         });
    // }

    this.props.verificarLogin();
    this.props.verificarGroubieDetails();
  }

  componentDidUpdate() {
    if (this.props.token) {
      this.props.verificarLogin();
    }
    if (this.props.error && this.props.error.type === "AUTHENTICATION") {
      this.setState({ modalAutenticacao: true });
      this.props.clearError();
    }
    if (this.props.total_reservations !== null) {
      Animated.spring(this.opacityAnimation, {
        toValue: 1,
        useNativeDriver: false,
      }).start();
    }
  }

  doLogin() {
    if (
      this.email &&
      this.password &&
      this.email.length > 0 &&
      this.password.length > 0
    ) {
      this.props.login(this.email, this.password);
    } else {
      Alert.alert(
        "Preencha os campos",
        "E-mail e Senha não podem ser vazios",
        [{ text: "OK", onPress: () => console.log("OK Pressed") }],
        { cancelable: false }
      );
    }
  }

  esqueceuSenha() {
    // if (this.emailSenha && this.emailSenha.length > 0) {
    //     this.props.esqueceuSenha(this.emailSenha, () => {
    //         this.setState({ confirmacao: true, confirmacaoText: 'Verifique seu e-mail! Te enviamos as instruções para redefinição de senha.' });
    //     });
    // } else {
    //     Alert.alert(
    //         'Preencha o email',
    //         'E-mail não pode ser vazio',
    //         [
    //             { text: 'OK', onPress: () => console.log('OK Pressed') },
    //         ],
    //         { cancelable: false }
    //     );
    // }
  }

  openLogin() {
    this.setState({ loginVisible: true });
    // setTimeout(() => {
    //     this.setState({ loginVisible: false });
    // },3000);
  }

  renderEsqueceuSenha() {
    return (
      <Modal
        visible={this.state.esqueceuSenha}
        onClosePress={() => {
          this.setState({ esqueceuSenha: false });
        }}
      >
        <Text style={styles.title}>Esqueceu Senha?</Text>
        <View style={styles.divider} />
        <View style={[styles.inputContainer]}>
          <Input
            placeholder="E-mail"
            icon="email"
            onChangeValue={(text) => (this.emailSenha = text)}
          />
        </View>
        <View
          style={[
            styles.buttonContainer,
            { paddingTop: 32, paddingBottom: 20 },
          ]}
        >
          <TouchableOpacity
            loading={this.props.sending}
            style={styles.button}
            onPress={() => this.esqueceuSenha()}
          >
            Resetar senha
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }

  renderConfirmacao() {
    return (
      <Modal
        visible={this.state.confirmacao}
        onClosePress={() => {
          this.setState({ confirmacao: false });
        }}
      >
        <Text
          // style={styles.title}
          style={{ textAlign: "center", marginLeft: 10, marginRight: 10 }}
        >
          {this.state.confirmacaoText}
        </Text>
        <View style={styles.divider} />
        <View
          style={[
            styles.buttonContainer,
            { paddingTop: 32, paddingBottom: 22 },
          ]}
        >
          <TouchableOpacity
            loading={this.props.sending}
            style={styles.button}
            onPress={() => {
              this.setState({ confirmacao: false });
            }}
          >
            OK
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }

  renderAlertaAutenticacao() {
    return (
      <Modal
        visible={this.state.modalAutenticacao}
        onClosePress={() => {
          this.setState({ modalAutenticacao: false });
        }}
        color={Colors.danger}
      >
        <Text style={styles.title}>Erro de autenticação!</Text>
        <View style={styles.divider} />
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            Verifique se o e-mail e senha foram digitados corretamente
          </Text>
        </View>
        <View style={[styles.buttonContainer, { paddingBottom: 42 }]}>
          <TouchableOpacity
            type="underline"
            style={[styles.button, { backgroundColor: Colors.danger }]}
            color={Colors.danger}
            onPress={() => {
              this.setState({ modalAutenticacao: false });
            }}
          >
            <Text style={[styles.buttonText, { color: Colors.white }]}>
              Tentar novamente
            </Text>
          </TouchableOpacity>
        </View>
      </Modal>
    );
  }

  render() {
    const opacityStyle = {
      opacity: this.opacityAnimation,
    };

    return (
      <View style={styles.container}>
        <StatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent
        />
        <Image
          style={styles.background}
          source={{ uri: Images.login_background }}
        />
        <Image style={styles.logo} source={{ uri: Images.groubie }} />
        <View style={{ width: 280, alignSelf: "center" }}>
          <Animated.View style={opacityStyle}>
            <Text style={[styles.text, { marginBottom: 30 }]} type="text">
              Mais de
              <Text style={styles.text} type="accent">
                {" "}
                {this.props.total_reservations}{" "}
              </Text>
              pessoas usaram o nosso app para economizar aproximadamente
              <Text style={styles.text} type="accent">
                {" "}
                R$ {this.props.total_discounts}{" "}
              </Text>
              em descontos. Essa é a hora de você participar. Use Reduz!
            </Text>
          </Animated.View>
          {this.props.loading && (
            <ActivityIndicator
              size="large"
              style={{ alignSelf: "center", marginTop: 20 }}
              color={Colors.primary}
            />
          )}
          {!this.props.loading && (
            <TouchableOpacity
              style={styles.button}
              type="email"
              onPress={this.openLogin.bind(this)}
            >
              <Text style={styles.buttonText}>Entre com o e-mail</Text>
            </TouchableOpacity>
          )}
        </View>

        <Modal
          visible={this.state.loginVisible}
          onClosePress={() => {
            this.setState({ loginVisible: false });
          }}
        >
          <Text style={styles.title}>Tá na hora de logar!</Text>
          <View style={styles.divider} />
          <View style={[styles.inputContainer]}>
            <Input
              lower={true}
              placeholder="E-mail"
              icon="email"
              style={{ width: "100%" }}
              onChangeValue={(text) => (this.email = text)}
            />
          </View>
          <View style={[styles.inputContainer]}>
            <Input
              placeholder="Senha"
              icon="password"
              style={{ width: "100%" }}
              secureTextEntry={true}
              onChangeValue={(text) => (this.password = text)}
            />
          </View>
          {this.props.loading && (
            <ActivityIndicator
              size="large"
              style={{ alignSelf: "center", marginTop: 20 }}
              color={Colors.primary}
            />
          )}
          <View style={[styles.buttonContainer, { paddingTop: 32 }]}>
            <TouchableOpacity
              style={styles.button}
              onPress={() => this.doLogin()}
            >
              <Text style={styles.buttonText}>Entrar</Text>
            </TouchableOpacity>
          </View>
        </Modal>

        {this.renderEsqueceuSenha()}
        {this.renderConfirmacao()}
        {this.renderAlertaAutenticacao()}
      </View>
    );
  }
}

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;

const styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  background: {
    flex: 1,
    width: SCREEN_WIDTH,
    height: SCREEN_HEIGHT,
    resizeMode: "cover",
    position: "absolute",
    zIndex: -1,
  },
  groubie: {
    width: 160,
    height: 32,
    marginBottom: 25,
  },
  logo: {
    width: 80,
    height: 80,
    marginBottom: 10,
  },
  inputContainer: {
    flexDirection: "row",
    paddingLeft: 19,
    paddingRight: 19,
    width: "100%",
  },
  buttonContainer: {
    flexDirection: "row",
    paddingLeft: 18,
    paddingRight: 18,
    width: "100%",
  },
  button: {
    width: "100%",
    marginBottom: 10,
    height: 40,
    backgroundColor: Colors.primary,
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: Fonts.type.medium,
    fontSize: 15,
    color: Colors.white,
    textAlign: "center",
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 18,
    color: Colors.text,
    marginBottom: 10,
    textAlign: "center",
  },
  text: {
    fontFamily: Fonts.type.regular,
    fontSize: 15,
    color: Colors.text,
    textAlign: "center",
  },
  textContainer: {
    width: 300,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 40,
  },
  divider: {
    width: 130,
    height: 1,
    backgroundColor: Colors.divider,
  },
  pularButton: {
    position: "absolute",
    top: 40,
    right: 20,
  },
  pularText: {
    fontFamily: Fonts.type.regular,
    fontSize: 18,
    color: Colors.text,
  },
});

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    token: state.auth.token,
    user: state.auth.user,
    error: state.auth.error,
    total_reservations: state.auth.total_reservations,
    total_discounts: state.auth.total_discounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verificarGroubieDetails: () => {
      dispatch({ type: GROUBIE_DETAILS_REQUEST });
    },
    verificarLogin: () => {
      dispatch({ type: VERIFY_LOGIN });
    },
    login: (email, password) => {
      dispatch({ type: LOGIN_REQUEST, email, password });
    },
    // loginFirebase: ({ email, first_name, last_name, firebase_token, firebase_user_id }, isFacebook) => {
    //     dispatch({ type: LOGIN_FIREBASE_REQUEST, email, first_name, last_name, firebase_token, firebase_user_id, isFacebook })
    // },
    // esqueceuSenha: (email, onSuccess) => {
    //     dispatch({ type: RESET_PASSWORD_REQUEST, email, onSuccess });
    // },
    clearError: () => {
      dispatch({ type: CLEAR_ERROR });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
