export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const VERIFY_LOGIN = "VERIFY_LOGIN";
export const LOGIN_SET = "LOGIN_SET";
export const LOGOUT = "LOGOUT";
export const GROUBIE_DETAILS_REQUEST = "GROUBIE_DETAILS_REQUEST";
export const GROUBIE_DETAILS_SUCCESS = "GROUBIE_DETAILS_SUCCESS";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const SELECT_RESTAURANT = "SELECT_RESTAURANT";

export const DASHBOARD_SET_VALUE = "DASHBOARD_SET_VALUE";
export const DASHBOARD_CHANGE_FILTER = "DASHBOARD_CHANGE_FILTER";
export const LIST_RESTAURANTS = "LIST_RESTAURANTS";

export const RESTAURANT_SET_VALUE = "RESTAURANT_SET_VALUE";

export const RESTAURANT_SEARCH = "RESTAURANT_SEARCH";

export const RESTAURANT_CITY_CHANGED = "RESTAURANT_CITY_CHANGED";
export const RESTAURANT_CHANGE_ACTIVE = "RESTAURANT_CHANGE_ACTIVE";

export const RESTAURANT_LIST_REQUEST = "RESTAURANT_LIST_REQUEST";
export const RESTAURANT_LIST_SUCCESS = "RESTAURANT_LIST_SUCCESS";
export const RESTAURANT_LIST_FAIL = "RESTAURANT_LIST_FAIL";
export const RESTAURANT_LIST_SET = "RESTAURANT_LIST_SET";

export const RESTAURANT_REQUEST = "RESTAURANT_REQUEST";
export const RESTAURANT_SUCCESS = "RESTAURANT_SUCCESS";
export const RESTAURANT_FAIL = "RESTAURANT_FAIL";

export const RESTAURANT_DADOS_PESSOAIS_REQUEST =
  "RESTAURANT_DADOS_PESSOAIS_REQUEST";
export const RESTAURANT_DADOS_PESSOAIS_SUCCESS =
  "RESTAURANT_DADOS_PESSOAIS_SUCCESS";
export const RESTAURANT_DADOS_PESSOAIS_FAIL = "RESTAURANT_DADOS_PESSOAIS_FAIL";

export const RESTAURANT_ENDERECO_REQUEST = "RESTAURANT_ENDERECO_REQUEST";
export const RESTAURANT_ENDERECO_SUCCESS = "RESTAURANT_ENDERECO_SUCCESS";
export const RESTAURANT_ENDERECO_FAIL = "RESTAURANT_ENDERECO_FAIL";

export const RESTAURANT_MIDIA_REQUEST = "RESTAURANT_MIDIA_REQUEST";
export const RESTAURANT_MIDIA_SUCCESS = "RESTAURANT_MIDIA_SUCCESS";
export const RESTAURANT_MIDIA_FAIL = "RESTAURANT_MIDIA_FAIL";

export const RESTAURANT_COMPLEMENTOS_REQUEST =
  "RESTAURANT_COMPLEMENTOS_REQUEST";
export const RESTAURANT_COMPLEMENTOS_SUCCESS =
  "RESTAURANT_COMPLEMENTOS_SUCCESS";
export const RESTAURANT_COMPLEMENTOS_FAIL = "RESTAURANT_COMPLEMENTOS_FAIL";

export const RESTAURANT_PRECOS_REQUEST = "RESTAURANT_PRECOS_REQUEST";
export const RESTAURANT_PRECOS_SUCCESS = "RESTAURANT_PRECOS_SUCCESS";
export const RESTAURANT_PRECOS_FAIL = "RESTAURANT_PRECOS_FAIL";

export const RESTAURANT_RESERVAS_REQUEST = "RESTAURANT_RESERVAS_REQUEST";
export const RESTAURANT_RESERVAS_SUCCESS = "RESTAURANT_RESERVAS_SUCCESS";
export const RESTAURANT_RESERVAS_FAIL = "RESTAURANT_RESERVAS_FAIL";

export const RESTAURANT_GOS_REQUEST = "RESTAURANT_GOS_REQUEST";
export const RESTAURANT_CONDITIONS_REQUEST = "RESTAURANT_CONDITIONS_REQUEST";
export const RESTAURANT_GOS_SUCCESS = "RESTAURANT_GOS_SUCCESS";
export const RESTAURANT_GOS_FAIL = "RESTAURANT_GOS_FAIL";

export const RESTAURANT_CONTATOS_REQUEST = "RESTAURANT_CONTATOS_REQUEST";
export const RESTAURANT_CONTATOS_SUCCESS = "RESTAURANT_CONTATOS_SUCCESS";
export const RESTAURANT_CONTATOS_FAIL = "RESTAURANT_CONTATOS_FAIL";

export const RESTAURANT_PAUSE_LIST_REQUEST = "RESTAURANT_PAUSE_LIST_REQUEST";
export const RESTAURANT_PAUSE_CHANGE_REQUEST =
  "RESTAURANT_PAUSE_CHANGE_REQUEST";
export const RESTAURANT_RESERVES_LIST_REQUEST =
  "RESTAURANT_RESERVES_LIST_REQUEST";

export const RESTAURANT_PUBLISH_REQUEST = "RESTAURANT_PUBLISH_REQUEST";
export const RESTAURANT_PUBLISH_SUCCESS = "RESTAURANT_PUBLISH_SUCCESS";
export const RESTAURANT_PUBLISH_FAIL = "RESTAURANT_PUBLISH_FAIL";

export const RESERVATION_LIST_REQUEST = "RESERVATION_LIST_REQUEST";
export const RESERVATION_LIST_SUCCESS = "RESERVATION_LIST_SUCCESS";
export const RESERVATION_LIST_FAIL = "RESERVATION_LIST_FAIL";

export const RESERVATION_SAVE_REQUEST = "RESERVATION_SAVE_REQUEST";
export const RESERVATION_SAVE_SUCCESS = "RESERVATION_SAVE_SUCCESS";
export const RESERVATION_SAVE_FAIL = "RESERVATION_SAVE_FAIL";

export const ACOMPANHAMENTO_REQUEST = "ACOMPANHAMENTO_REQUEST";
export const ACOMPANHAMENTO_SUCCESS = "ACOMPANHAMENTO_SUCCESS";
export const ACOMPANHAMENTO_FAIL = "ACOMPANHAMENTO_FAIL";
export const ACOMPANHAMENTO_RANGE_REQUEST = "ACOMPANHAMENTO_RANGE_REQUEST";

export const OPTIONS_REQUEST = "OPTIONS_REQUEST";
export const OPTIONS_SUCCESS = "OPTIONS_SUCCESS";
export const OPTIONS_FAIL = "OPTIONS_FAIL";

export const FEED_REQUEST = "FEED_REQUEST";
export const FEED_SUCCESS = "FEED_SUCCESS";
export const FEED_FAIL = "FEED_FAIL";

export const HISTORICO_REQUEST = "HISTORICO_REQUEST";
export const HISTORICO_SUCCESS = "HISTORICO_SUCCESS";
export const HISTORICO_FAIL = "HISTORICO_FAIL";

export const AVALIACAO_REQUEST = "AVALIACAO_REQUEST";
export const AVALIACAO_SUCCESS = "AVALIACAO_SUCCESS";
export const AVALIACAO_FAIL = "AVALIACAO_FAIL";

export const AVALIACAO_COMMENT_REQUEST = "AVALIACAO_COMMENT_REQUEST";
export const AVALIACAO_COMMENT_SUCCESS = "AVALIACAO_COMMENT_SUCCESS";
export const AVALIACAO_COMMENT_FAIL = "AVALIACAO_COMMENT_FAIL";
