import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import Sagas from "../sagas/";
import { reducers } from "../reducers";

const sagaMiddleware = createSagaMiddleware();

export const Store = createStore(reducers, applyMiddleware(sagaMiddleware));

// then run the saga
sagaMiddleware.run(Sagas);
