import React, { Component } from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import Block from "./Block";
import { Fonts, Colors, Images } from "../themes";

class Filters extends Component {
  static defaultProps = {
    cities: [],
    activies: ["Ativo", "Inativo"],
    onChangeFilters: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      filter_city: [1],
      filter_active: ["Ativo"],
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.onChangeFilters(
        this.state.filter_city,
        this.state.filter_active
      );
    }, 1000);
  }

  changeCityFilter(city_id) {
    const list = this.state.filter_city;
    if (list.includes(city_id)) {
      list.splice(list.indexOf(city_id), 1);
    } else {
      list.push(city_id);
    }

    this.setState({ filter_city: list }, () => {
      this.props.onChangeFilters(
        this.state.filter_city,
        this.state.filter_active
      );
    });
  }

  changeActiveFilter(item) {
    const list = this.state.filter_active;
    if (list.includes(item)) {
      list.splice(list.indexOf(item), 1);
      this.setState({ filter_active: list }, () => {
        this.props.onChangeFilters(
          this.state.filter_city,
          this.state.filter_active
        );
      });
    } else {
      this.setState({ filter_active: [item] }, () => {
        this.props.onChangeFilters(
          this.state.filter_city,
          this.state.filter_active
        );
      });
    }
  }

  renderHeader() {
    const { filter_city, filter_active } = this.state;
    const cidades_ativas = this.props.cities.filter((city) =>
      filter_city.includes(city.id)
    );
    const ativos = this.props.activies.filter((item) =>
      filter_active.includes(item)
    );

    return (
      <View
        style={{
          flexDirection: "row",
          marginRight: 40,
          marginTop: 15,
          paddingLeft: 5,
        }}
      >
        {cidades_ativas.map((city, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => this.changeCityFilter(city.id)}
              style={[
                styles.filterContainer,
                filter_city.includes(city.id)
                  ? { backgroundColor: Colors.accent }
                  : {},
              ]}
            >
              <Text
                style={[
                  styles.filterText,
                  filter_city.includes(city.id) ? { color: Colors.white } : {},
                ]}
              >
                {city.name}
              </Text>
              <Image
                source={{ uri: Images.plus_filter }}
                style={[
                  styles.plus,
                  filter_city.includes(city.id)
                    ? {
                        tintColor: Colors.accent_dark,
                        transform: [{ rotate: "-45deg" }],
                      }
                    : {},
                ]}
              />
            </TouchableOpacity>
          );
        })}
        {ativos.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => this.changeActiveFilter(item)}
              style={[
                styles.filterContainer,
                filter_active.includes(item)
                  ? { backgroundColor: Colors.accent }
                  : {},
              ]}
            >
              <Text
                style={[
                  styles.filterText,
                  filter_active.includes(item) ? { color: Colors.white } : {},
                ]}
              >
                {item}
              </Text>
              <Image
                source={{ uri: Images.plus_filter }}
                style={[
                  styles.plus,
                  filter_active.includes(item)
                    ? {
                        tintColor: Colors.accent_dark,
                        transform: [{ rotate: "-45deg" }],
                      }
                    : {},
                ]}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }

  renderFilters() {
    const { filter_city, filter_active } = this.state;
    return (
      <React.Fragment>
        <Text style={styles.filterTitle}>Cidades</Text>
        <View
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          {this.props.cities.map((city) => {
            return (
              <TouchableOpacity
                onPress={() => this.changeCityFilter(city.id)}
                style={[
                  styles.filterContainer,
                  filter_city.includes(city.id)
                    ? { backgroundColor: Colors.accent }
                    : {},
                ]}
              >
                <Text
                  style={[
                    styles.filterText,
                    filter_city.includes(city.id)
                      ? { color: Colors.white }
                      : {},
                  ]}
                >
                  {city.name}
                </Text>
                <Image
                  source={{ uri: Images.plus_filter }}
                  style={[
                    styles.plus,
                    filter_city.includes(city.id)
                      ? {
                          tintColor: Colors.accent_dark,
                          transform: [{ rotate: "-45deg" }],
                        }
                      : {},
                  ]}
                />
              </TouchableOpacity>
            );
          })}
        </View>
        <Text style={styles.filterTitle}>Exibir</Text>
        <View
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          {this.props.activies.map((item) => {
            return (
              <TouchableOpacity
                onPress={() => this.changeActiveFilter(item)}
                style={[
                  styles.filterContainer,
                  filter_active.includes(item)
                    ? { backgroundColor: Colors.accent }
                    : {},
                ]}
              >
                <Text
                  style={[
                    styles.filterText,
                    filter_active.includes(item) ? { color: Colors.white } : {},
                  ]}
                >
                  {item}
                </Text>
                <Image
                  source={{ uri: Images.plus_filter }}
                  style={[
                    styles.plus,
                    filter_active.includes(item)
                      ? {
                          tintColor: Colors.accent_dark,
                          transform: [{ rotate: "-45deg" }],
                        }
                      : {},
                  ]}
                />
              </TouchableOpacity>
            );
          })}
        </View>
        <View style={{ height: 50 }} />
      </React.Fragment>
    );
  }

  render() {
    return (
      <Block
        color={"transparent"}
        title={this.renderHeader()}
        style={{ padding: 0, marginBottom: 0 }}
      >
        {this.renderFilters()}
      </Block>
    );
  }
}

const styles = StyleSheet.create({
  filterContainer: {
    height: 24,
    borderRadius: 12,
    marginTop: 5,
    marginLeft: 3,
    backgroundColor: Colors.fill,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    flexDirection: "row",
  },
  filterTitle: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginTop: 20,
  },
  filterText: {
    fontFamily: Fonts.type.medium,
    fontSize: 12,
    color: Colors.text,
  },
  plus: {
    tintColor: Colors.text,
    marginLeft: 5,
    width: 12,
    height: 12,
  },
});

export default Filters;
