import React, { Component } from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { Fonts, Colors } from "../themes";

class Selector extends Component {
  static defaultProps = {
    options: [],
    selected: [],
    onClick: () => {},
  };

  renderOption(option, index) {
    const isSelected = this.props.selected.includes(option);
    return (
      <TouchableOpacity
        key={index}
        style={[
          styles.itemContainer,
          isSelected ? styles.itemSelectedContainer : {},
        ]}
        onPress={() => this.props.onClick(option)}
      >
        <Text style={[styles.title, isSelected ? styles.titleSelected : {}]}>
          {option}
        </Text>
      </TouchableOpacity>
    );
  }

  render() {
    return (
      <View style={[styles.container]}>
        {this.props.options.map((option, index) =>
          this.renderOption(option, index)
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#fff",
    borderWidth: 1,
    borderRadius: 3,
    borderColor: Colors.divider,
    padding: 10,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 12,
    color: Colors.text,
  },
  titleSelected: {
    color: Colors.white,
  },
  itemContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 34,
    borderRadius: 6,
    paddingLeft: 20,
    paddingRight: 20,
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: Colors.fill,
  },
  itemSelectedContainer: {
    backgroundColor: Colors.accent,
  },
});

export default Selector;
