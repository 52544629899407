import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Picker,
  TouchableOpacity,
} from "react-native";
// import styled from "styled-components/native";
import { Fonts, Colors, Images } from "../themes";
import moment from "moment";

class Reservas extends Component {
  static defaultProps = {
    onChange: () => {},
  };

  state = {
    dayIndex: 0,
    desconto: 10,
    replicar: [],
    lugares: 6,
    selectedAll: false,
    days: [
      { name: "Segunda", reservas: [] },
      { name: "Terça", reservas: [] },
      { name: "Quarta", reservas: [] },
      { name: "Quinta", reservas: [] },
      { name: "Sexta", reservas: [] },
      { name: "Sábado", reservas: [] },
      { name: "Domingo", reservas: [] },
    ],
  };

  constructor(props) {
    super(props);
    this.descontos = [10, 15, 20, 25, 30];
    this.lugares = ["06", "08", "10", "12", "16", "20", "24"];
    this.hours = [];

    var m = moment("00:00", "HH:mm");
    while (true) {
      var hour = m.format("HH:mm");
      if (this.hours.includes(hour)) {
        break;
      } else {
        this.hours.push(hour);
        m = m.add(15, "minutes");
      }
    }
  }

  componentDidUpdate() {
    if (
      this.initial !== this.props.initial &&
      this.props.initial &&
      this.props.initial.length >= 7
    ) {
      this.initial = this.props.initial;
      this.setState({ days: this.initial });
    }
  }

  applyChanges(day) {
    const { days, desconto, lugares } = this.state;
    days[day].reservas
      .filter((r) => r.selected)
      .forEach((r) => {
        r.desconto = desconto;
        r.lugares = lugares;
      });
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  applyReplication(dayIndex, replicar) {
    const { days } = this.state;
    const day = days[dayIndex];
    replicar.forEach((r) => {
      days[r].reservas = day.reservas;
    });
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  addReserve(day, hora, index) {
    const { days } = this.state;
    var m = moment(hora, "HH:mm");
    m = m.add(15, "minutes");
    hora = m.format("HH:mm");

    if (index === -1) {
      days[day].reservas.push({
        hora: hora,
        desconto: this.state.desconto,
        lugares: this.state.lugares,
        selected: false,
      });
    } else {
      days[day].reservas.splice(index + 1, 0, {
        hora: hora,
        desconto: this.state.desconto,
        lugares: this.state.lugares,
        selected: false,
      });
    }

    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  removeReserve(day, index) {
    const { days } = this.state;
    days[day].reservas.splice(index, 1);
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  deleteAllReserves(day) {
    const { days } = this.state;
    days[day].reservas = days[day].reservas.filter((r) => !r.selected);
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  selectAllReserves(day, check) {
    const { days } = this.state;
    days[day].reservas.forEach((reserva) => {
      reserva.selected = check;
    });

    this.setState({
      selectedAll: check,
      days: JSON.parse(JSON.stringify(days)),
    });
    this.props.onChange(days);
  }

  selectProperty(day, index, key, value) {
    const { days } = this.state;
    days[day].reservas[index][key] = value;
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  changeReplicar(day) {
    const { replicar } = this.state;
    if (replicar.includes(day)) {
      replicar.splice(replicar.indexOf(day), 1);
    } else {
      replicar.push(day);
    }
    this.setState({ replicar: JSON.parse(JSON.stringify(replicar)) });
  }

  renderDays() {
    const { dayIndex, days } = this.state;
    return (
      <View style={styles.daysContainer}>
        {days.map((day, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.dayItem,
                dayIndex === index ? styles.dayItemSelected : {},
                index === days.length - 1 ? { marginRight: 0 } : {},
              ]}
              onPress={() => this.setState({ dayIndex: index, replicar: [] })}
            >
              <Text
                style={[
                  styles.dayTitle,
                  dayIndex === index ? styles.dayTitleSelected : {},
                ]}
              >
                {day.name.toUpperCase()}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }

  renderDescontos() {
    return (
      <View style={styles.descontoContainer}>
        <View style={styles.listaDescontoContainer}>
          <Text style={styles.subtitle}>Desconto: </Text>
          {this.descontos.map((desconto, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={[
                  styles.descontoItem,
                  desconto === this.state.desconto
                    ? styles.descontoItemSelected
                    : {},
                ]}
                onPress={() => this.setState({ desconto })}
              >
                <Text style={styles.descontoItemTitle}>{desconto}%</Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <Image
          source={{ uri: Images.plus }}
          style={{
            width: 10,
            height: 10,
            tintColor: Colors.primary_dark,
            marginRight: 5,
          }}
        />
        <View style={[styles.listaDescontoContainer]}>
          <Text style={styles.subtitle}>Lugares: </Text>
          {this.lugares.map((lugares, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={[
                  styles.descontoItem,
                  { width: 23 },
                  parseInt(lugares) === this.state.lugares
                    ? styles.descontoItemSelected
                    : {},
                ]}
                onPress={() => this.setState({ lugares: parseInt(lugares) })}
              >
                <Text style={styles.descontoItemTitle}>{lugares}</Text>
              </TouchableOpacity>
            );
          })}
        </View>
        <TouchableOpacity
          style={styles.aplicarButton}
          onPress={() => this.applyChanges(this.state.dayIndex)}
        >
          <Text style={styles.dayTitle}>Aplicar</Text>
        </TouchableOpacity>
      </View>
    );
  }

  renderReservas() {
    const { days, dayIndex, selectedAll } = this.state;
    const reservas = days[dayIndex].reservas;

    return (
      <View>
        <View style={styles.reservaItem}>
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => this.selectAllReserves(dayIndex, !selectedAll)}
              style={[
                styles.checkButton,
                selectedAll ? styles.checkButtonSelected : {},
              ]}
            >
              {selectedAll && (
                <Image source={{ uri: Images.check }} style={styles.check} />
              )}
            </TouchableOpacity>
          </View>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            HORÁRIO
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            DESCONTO
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            LUGARES
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            PONTOS
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            L. SEM %
          </Text>
          <TouchableOpacity
            onPress={() => this.addReserve(dayIndex, "11:45", -1)}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={{ uri: Images.reserve_add }}
              style={{ width: 18, height: 18 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.deleteAllReserves(dayIndex)}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={{ uri: Images.reserve_remove }}
              style={{ width: 18, height: 18 }}
            />
          </TouchableOpacity>
        </View>
        {reservas &&
          reservas.length > 0 &&
          reservas.map((reserva, index) => {
            return (
              <View style={styles.reservaItem} key={index} className="reservas">
                <View
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() =>
                      this.selectProperty(
                        dayIndex,
                        index,
                        "selected",
                        !reserva.selected
                      )
                    }
                    style={[
                      styles.checkButton,
                      reserva.selected ? styles.checkButtonSelected : {},
                    ]}
                  >
                    {reserva.selected && (
                      <Image
                        source={{ uri: Images.check }}
                        style={styles.check}
                      />
                    )}
                  </TouchableOpacity>
                </View>
                <Picker
                  selectedValue={reserva.hora}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(dayIndex, index, "hora", itemValue)
                  }
                  style={[
                    {
                      color: Colors.text,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.hours.map((hora, index) => {
                    return (
                      <Picker.Item key={index} label={hora} value={hora} />
                    );
                  })}
                </Picker>
                <Picker
                  selectedValue={reserva.desconto}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(
                      dayIndex,
                      index,
                      "desconto",
                      this.descontos[itemIndex]
                    )
                  }
                  style={[
                    {
                      color: Colors.primary_dark,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.descontos.map((desconto, index) => {
                    return (
                      <Picker.Item
                        key={index}
                        label={`${desconto}%`}
                        value={desconto}
                      />
                    );
                  })}
                </Picker>
                <Picker
                  selectedValue={reserva.lugares}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(
                      dayIndex,
                      index,
                      "lugares",
                      parseInt(itemValue)
                    )
                  }
                  style={[
                    {
                      color: Colors.accent_dark,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.lugares.map((lugares, index) => {
                    return (
                      <Picker.Item
                        key={index}
                        label={lugares}
                        value={parseInt(lugares)}
                      />
                    );
                  })}
                </Picker>
                <Text
                  style={[
                    styles.dayTitle,
                    { textAlign: "center", flex: 3, opacity: 0.3 },
                  ]}
                >
                  0
                </Text>
                <Text
                  style={[
                    styles.dayTitle,
                    { textAlign: "center", flex: 3, opacity: 0.3 },
                  ]}
                >
                  0
                </Text>
                <TouchableOpacity
                  onPress={() => this.addReserve(dayIndex, reserva.hora, index)}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{ uri: Images.reserve_add }}
                    style={{ width: 18, height: 18 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.removeReserve(dayIndex, index)}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{ uri: Images.reserve_remove }}
                    style={{ width: 18, height: 18 }}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
        {(!reservas || reservas.length === 0) && (
          <Text
            style={[
              styles.subtitle,
              { textAlign: "center", marginTop: 20, marginBottom: 20 },
            ]}
          >
            Nenhuma reserva adicionada neste dia
          </Text>
        )}
      </View>
    );
  }

  renderReplicar() {
    const { days, dayIndex } = this.state;
    return (
      <View style={[styles.descontoContainer, { marginTop: 5 }]}>
        <Text style={[styles.subtitle, { marginLeft: 5, marginRight: 5 }]}>
          Replicar
        </Text>
        <View
          style={[
            styles.descontoItem,
            {
              width: "auto",
              height: 25,
              paddingLeft: 8,
              paddingRight: 8,
              borderRadius: 3,
            },
          ]}
        >
          <Text style={styles.descontoItemTitle}>
            {days[dayIndex].name.toUpperCase()}
          </Text>
        </View>
        <Text style={[styles.subtitle, { marginLeft: 5, marginRight: 5 }]}>
          em
        </Text>
        {days.map((day, index) => {
          if (index !== dayIndex) {
            const isSelected = this.state.replicar.includes(index);
            return (
              <TouchableOpacity
                key={index}
                onPress={() => this.changeReplicar(index)}
                style={[
                  styles.descontoItem,
                  isSelected ? styles.descontoItemSelected : {},
                  {
                    width: "auto",
                    height: 25,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 3,
                  },
                ]}
              >
                <Text style={styles.descontoItemTitle}>
                  {day.name.toUpperCase()}
                </Text>
              </TouchableOpacity>
            );
          }
          return null;
        })}
        <TouchableOpacity
          style={styles.aplicarButton}
          onPress={() => this.applyReplication(dayIndex, this.state.replicar)}
        >
          <Text style={styles.dayTitle}>Aplicar</Text>
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    return (
      <View style={[styles.container]}>
        {this.renderDays()}
        {this.renderDescontos()}
        {this.renderReservas()}
        {this.renderReplicar()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    // minWidth: 570,
  },
  daysContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  dayItem: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 34,
    borderRadius: 6,
    minWidth: 70,
    marginBottom: 5,
    marginRight: 2,
    backgroundColor: Colors.fill,
  },
  dayItemSelected: {
    backgroundColor: Colors.accent,
  },
  dayTitle: {
    fontFamily: Fonts.type.medium,
    fontSize: 12,
    color: Colors.text,
  },
  dayTitleSelected: {
    color: Colors.white,
  },
  descontoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 35,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: Colors.border,
    backgroundColor: Colors.fill,
  },
  listaDescontoContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: 235,
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    fontFamily: Fonts.type.regular,
    fontSize: 12,
    color: Colors.text,
  },
  descontoItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 18,
    width: 29,
    marginLeft: 3,
    borderRadius: 1,
    backgroundColor: Colors.accent,
  },
  descontoItemSelected: {
    paddingTop: 4,
    borderBottomWidth: 4,
    borderColor: Colors.accent_dark,
  },
  descontoItemTitle: {
    fontFamily: Fonts.type.medium,
    fontSize: 10,
    color: Colors.white,
  },
  aplicarButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 35,
    minWidth: 40,
    marginLeft: 3,
    borderLeftWidth: 2,
    borderColor: Colors.border,
  },
  reservaItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 35,
    borderWidth: 2,
    borderColor: Colors.border,
    borderRadius: 3,
    marginTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 18,
    height: 18,
    borderRadius: 3,
    backgroundColor: Colors.fill,
  },
  checkButtonSelected: {
    backgroundColor: Colors.primary,
  },
  check: {
    width: 11,
    height: 8,
  },
});

export default Reservas;
