const images = {
  build: require("../images/build.png"),
  cacto: require("../images/cacto.png"),
  camelo: require("../images/camelo.png"),
  check: require("../images/check.png"),
  contact_add: require("../images/contact_add.png"),
  contestacao_icon: require("../images/contestacao_icon.png"),
  close: require("../images/close.png"),
  down: require("../images/down.png"),
  expand: require("../images/expand.png"),
  groubie: require("../images/logo.png"),
  input_checked: require("../images/input_checked.png"),
  input_warning: require("../images/input_warning.png"),
  keyboard: require("../images/keyboard.png"),
  locked: require("../images/locked.png"),
  logo: require("../images/logo.png"),
  logo_green: require("../images/logo_green.png"),
  logo_white: require("../images/logo_white.png"),
  login_background: require("../images/login_background.png"),
  plus: require("../images/plus.png"),
  plus_filter: require("../images/plus_filter.png"),
  pause: require("../images/pause.png"),
  pausas: require("../images/pausas.png"),
  resume: require("../images/resume.png"),
  reservations: require("../images/reservations.png"),
  reserve_add: require("../images/reserve_add.png"),
  reserve_remove: require("../images/reserve_remove.png"),
  restaurants: require("../images/restaurants.png"),
  sair: require("../images/sair.png"),
  search: require("../images/search.png"),
  sushisan: require("../images/sushisan.png"),
  star: require("../images/star.png"),
  star_empty: require("../images/star_empty.png"),
  star_rating: require("../images/star_rating.png"),
  upload: require("../images/upload.png"),
};

export default images;
