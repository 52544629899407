import { call, put } from "redux-saga/effects";
import {
  RESTAURANT_DADOS_PESSOAIS_FAIL,
  RESTAURANT_DADOS_PESSOAIS_SUCCESS,
  RESTAURANT_ENDERECO_SUCCESS,
  RESTAURANT_ENDERECO_FAIL,
  RESTAURANT_FAIL,
  RESTAURANT_SUCCESS,
  RESTAURANT_COMPLEMENTOS_SUCCESS,
  RESTAURANT_COMPLEMENTOS_FAIL,
  RESTAURANT_RESERVAS_SUCCESS,
  RESTAURANT_RESERVAS_FAIL,
  RESTAURANT_MIDIA_SUCCESS,
  RESTAURANT_MIDIA_FAIL,
  RESTAURANT_GOS_SUCCESS,
  RESTAURANT_GOS_FAIL,
  RESTAURANT_SET_VALUE,
  RESTAURANT_CITY_CHANGED,
  RESTAURANT_CONTATOS_SUCCESS,
  RESTAURANT_CONTATOS_FAIL,
  RESTAURANT_PRECOS_SUCCESS,
  RESTAURANT_PRECOS_FAIL,
  RESTAURANT_PUBLISH_SUCCESS,
  RESTAURANT_PUBLISH_FAIL,
} from "../constants";

export function* bairros(api, action) {
  try {
    const response = yield call(api.bairros, action.id);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: RESTAURANT_SET_VALUE,
      key: "bairros_list",
      value: response.data.neighborhoods,
    });
  } catch (e) {
    console.error(e);
  }
}

export function* dados(api, action) {
  try {
    const response = yield call(api.dados, action.id);
    if (response.problem !== null) {
      throw new Error("");
    }

    const type = {
      type: RESTAURANT_SUCCESS,
      id: action.id,

      permissoes_list: response.data.permissions,
      especialidades_list: response.data.specialties,
      food_types_list: response.data.food_types,
      cities_list: response.data.cities,
    };

    if (!response.data.dados_pessoais) {
      yield put(type);
    } else {
      yield put({
        ...type,
        nome: response.data.dados_pessoais.name,
        razao: response.data.dados_pessoais.corporate_name,
        url: response.data.dados_pessoais.site,
        cnpj: response.data.dados_pessoais.cnpj,
        endereco: response.data.endereco.street,
        cidade: response.data.endereco.city,
        bairro: response.data.endereco.neighborhood,
        cover: response.data.midia.cover ? `${response.data.midia.cover}` : "",
        cover2: response.data.midia.cover2
          ? `${response.data.midia.cover2}`
          : "",
        cover3: response.data.midia.cover3
          ? `${response.data.midia.cover3}`
          : "",
        cover4: response.data.midia.cover4
          ? `${response.data.midia.cover4}`
          : "",
        cover5: response.data.midia.cover5
          ? `${response.data.midia.cover5}`
          : "",
        descricao: response.data.complementos.about,
        menu: response.data.complementos.menu,
        ticket: response.data.complementos.ticket,
        instagram: response.data.complementos.instagram,
        // ticket: response.data.precos.ticket,
        taxa: response.data.precos.tax,
        validade_taxa: response.data.precos.validate_tax,
        renovacao_taxa: response.data.precos.tax_renew,
        faixa_preco: response.data.precos.faixa_preco,
        especialidade_principal: response.data.complementos.mainspecialty || "",
        especialidades: response.data.complementos.specialties,
        food_types: response.data.complementos.food_types,
        contatos: response.data.contatos,
        reservas: response.data.reservas.dias_reservas,
        gos: response.data.gos.dias_go,
        condicoes_extras: response.data.reservas.extra_conditions || "",
        go_conditions: response.data.gos.go_conditions || "",
        general_conditions:
          response?.data?.conditions?.general_conditions || "",

        benefit_condition:
          response?.data?.conditions?.reserve_conditions?.find(
            (r) => r.type === "benefit_condition"
          )?.text || "",
        date_condition:
          response?.data?.conditions?.reserve_conditions?.find(
            (r) => r.type === "date_condition"
          )?.text || "",
        hour_condition:
          response?.data?.conditions?.reserve_conditions?.find(
            (r) => r.type === "hour_condition"
          )?.text || "",
        seat_condition:
          response?.data?.conditions?.reserve_conditions?.find(
            (r) => r.type === "seat_condition"
          )?.text || "",
        benefit_go_condition:
          response?.data?.conditions?.go_conditions?.find(
            (r) => r.type === "benefit_go_condition"
          )?.text || "",
        date_go_condition:
          response?.data?.conditions?.go_conditions?.find(
            (r) => r.type === "date_go_condition"
          )?.text || "",
        hour_go_condition:
          response?.data?.conditions?.go_conditions?.find(
            (r) => r.type === "hour_go_condition"
          )?.text || "",
        seat_go_condition:
          response?.data?.conditions?.go_conditions?.find(
            (r) => r.type === "seat_go_condition"
          )?.text || "",

        is_free: response.data.reservas.is_free,
        is_premium: response.data.gos.is_premium,
      });
    }

    if (response.data.endereco && response.data.endereco.city) {
      yield put({
        type: RESTAURANT_CITY_CHANGED,
        id: response.data.endereco.city,
      });
    }
  } catch (e) {
    console.error(e);
    yield put({ type: RESTAURANT_FAIL });
  }
}

export function* dados_pessoais(api, action) {
  try {
    const response = yield call(
      api.post_dados_pessoais,
      action.id,
      action.nome,
      action.url,
      action.razao,
      action.cnpj
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: RESTAURANT_DADOS_PESSOAIS_SUCCESS,
      id: response.data.id,
    });
  } catch (e) {
    yield put({ type: RESTAURANT_DADOS_PESSOAIS_FAIL });
  }
}

export function* endereco(api, action) {
  try {
    const response = yield call(
      api.post_endereco,
      action.id,
      action.endereco,
      action.cidade,
      action.bairro
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_ENDERECO_SUCCESS });
  } catch (e) {
    yield put({ type: RESTAURANT_ENDERECO_FAIL });
  }
}

export function* midia(api, action) {
  try {
    const response = yield call(
      api.post_midia,
      action.id,
      action.cover,
      action.cover2,
      action.cover3,
      action.cover4,
      action.cover5
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_MIDIA_SUCCESS });
  } catch (e) {
    yield put({ type: RESTAURANT_MIDIA_FAIL });
  }
}

export function* complementos(api, action) {
  try {
    const response = yield call(
      api.post_complementos,
      action.id,
      action.descricao,
      action.especialidade_principal,
      action.especialidades,
      action.food_types,
      action.menu,
      action.ticket,
      action.instagram
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_COMPLEMENTOS_SUCCESS });
  } catch (e) {
    yield put({ type: RESTAURANT_COMPLEMENTOS_FAIL });
  }
}

export function* precos(api, action) {
  try {
    const { ticket, taxa, validade_taxa, renovacao_taxa, faixa_preco } = action;
    const response = yield call(
      api.post_precos,
      action.id,
      ticket,
      taxa,
      validade_taxa,
      renovacao_taxa,
      faixa_preco
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_PRECOS_SUCCESS });
  } catch (e) {
    console.error(e);
    yield put({ type: RESTAURANT_PRECOS_FAIL });
  }
}

export function* reservas(api, action) {
  try {
    const response = yield call(
      api.post_reservas,
      action.id,
      action.reservas,
      action.condicoes_extras,
      action.is_free
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_RESERVAS_SUCCESS });
  } catch (e) {
    yield put({ type: RESTAURANT_RESERVAS_FAIL });
  }
}

export function* gos(api, action) {
  try {
    const response = yield call(
      api.post_gos,
      action.id,
      action.gos,
      action.go_conditions,
      action.is_premium
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_GOS_SUCCESS });
  } catch (e) {
    yield put({ type: RESTAURANT_GOS_FAIL });
  }
}

export function* conditions(api, action) {
  try {
    const response = yield call(
      api.post_conditions,
      action.id,
      action.general_conditions,
      action.benefit_condition,
      action.date_condition,
      action.hour_condition,
      action.seat_condition,
      action.benefit_go_condition,
      action.date_go_condition,
      action.hour_go_condition,
      action.seat_go_condition
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_GOS_SUCCESS });
  } catch (e) {
    yield put({ type: RESTAURANT_GOS_FAIL });
  }
}

export function* contatos(api, action) {
  try {
    const response = yield call(api.post_contatos, action.id, action.contatos);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_CONTATOS_SUCCESS });
  } catch (e) {
    console.error(e);
    yield put({ type: RESTAURANT_CONTATOS_FAIL });
  }
}

export function* pausas(api, action) {
  try {
    const response = yield call(api.pausas, action.id, action.isGo);
    if (response.problem !== null) {
      throw new Error("");
    }
    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (e) {}
}

export function* change_pausa(api, action) {
  try {
    const response = yield call(
      api.change_pausa_dia,
      action.id,
      action.date,
      action.hour,
      action.isGo
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (e) {}
}

export function* change_active(api, action) {
  try {
    const response = yield call(api.change_active, action.id);
    if (response.problem !== null) {
      throw new Error("");
    }
    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (e) {
    if (action.onFail) {
      action.onFail();
    }
  }
}

export function* reserves(api, action) {
  try {
    const response = yield call(
      api.reserves_day,
      action.id,
      action.date,
      action.isGo
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    if (action.onSuccess) {
      action.onSuccess(response.data);
    }
  } catch (e) {}
}

export function* publicar(api, action) {
  try {
    const response = yield call(api.publicar, action.id);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({ type: RESTAURANT_PUBLISH_SUCCESS });
  } catch (e) {
    console.error(e);
    yield put({ type: RESTAURANT_PUBLISH_FAIL });
  }
}
