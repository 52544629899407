import AsyncStorage from "@react-native-community/async-storage";
import apisauce from "apisauce";

const base = process.env.REACT_APP_API_BASE_URL;

const create = (baseURL = base) => {
  const api = apisauce.create({
    baseURL,
  });

  const setHeader = (header, value) => {
    api.setHeader(header, value);
  };

  const auth = (username, password) => {
    const params = {
      username: username,
      password: password,
    };

    return api.post("auth/", params);
  };

  const groubieDetails = () => {
    return api.get("application/");
  };

  const account = () => {
    return api.get("accounts/");
  };

  const restaurants = () => {
    return api.get(`insider/restaurants/`);
  };

  const bairros = (city_id) => {
    const params = {
      city_id: city_id,
    };
    return api.get(`insider/restaurants/neighborhoods/`, params);
  };

  const dados = (id) => {
    const params = {
      restaurant_id: id,
    };
    return api.get(`insider/restaurants/details/`, params);
  };

  const post_dados_pessoais = (id, nome, url, razao, cnpj) => {
    const params = {
      id: id ? id : null,
      name: nome,
      site: url,
      corporate_name: razao,
      cnpj: cnpj,
    };

    return api.post("insider/restaurants/personal_data/", params);
  };

  const post_endereco = (id, endereco, cidade, bairro) => {
    const params = {
      id: id,
      street: endereco,
      city: cidade,
      neighborhood: bairro,
    };

    return api.post("insider/restaurants/address/", params);
  };

  const post_midia = (id, cover, cover2, cover3, cover4, cover5) => {
    const formData = new FormData();

    formData.append("id", id);

    if (cover && cover instanceof FormData && cover.get("media")) {
      formData.append("cover", cover.get("media"));
    }
    if (cover2 && cover2 instanceof FormData && cover2.get("media")) {
      formData.append("cover2", cover2.get("media"));
    }
    if (cover3 && cover3 instanceof FormData && cover3.get("media")) {
      formData.append("cover3", cover3.get("media"));
    }
    if (cover4 && cover4 instanceof FormData && cover4.get("media")) {
      formData.append("cover4", cover4.get("media"));
    }
    if (cover5 && cover5 instanceof FormData && cover5.get("media")) {
      formData.append("cover5", cover5.get("media"));
    }

    return api.post("insider/restaurants/media/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const post_complementos = (
    id,
    descricao,
    especialidade_principal,
    especialidades,
    food_types,
    menu,
    ticket,
    instagram
  ) => {
    const params = {
      id: id,
      about: descricao,
      menu: menu,
      ticket: ticket,
      instagram: instagram,
      mainspecialty: especialidade_principal,
      specialties: especialidades,
      food_types: food_types,
    };

    return api.post("insider/restaurants/complements/", params);
  };

  const post_precos = (
    id,
    ticket,
    taxa,
    validade_taxa,
    renovacao_taxa,
    faixa_preco
  ) => {
    const params = {
      id: id,
      ticket: ticket,
      taxa: taxa,
      validade_taxa: validade_taxa,
      renovacao_taxa: renovacao_taxa,
      faixa_preco: faixa_preco,
    };

    return api.post("insider/restaurants/precos", params);
  };

  const post_reservas = (id, reservas, condicoes_extras, is_free) => {
    const params = {
      id: id,
      dias_reservas: reservas,
      extra_conditions: condicoes_extras,
      is_free: is_free,
    };
    return api.post("insider/restaurants/reserves/", params);
  };

  const post_gos = (id, gos, go_conditions, is_premium) => {
    const params = {
      id: id,
      dias_gos: gos,
      go_conditions: go_conditions,
      is_premium: is_premium,
    };
    return api.post("insider/restaurants/gos/", params);
  };

  const post_conditions = (
    id,
    general_conditions,
    benefit_condition,
    date_condition,
    hour_condition,
    seat_condition,
    benefit_go_condition,
    date_go_condition,
    hour_go_condition,
    seat_go_condition
  ) => {
    const params = {
      id: id,
      general_conditions,
      benefit_condition,
      date_condition,
      hour_condition,
      seat_condition,
      benefit_go_condition,
      date_go_condition,
      hour_go_condition,
      seat_go_condition,
    };
    return api.post("insider/restaurants/conditions/", params);
  };

  const post_contatos = (id, contatos) => {
    const params = {
      id: id,
      contatos: contatos,
    };
    return api.post("insider/restaurants/contacts/", params);
  };

  const pausas = (id, isGo) => {
    const params = {
      restaurant_id: id,
      isGo: isGo,
    };
    return api.get("insider/restaurants/pause/", params);
  };

  const change_pausa_dia = (id, date, hour, isGo) => {
    const params = {
      id: id,
      date: date,
      hour: hour,
      isGo: isGo,
    };

    return api.post("insider/restaurants/change_pause/", params);
  };

  const reserves_day = (id, date, isGo) => {
    const params = {
      restaurant_id: id,
      date: date,
      isGo: isGo,
    };

    return api.get("insider/restaurants/reserves/calendar/", params);
  };

  const change_active = (id) => {
    const params = {
      id: id,
    };

    return api.post("insider/restaurants/change_active/", params);
  };

  const publicar = (id) => {
    const params = {
      id: id,
    };

    return api.post("insider/restaurants/publish/", params);
  };

  const reservations = (dates) => {
    const params = {
      dates: dates,
    };

    return api.get("insider/reservations", params);
  };

  const post_contestacao = (id, problem, seats, contacted, image) => {
    const params = {
      id: id,
      problem: problem,
      seats: seats,
      contacted: contacted,
      image: image,
    };

    return api.post("insider/reservations/contestacao", params);
  };

  const acompanhamento = (mes, ano, initial, final, city_id) => {
    const params = {
      mes: mes,
      ano: ano,
      initial: initial,
      final: final,
      city_id: city_id,
    };

    return api.get("insider/acompanhamento", params);
  };

  const acompanhamento_range = (initial, final, city_id) => {
    const params = {
      initial: initial,
      final: final,
      city_id: city_id,
    };

    return api.get("insider/acompanhamento_range", params);
  };

  const options = (restaurant_id, screen) => {
    const params = {
      restaurant_id: restaurant_id,
      screen: screen,
    };

    return api.get("insider/options", params);
  };

  const feed = (restaurant_id, start, end, includeGo) => {
    const params = {
      restaurant_id: restaurant_id,
      start: start,
      end: end,
      includeGo: includeGo,
    };

    return api.get("insider/feed", params);
  };

  const avaliacao = (restaurant_id, start, end, nao_respondidas) => {
    const params = {
      restaurant_id: restaurant_id,
      start: start,
      end: end,
      nao_respondidas: nao_respondidas,
    };

    return api.get("insider/avaliacao", params);
  };

  const post_comment = (score_id, from_name, comment) => {
    const params = {
      id: score_id,
      from_name: from_name,
      comment: comment,
    };

    return api.post("insider/avaliacao/comment", params);
  };

  const conditions = () => {
    return api.get("insider/conditions/");
  };

  const save_condition = (conditionId, data) => {
    return api.patch(`insider/conditions/${conditionId}/`, data);
  };

  return {
    auth,
    groubieDetails,
    account,
    setHeader,
    restaurants,
    bairros,
    dados,
    post_dados_pessoais,
    post_endereco,
    post_midia,
    post_complementos,
    post_precos,
    post_reservas,
    post_gos,
    post_conditions,
    post_contatos,
    pausas,
    change_pausa_dia,
    reserves_day,
    publicar,
    reservations,
    post_contestacao,
    acompanhamento,
    acompanhamento_range,
    change_active,
    options,
    feed,
    avaliacao,
    post_comment,
    conditions,
    save_condition,
  };
};

const initialize = async () => {
  const api = create();
  const token = await AsyncStorage.getItem("token");

  if (token) {
    api.setHeader("Authorization", `Bearer ${token}`);
  }

  return api;
};

const exportCreate = {
  create,
  initialize,
};

export default exportCreate;
