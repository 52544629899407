import {
  RESTAURANT_SET_VALUE,
  RESTAURANT_DADOS_PESSOAIS_REQUEST,
  RESTAURANT_DADOS_PESSOAIS_SUCCESS,
  RESTAURANT_DADOS_PESSOAIS_FAIL,
  RESTAURANT_ENDERECO_REQUEST,
  RESTAURANT_ENDERECO_SUCCESS,
  RESTAURANT_ENDERECO_FAIL,
  RESTAURANT_REQUEST,
  RESTAURANT_SUCCESS,
  RESTAURANT_FAIL,
  RESTAURANT_COMPLEMENTOS_REQUEST,
  RESTAURANT_COMPLEMENTOS_SUCCESS,
  RESTAURANT_COMPLEMENTOS_FAIL,
  RESTAURANT_RESERVAS_REQUEST,
  RESTAURANT_RESERVAS_SUCCESS,
  RESTAURANT_RESERVAS_FAIL,
  RESTAURANT_MIDIA_REQUEST,
  RESTAURANT_MIDIA_SUCCESS,
  RESTAURANT_MIDIA_FAIL,
  RESTAURANT_GOS_SUCCESS,
  RESTAURANT_GOS_FAIL,
  RESTAURANT_GOS_REQUEST,
  RESTAURANT_CONDITIONS_REQUEST,
  RESTAURANT_CITY_CHANGED,
  RESTAURANT_CONTATOS_REQUEST,
  RESTAURANT_CONTATOS_SUCCESS,
  RESTAURANT_CONTATOS_FAIL,
  RESTAURANT_PRECOS_REQUEST,
  RESTAURANT_PRECOS_SUCCESS,
  RESTAURANT_PRECOS_FAIL,
  RESTAURANT_PUBLISH_REQUEST,
  RESTAURANT_PUBLISH_SUCCESS,
  RESTAURANT_PUBLISH_FAIL,
} from "../constants";

const initialState = {
  loading: false,

  especialidades_list: [],
  food_types_list: [],
  permissoes_list: [],
  cities_list: [],
  bairros_list: [],

  dados_pessoais_saving: false,
  dados_pessoais_touched: false,
  dados_pessoais_error: false,
  endereco_saving: false,
  endereco_touched: false,
  endereco_error: false,
  midia_saving: false,
  midia_touched: false,
  midia_error: false,
  complemento_saving: false,
  complemento_touched: false,
  complemento_error: false,
  precos_saving: false,
  precos_touched: false,
  precos_error: false,
  reservas_saving: false,
  reservas_touched: false,
  reservas_error: false,
  gos_saving: false,
  gos_touched: false,
  gos_error: false,
  contatos_saving: false,
  contatos_touched: false,
  contatos_error: false,
  publish_saving: false,
  publish_error: false,

  //Form
  id: null,
  // id: 258,
  nome: "",
  url: "",
  razao: "",
  cnpj: "",
  endereco: "",
  cidade: 0,
  bairro: 0,
  cover: "",
  cover2: "",
  cover3: "",
  cover4: "",
  cover5: "",
  descricao: "",
  ticket: 0,
  taxa: 0,
  validade_taxa: "",
  renovacao_taxa: 0,
  faixa_preco: 0,
  especialidade_principal: "",
  especialidades: [],
  food_types: [],
  is_free: false,
  is_premium: false,
  condicoes_extras: "",
  go_conditions: "",
  reservas: [],
  gos: [],
  contatos: [],
};
export const restaurant = (state = initialState, action) => {
  switch (action.type) {
    case RESTAURANT_SET_VALUE: {
      var value = action.value;
      if (action.key === "especialidades" || action.key === "food_types") {
        const list = state[action.key];
        if (list.includes(value)) {
          list.splice(list.indexOf(value), 1);
        } else {
          list.push(value);
        }

        value = [...list];
      }
      const r = { ...state };
      r[action.key] = value;
      return r;
    }
    case RESTAURANT_CITY_CHANGED:
      return { ...state, cidade: action.id };
    case RESTAURANT_REQUEST:
      return { ...state, loading: true };
    case RESTAURANT_SUCCESS:
      return {
        ...state,

        especialidades_list: action.especialidades_list,
        food_types_list: action.food_types_list,
        permissoes_list: action.permissoes_list,
        cities_list: action.cities_list,

        loading: false,
        id: action.id,
        nome: action.nome,
        url: action.url,
        razao: action.razao,
        cnpj: action.cnpj,
        endereco: action.endereco,
        cidade: action.cidade,
        bairro: action.bairro,
        cover: action.cover,
        cover2: action.cover2,
        cover3: action.cover3,
        cover4: action.cover4,
        cover5: action.cover5,
        descricao: action.descricao,
        menu: action.menu,
        ticket: action.ticket,
        instagram: action.instagram,
        taxa: action.taxa,
        validade_taxa: action.validade_taxa,
        renovacao_taxa: action.renovacao_taxa,
        faixa_preco: action.faixa_preco,
        especialidade_principal: action.especialidade_principal,
        especialidades: action.especialidades,
        food_types: action.food_types,
        contatos: action.contatos,
        reservas: action.reservas,
        gos: action.gos,
        condicoes_extras: action.condicoes_extras,
        go_conditions: action.go_conditions,
        general_conditions: action.general_conditions,
        benefit_condition: action.benefit_condition,
        date_condition: action.date_condition,
        hour_condition: action.hour_condition,
        seat_condition: action.seat_condition,
        benefit_go_condition: action.benefit_go_condition,
        date_go_condition: action.date_go_condition,
        hour_go_condition: action.hour_go_condition,
        seat_go_condition: action.seat_go_condition,
        is_free: action.is_free,
        is_premium: action.is_premium,
      };
    case RESTAURANT_FAIL:
      return { ...state, loading: false };
    case RESTAURANT_DADOS_PESSOAIS_REQUEST:
      return { ...state, dados_pessoais_saving: true };
    case RESTAURANT_DADOS_PESSOAIS_SUCCESS:
      return {
        ...state,
        dados_pessoais_saving: false,
        id: action.id,
        dados_pessoais_touched: false,
        dados_pessoais_error: false,
      };
    case RESTAURANT_DADOS_PESSOAIS_FAIL:
      return {
        ...state,
        dados_pessoais_saving: false,
        dados_pessoais_error: true,
      };
    case RESTAURANT_ENDERECO_REQUEST:
      return { ...state, endereco_saving: true };
    case RESTAURANT_ENDERECO_SUCCESS:
      return {
        ...state,
        endereco_saving: false,
        endereco_touched: false,
        endereco_error: false,
      };
    case RESTAURANT_ENDERECO_FAIL:
      return { ...state, endereco_saving: false, endereco_error: true };
    case RESTAURANT_MIDIA_REQUEST:
      return { ...state, midia_saving: true };
    case RESTAURANT_MIDIA_SUCCESS:
      return {
        ...state,
        midia_saving: false,
        midia_touched: false,
        midia_error: false,
      };
    case RESTAURANT_MIDIA_FAIL:
      return { ...state, midia_saving: false, midia_error: true };
    case RESTAURANT_COMPLEMENTOS_REQUEST:
      return { ...state, complemento_saving: true };
    case RESTAURANT_COMPLEMENTOS_SUCCESS:
      return {
        ...state,
        complemento_saving: false,
        complemento_touched: false,
        complemento_error: false,
      };
    case RESTAURANT_COMPLEMENTOS_FAIL:
      return { ...state, complemento_saving: false, complemento_error: true };
    case RESTAURANT_PRECOS_REQUEST:
      return { ...state, precos_saving: true };
    case RESTAURANT_PRECOS_SUCCESS:
      return {
        ...state,
        precos_saving: false,
        precos_touched: false,
        precos_error: false,
      };
    case RESTAURANT_PRECOS_FAIL:
      return { ...state, precos_saving: false, precos_error: true };
    case RESTAURANT_RESERVAS_REQUEST:
      return { ...state, reservas_saving: true };
    case RESTAURANT_RESERVAS_SUCCESS:
      return {
        ...state,
        reservas_saving: false,
        reservas_touched: false,
        reservas_error: false,
      };
    case RESTAURANT_RESERVAS_FAIL:
      return { ...state, reservas_saving: false, reservas_error: true };
    case RESTAURANT_GOS_REQUEST:
      return { ...state, gos_saving: true };
    case RESTAURANT_CONDITIONS_REQUEST:
      return { ...state, conditions_saving: true };
    case RESTAURANT_GOS_SUCCESS:
      return {
        ...state,
        gos_saving: false,
        gos_touched: false,
        gos_error: false,
      };
    case RESTAURANT_GOS_FAIL:
      return { ...state, gos_saving: false, gos_error: true };
    case RESTAURANT_CONTATOS_REQUEST:
      return { ...state, contatos_saving: true };
    case RESTAURANT_CONTATOS_SUCCESS:
      return {
        ...state,
        contatos_saving: false,
        contatos_touched: false,
        contatos_error: false,
      };
    case RESTAURANT_CONTATOS_FAIL:
      return { ...state, contatos_saving: false, contatos_error: true };
    case RESTAURANT_PUBLISH_REQUEST:
      return { ...state, publish_saving: true };
    case RESTAURANT_PUBLISH_SUCCESS:
      return { ...state, publish_saving: false, publish_error: false };
    case RESTAURANT_PUBLISH_FAIL:
      return { ...state, publish_saving: false, publish_error: true };
    default:
      return state;
  }
};
