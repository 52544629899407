import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { Fonts, Colors, Images } from "../themes";
import Calendar from "react-calendar";
import moment from "moment";

// const ref = React.createRef();

class Restaurant extends Component {
  static defaultProps = {
    restaurant: {},
    onSelectDate: () => {},
    onPauseListRequest: () => {},
    onPauseDayClick: () => {},
    onEditClick: () => {},
    onActiveClick: () => {},
  };

  state = {
    open: false,
    isGo: false,
    calendar_position: { x: 0, y: 0 },
    daySelected: null,
    hourSelected: null,
    pausedDates: [],
    hours: [],
    loading: false,
    loading_hours: false,
    active: true,
    title_description: "",
  };

  componentDidUpdate() {
    if (this.restaurant !== this.props.restaurant.id) {
      this.restaurant = this.props.restaurant.id;
      this.setState({ active: this.props.restaurant.active });
    }
  }

  loadPausedDates() {
    this.setState({ loading: true });
    this.props.onPauseListRequest(this.state.isGo, (data) => {
      this.setState({
        loading: false,
        pausedDates: data.dates,
        daySelected: null,
        hours: [],
        title_description: "",
      });
    });
  }

  onActiveClick() {
    this.setState({ loading_active: true });
    this.props.onAcitveClick(
      this.props.restaurant.id,
      () => {
        this.setState({ active: !this.state.active });
      },
      () => {}
    );
  }

  onPauseClick() {
    const { open } = this.state;

    this.element.measure((fx, fy, width, height, px, py) => {
      this.setState({ calendar_position: { x: fx, y: fy } });
    });

    this.setState({ open: !open });
    if (!open) {
      this.loadPausedDates();
    }
  }

  changeIsGo(isGo) {
    this.setState({ isGo: isGo }, () => {
      this.loadPausedDates();
    });
  }

  changePausaDia() {
    this.setState({ loading: true });
    this.props.onPauseDayClick(
      this.state.isGo,
      this.state.daySelected,
      null,
      () => {
        this.loadPausedDates();
      }
    );
  }

  changePausaHora() {
    this.setState({ loading_hours: true });
    this.props.onPauseDayClick(
      this.state.isGo,
      this.state.daySelected,
      this.state.isGo
        ? `${this.state.hourSelected.initial_hour}-${this.state.hourSelected.final_hour}`
        : this.state.hourSelected.hour,
      () => {
        this.onSelectDate(this.state.daySelected);
      }
    );
  }

  onSelectDate(date) {
    this.setState({ daySelected: date, loading_hours: true });
    this.props.onSelectDate(date, this.state.isGo, (data) => {
      this.setState({
        hours: data.reserves,
        title_description: data.description,
        loading_hours: false,
        hourSelected: null,
      });
    });
  }

  renderMain(showBorders) {
    const { restaurant } = this.props;
    const { open } = this.state;

    return (
      <View
        key={restaurant.id}
        ref={(view) => {
          this.element = view;
        }}
        style={[
          styles.item,
          open ? styles.openItem : {},
          !showBorders ? styles.withoutBorders : {},
        ]}
        onLayout={(event) => {
          if (showBorders)
            this.setState({
              calendar_position: {
                x: event.nativeEvent.layout.x,
                y: event.nativeEvent.layout.y,
              },
            });
        }}
      >
        <Text
          style={styles.joinedText}
        >{`Entrou em ${restaurant.joined}`}</Text>
        <Text style={styles.nameText}>{restaurant.name}</Text>
        <Text
          style={styles.joinedText}
        >{`Já soma mais de ${restaurant.count_people} pessoas e ${restaurant.count_go} 2 por 1 gerados.`}</Text>
        <View style={[styles.bottomContainer]}>
          <TouchableOpacity
            disabled={!this.state.active}
            style={[
              styles.button,
              {
                backgroundColor: Colors.warning,
                opacity: this.state.active ? 1 : 0.3,
              },
            ]}
            onPress={this.onPauseClick.bind(this)}
          >
            <Text style={styles.buttonText}>PAUSAS</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={this.onActiveClick.bind(this)}
            style={[styles.button, { backgroundColor: Colors.text }]}
          >
            <Text style={styles.buttonText}>
              {this.state.active ? "DESATIVAR" : "ATIVAR"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.props.onEditClick(restaurant)}
            style={[styles.button, { backgroundColor: Colors.accent }]}
          >
            <Text style={styles.buttonText}>EDITAR</Text>
          </TouchableOpacity>
        </View>
        {showBorders && (
          <View
            className={
              restaurant.is_free && restaurant.is_premium ? "reservaEGo" : ""
            }
            style={[
              styles.bar,
              restaurant.is_premium && !restaurant.is_free
                ? { backgroundColor: Colors.secondary }
                : {},
            ]}
          />
        )}
      </View>
    );
  }

  renderHours() {
    if (this.state.loading_hours) {
      return (
        <ActivityIndicator
          style={{ margin: 30 }}
          size="large"
          color={Colors.warning}
        />
      );
    }

    const { daySelected, hourSelected, title_description, hours, isGo } =
      this.state;

    return (
      <React.Fragment>
        <Text
          style={[
            styles.joinedText,
            { color: Colors.text, marginTop: 45, alignSelf: "center" },
          ]}
        >
          {title_description}
        </Text>
        <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 10 }}>
          {hours.map((hour, index) => {
            return (
              <TouchableOpacity
                key={index}
                style={[
                  styles.reserveButton,
                  isGo ? { backgroundColor: Colors.secondary } : {},
                  hour.paused ? { backgroundColor: Colors.warning } : {},
                ]}
                onPress={() => this.setState({ hourSelected: hour })}
              >
                <Text style={[styles.buttonText, { fontSize: 10 }]}>
                  {!isGo ? hour.hour : hour.initial_hour}
                </Text>
                {isGo && (
                  <Text style={[styles.buttonText, { fontSize: 10 }]}>
                    {!isGo ? hour.hour : hour.final_hour}
                  </Text>
                )}
                {hourSelected === hour && (
                  <View
                    style={[
                      styles.reserveSelected,
                      isGo ? { backgroundColor: Colors.secondary_dark } : {},
                      hour.paused
                        ? { backgroundColor: Colors.warning_dark }
                        : {},
                    ]}
                  />
                )}
              </TouchableOpacity>
            );
          })}
        </View>
        {daySelected !== null && hours.length === 0 && (
          <Text
            style={[
              styles.joinedText,
              {
                fontFamily: Fonts.type.light,
                color: Colors.text,
                marginTop: 5,
                alignSelf: "center",
              },
            ]}
          >
            Nenhum horário encontrado
          </Text>
        )}
      </React.Fragment>
    );
  }

  renderPauseView() {
    if (this.state.loading) {
      return (
        <ActivityIndicator
          style={{ margin: 30 }}
          size="large"
          color={Colors.warning}
        />
      );
    }

    const { daySelected, pausedDates, hourSelected } = this.state;

    return (
      <View style={styles.calendarContainer}>
        <Calendar
          className={["calendar"]}
          locale="pt-BR"
          tileClassName={({ date, view }) => {
            const dateString = moment(date).format("YYYY-MM-DD");
            if (this.state.pausedDates.includes(dateString)) {
              return ["date_selected"];
            }
          }}
          tileDisabled={({ date }) => {
            const yesterday = moment().add(-1, "days").toDate();
            return yesterday > date;
          }}
          onClickDay={(date) => {
            const dateString = moment(date).format("YYYY-MM-DD");
            this.onSelectDate(dateString);
          }}
        />
        <View style={{ flex: 1, marginTop: 10, height: 30 }}>
          <TouchableOpacity
            onPress={this.changePausaDia.bind(this)}
            style={[
              styles.pauseButton,
              this.state.daySelected === null ? { opacity: 0.3 } : {},
            ]}
            disabled={this.state.daySelected === null}
          >
            <Text style={styles.pauseButtonText}>
              {daySelected !== null && pausedDates.includes(daySelected)
                ? "INICIAR O DIA"
                : "PAUSAR O DIA"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={this.changePausaHora.bind(this)}
            disabled={hourSelected === null || hourSelected.paused}
            style={[
              styles.pauseButton,
              {
                borderRadius: 15,
                width: 30,
                paddingLeft: 0,
                paddingRight: 0,
                right: 40,
              },
              hourSelected === null || hourSelected.paused
                ? { opacity: 0.3 }
                : {},
            ]}
          >
            <Image
              source={{ uri: Images.pause }}
              style={{ width: 10, height: 12 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            disabled={hourSelected === null || !hourSelected.paused}
            onPress={this.changePausaHora.bind(this)}
            style={[
              styles.pauseButton,
              {
                borderRadius: 15,
                width: 30,
                paddingLeft: 0,
                paddingRight: 0,
                right: 0,
              },
              hourSelected === null || !hourSelected.paused
                ? { opacity: 0.3 }
                : {},
            ]}
          >
            <Image
              source={{ uri: Images.resume }}
              style={{ width: 9, height: 10 }}
            />
          </TouchableOpacity>
        </View>

        {this.renderHours()}
      </View>
    );
  }

  render() {
    const { restaurant } = this.props;
    const { open } = this.state;

    return (
      <React.Fragment>
        {this.renderMain(!open)}
        {Platform.OS === "web" && open && (
          <View
            key={restaurant.id}
            style={[
              styles.item,
              styles.pausaContainer,
              {
                left: this.state.calendar_position.x,
                top: this.state.calendar_position.y,
              },
            ]}
          >
            {this.renderMain(false)}
            <View
              style={{
                flexDirection: "row",
                borderTopWidth: 1,
                borderColor: Colors.divider,
                padding: 15,
              }}
            >
              {restaurant.is_free && (
                <TouchableOpacity
                  style={styles.reserveToggle}
                  onPress={() => this.changeIsGo(false)}
                >
                  <Text style={styles.reserveToggleText}>Reservas</Text>
                  {!this.state.isGo && <View style={styles.line} />}
                </TouchableOpacity>
              )}
              {restaurant.is_premium && (
                <TouchableOpacity
                  style={styles.reserveToggle}
                  onPress={() => this.changeIsGo(true)}
                >
                  <Text style={styles.reserveToggleText}>2 por 1</Text>
                  {this.state.isGo && (
                    <View
                      style={[
                        styles.line,
                        { backgroundColor: Colors.secondary },
                      ]}
                    />
                  )}
                </TouchableOpacity>
              )}
            </View>
            {this.renderPauseView()}
            <View
              className={
                restaurant.is_free && restaurant.is_premium ? "reservaEGo" : ""
              }
              style={[
                styles.bar,
                restaurant.is_premium && !restaurant.is_free
                  ? { backgroundColor: Colors.secondary }
                  : {},
              ]}
            />
          </View>
        )}
      </React.Fragment>
    );
  }
}

const styles = StyleSheet.create({
  item: {
    backgroundColor: "#fff",
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 16,
    paddingRight: 10,
    width: 265,
    height: 120,
    borderRadius: 5,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 24,
    shadowColor: Colors.text,
    shadowOpacity: 0.2,
    // overflow: "hidden",
    marginBottom: 30,
    marginRight: 20,
    overflow: "visible",
    zIndex: 0,
  },
  bar: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    width: 6,
    backgroundColor: Colors.primary,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
  },
  withoutBorders: {
    shadowRadius: 0,
    shadowOffset: { width: 0, height: 0 },
    margin: 0,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    // marginRight: 0,
    borderRadius: 0,
  },
  openItem: {
    // height: 516
  },
  bottomContainer: {
    paddingTop: 10,
    flexDirection: "row",
    justifyContent: "flex-end",
    // alignItems: 'flex-end',
    width: "100%",
    height: 35,
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
    height: 21,
    paddingLeft: 10,
    paddingRight: 10,
    marginRight: 5,
  },
  buttonText: {
    fontFamily: Fonts.type.medium,
    fontSize: 8,
    color: Colors.white,
  },
  joinedText: {
    fontFamily: Fonts.type.medium,
    fontSize: 12,
    color: Colors.text_light,
  },
  nameText: {
    fontFamily: Fonts.type.bold,
    fontSize: 14,
    color: Colors.text,
    marginTop: 4,
    marginBottom: 4,
  },
  pausaContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    width: 265,
    // height: 396,
    height: "auto",
    zIndex: 1000,
    borderWidth: 0,
    borderColor: Colors.divider,
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    // margin: 0,
    // marginTop: 0,
    // marginBottom: 0,
    // marginLeft: 0,
    // marginRight: 20,
    overflow: "hidden",
  },
  pauseButton: {
    backgroundColor: Colors.warning,
    position: "absolute",
    paddingLeft: 10,
    paddingRight: 10,
    height: 30,
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
  },
  pauseButtonText: {
    fontFamily: Fonts.type.medium,
    fontSize: 9,
    color: Colors.warning_dark,
  },
  calendarContainer: {
    padding: 10,
  },
  reserveButton: {
    width: 45,
    height: 34,
    marginRight: 4,
    marginTop: 3,
    borderRadius: 3,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.primary,
    overflow: "hidden",
  },
  reserveSelected: {
    position: "absolute",
    height: 6,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.primary_dark,
  },
  reserveToggle: {
    marginRight: 20,
  },
  reserveToggleText: {
    fontFamily: Fonts.type.bold,
    fontSize: 14,
    color: Colors.text,
  },
  line: {
    height: 3,
    borderRadius: 2,
    marginTop: 5,
    backgroundColor: Colors.primary,
  },
});

export default Restaurant;
