import { takeLatest, all } from "redux-saga/effects";
import {
  dados,
  dados_pessoais,
  endereco,
  complementos,
  reservas,
  pausas,
  change_pausa,
  reserves,
  midia,
  gos,
  conditions,
  bairros,
  contatos,
  precos,
  publicar,
  change_active,
} from "../sagas/restaurant";
import {
  restaurants,
  search,
  filter,
  list_restaurants,
} from "../sagas/dashboard";
import InsiderApi from "../api/InsiderApi";
import {
  RESTAURANT_DADOS_PESSOAIS_REQUEST,
  RESTAURANT_ENDERECO_REQUEST,
  RESTAURANT_REQUEST,
  RESTAURANT_COMPLEMENTOS_REQUEST,
  RESTAURANT_LIST_REQUEST,
  RESTAURANT_SEARCH,
  RESTAURANT_RESERVAS_REQUEST,
  RESTAURANT_PAUSE_LIST_REQUEST,
  RESTAURANT_PAUSE_CHANGE_REQUEST,
  RESTAURANT_RESERVES_LIST_REQUEST,
  RESTAURANT_MIDIA_REQUEST,
  RESTAURANT_GOS_REQUEST,
  RESTAURANT_CONDITIONS_REQUEST,
  RESTAURANT_CITY_CHANGED,
  RESTAURANT_CONTATOS_REQUEST,
  RESTAURANT_PRECOS_REQUEST,
  RESTAURANT_PUBLISH_REQUEST,
  DASHBOARD_CHANGE_FILTER,
  RESERVATION_LIST_REQUEST,
  RESERVATION_SAVE_REQUEST,
  ACOMPANHAMENTO_REQUEST,
  RESTAURANT_CHANGE_ACTIVE,
  FEED_REQUEST,
  OPTIONS_REQUEST,
  HISTORICO_REQUEST,
  AVALIACAO_REQUEST,
  AVALIACAO_COMMENT_REQUEST,
  LOGIN_REQUEST,
  VERIFY_LOGIN,
  LOGOUT,
  GROUBIE_DETAILS_REQUEST,
  ACOMPANHAMENTO_RANGE_REQUEST,
  LIST_RESTAURANTS,
} from "../constants";
import {
  reservation,
  contestacao,
  acompanhamento,
  acompanhamento_range,
} from "./reservation";
import { feed, options, historico, avaliacao, avaliacao_comment } from "./feed";
import { auth, verificar_login, logout, verifyGroubieDetails } from "./auth";

export default function* root() {
  const api = InsiderApi.create();

  yield all([
    takeLatest(GROUBIE_DETAILS_REQUEST, verifyGroubieDetails, api),
    takeLatest(LOGOUT, logout, api),
    takeLatest(LOGIN_REQUEST, auth, api),
    takeLatest(VERIFY_LOGIN, verificar_login, api),
    takeLatest(DASHBOARD_CHANGE_FILTER, filter, api),
    takeLatest(RESTAURANT_LIST_REQUEST, restaurants, api),
    takeLatest(RESTAURANT_SEARCH, search, api),
    takeLatest(RESTAURANT_CITY_CHANGED, bairros, api),
    takeLatest(RESTAURANT_REQUEST, dados, api),
    takeLatest(RESTAURANT_DADOS_PESSOAIS_REQUEST, dados_pessoais, api),
    takeLatest(RESTAURANT_ENDERECO_REQUEST, endereco, api),
    takeLatest(RESTAURANT_MIDIA_REQUEST, midia, api),
    takeLatest(RESTAURANT_COMPLEMENTOS_REQUEST, complementos, api),
    takeLatest(RESTAURANT_PRECOS_REQUEST, precos, api),
    takeLatest(RESTAURANT_RESERVAS_REQUEST, reservas, api),
    takeLatest(RESTAURANT_GOS_REQUEST, gos, api),
    takeLatest(RESTAURANT_CONDITIONS_REQUEST, conditions, api),
    takeLatest(RESTAURANT_CONTATOS_REQUEST, contatos, api),
    takeLatest(RESTAURANT_PAUSE_LIST_REQUEST, pausas, api),
    takeLatest(RESTAURANT_PAUSE_CHANGE_REQUEST, change_pausa, api),
    takeLatest(RESTAURANT_RESERVES_LIST_REQUEST, reserves, api),
    takeLatest(RESTAURANT_PUBLISH_REQUEST, publicar, api),
    takeLatest(RESTAURANT_CHANGE_ACTIVE, change_active, api),
    takeLatest(RESERVATION_LIST_REQUEST, reservation, api),
    takeLatest(RESERVATION_SAVE_REQUEST, contestacao, api),
    takeLatest(ACOMPANHAMENTO_REQUEST, acompanhamento, api),
    takeLatest(ACOMPANHAMENTO_RANGE_REQUEST, acompanhamento_range, api),
    takeLatest(LIST_RESTAURANTS, list_restaurants, api),

    takeLatest(OPTIONS_REQUEST, options, api),
    takeLatest(FEED_REQUEST, feed, api),
    takeLatest(HISTORICO_REQUEST, historico, api),
    takeLatest(AVALIACAO_REQUEST, avaliacao, api),
    takeLatest(AVALIACAO_COMMENT_REQUEST, avaliacao_comment, api),
  ]);
}
