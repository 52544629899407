import React, { Component } from "react";
import { View, Image, Animated, TouchableOpacity, Easing } from "react-native";
import { Colors, Images } from "../themes";

export default class Modal extends Component {
  static get defaultProps() {
    return {
      onClosePress: () => {},
      onClose: () => {},
      showCloseButton: true,
      color: Colors.primary,
      showIcon: true,
      huge: false,
      showOverlay: true,
      icon: Images.logo,
      iconStyle: styles.icon,
    };
  }

  constructor(props) {
    super(props);
    this.scale = new Animated.Value(0);
    this.backgroundScale = new Animated.Value(0);
    this.backgroundVisible = new Animated.Value(0);
    this.state = { visible: false, pointerEvents: "none" };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.visible && !nextProps.visible) {
      this.hide();
    } else if (!this.state.visible && nextProps.visible) {
      this.show();
    }
  }

  show() {
    const backgroundValue = this.props.showOverlay ? 1 : 0;
    this.setState({ visible: true, pointerEvents: "auto" });
    this.backgroundScale.setValue(1);
    Animated.spring(this.backgroundVisible, {
      toValue: backgroundValue,
    }).start();
    Animated.timing(this.scale, {
      toValue: 1,
      duration: 300,
      easing: Easing.elastic(2),
    }).start();
  }

  hide() {
    this.backgroundScale.setValue(0);
    Animated.timing(this.backgroundVisible, {
      toValue: 0,
      duration: 50,
    }).start(() => {
      Animated.timing(this.scale, {
        toValue: 0,
        duration: 300,
        easing: Easing.back(2),
      }).start(() => {
        this.setState({ visible: false, pointerEvents: "none" });
        this.props.onClose();
      });
    });
  }

  renderIcon() {
    if (this.props.showIcon) {
      return (
        <View
          style={[styles.iconContainer, { backgroundColor: this.props.color }]}
        >
          <Image style={this.props.iconStyle} source={this.props.icon} />
        </View>
      );
    }
  }

  render() {
    if (this.state.visible) {
      const backgroundColor = this.backgroundVisible.interpolate({
        inputRange: [0, 1],
        outputRange: ["#00000000", "#00000066"],
      });

      const modalStyle = {
        transform: [{ scale: this.scale }],
        backgroundColor: backgroundColor,
      };

      return (
        <Animated.View
          style={[
            styles.container,
            modalStyle,
            this.props.huge ? styles.hugeContainer : {},
          ]}
          pointerEvents={this.state.pointerEvents}
        >
          {this.renderIcon()}
          <View
            style={[
              styles.boxContainer,
              this.props.showIcon ? {} : { paddingTop: 30 },
              this.props.huge ? styles.hugeBoxContainer : {},
            ]}
          >
            <TouchableOpacity
              disabled={!this.props.showCloseButton}
              onPress={this.props.onClosePress}
              style={[
                styles.closeContainer,
                !this.props.showCloseButton ? { opacity: 0 } : {},
              ]}
            >
              <Image style={styles.close} source={{ uri: Images.close }} />
            </TouchableOpacity>
            {this.props.children}
          </View>
        </Animated.View>
      );
    }

    return <View style={{ width: 0, height: 0 }} />;
  }
}

const styles = {
  container: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    padding: 33,
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    // flexDirection: 'row'
  },
  hugeContainer: {
    justifyContent: "flex-start",
  },
  iconContainer: {
    backgroundColor: Colors.primary,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: -40,
    // position: 'absolute',
    width: 80,
    height: 80,
    borderRadius: 40,
    borderColor: "white",
    borderWidth: 5,
    zIndex: 100,
  },
  icon: {
    width: 46,
    height: 42,
  },
  boxContainer: {
    backgroundColor: "white",
    minWidth: 450,
    maxWidth: 600,
    borderRadius: 10,
    paddingTop: 60,
    justifyContent: "center",
    alignItems: "center",
  },
  hugeBoxContainer: {
    minWidth: "100%",
    minHeight: "80vh",
  },
  closeContainer: {
    position: "absolute",
    right: 10,
    top: 10,
  },
  close: {
    width: 23,
    height: 23,
  },
};
