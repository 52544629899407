import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Picker,
  Platform,
} from "react-native";
import { connect } from "react-redux";
import { Colors, Fonts } from "../themes";
import { LIST_RESTAURANTS } from "../constants";

class DynamicLinksScreen extends Component {
  static defaultProps = {};

  state = {
    loading: true,
    type: "open",
    restaurant: "",
    restaurants: [],
    link: null,
    copied: false,
  };

  componentDidMount() {
    this.props.load((restaurants) => {
      this.setState({ loading: false, restaurants });
    });
  }

  gerarLink() {
    const { type } = this.state;
    var link =
      "https://guava.page.link/?link=http://groubie.com?[PARAMETERS]&apn=com.groubie.app&isi=922608747&ibi=com.groubie.Groubie&ipbi=com.groubie.Groubie&efr=1";
    if (type === "open" || type === "go") {
      link = link.replace("[PARAMETERS]", `tipo=${type}`);
    } else if (type === "restaurant") {
      link = link.replace(
        "[PARAMETERS]",
        `restaurant_id=${this.state.restaurant}&tipo=${type}`
      );
    } else if (type === "reserve") {
    }

    this.setState({ link });
  }

  copiarLink() {
    if (Platform.OS === "web") {
      var textarea = document.createElement("textarea");
      textarea.textContent = this.state.link;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      this.setState({ copied: true });
    }
  }

  renderType() {
    return (
      <View style={{ marginRight: 10 }}>
        <Text style={[styles.section]}>Tipo do Link</Text>
        <Picker
          style={{ fontSize: 14 }}
          selectedValue={this.state.type}
          onValueChange={(value) => {
            this.setState({ type: value, copied: false, link: null });
          }}
        >
          <Picker.Item
            label="Abrir App"
            value="open"
            style={{ fontSize: 14 }}
          />
          <Picker.Item
            label="Abrir Perfil do Restaurante"
            value="restaurant"
            style={{ fontSize: 14 }}
          />
          <Picker.Item
            label="Assinar Groubie Go!"
            value="go"
            style={{ fontSize: 14 }}
          />
          {/* <Picker.Item label="Reserva" value="reserve" style={{ fontSize: 14 }} /> */}
        </Picker>
      </View>
    );
  }

  renderRestaurants() {
    if (this.state.type === "restaurant") {
      return (
        <View style={{ marginRight: 10 }}>
          <Text style={[styles.section]}>Restaurante</Text>
          <Picker
            style={{ fontSize: 14 }}
            selectedValue={this.state.restaurant}
            onValueChange={(value) => {
              this.setState({ restaurant: value });
            }}
          >
            {this.state.restaurants.map((restaurant, index) => {
              return (
                <Picker.Item
                  label={restaurant.name}
                  value={restaurant.restaurant_id}
                  style={{ fontSize: 14 }}
                />
              );
            })}
          </Picker>
        </View>
      );
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Gerador de Dynamic Links</Text>
        {this.state.loading && (
          <ActivityIndicator
            style={{ alignSelf: "center" }}
            size="large"
            color={Colors.primary_dark}
          />
        )}
        {!this.state.loading && (
          <View
            style={{ flexDirection: "row", width: "auto", marginBottom: 20 }}
          >
            {this.renderType()}
            {this.renderRestaurants()}
            <View style={{ marginRight: 10, marginTop: 20 }}>
              <TouchableOpacity
                onPress={this.gerarLink.bind(this)}
                style={{
                  backgroundColor: Colors.primary,
                  height: 45,
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Text
                  style={[
                    styles.section,
                    { color: Colors.white, marginBottom: 0 },
                  ]}
                >
                  Gerar Link
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}

        {this.state.link && (
          <View
            style={{ flexDirection: "row", width: "auto", marginBottom: 20 }}
          >
            <View style={{ marginRight: 10, marginTop: 20 }}>
              <TouchableOpacity
                onPress={this.copiarLink.bind(this)}
                style={{
                  backgroundColor: this.state.copied
                    ? Colors.primary_dark
                    : Colors.primary,
                  height: 45,
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Text
                  style={[
                    styles.section,
                    { color: Colors.white, marginBottom: 0 },
                  ]}
                >
                  {this.state.copied ? "Copiado" : "Copiar Link"}
                </Text>
              </TouchableOpacity>
            </View>
            <Text style={[styles.title, { fontSize: 12, marginTop: 35 }]}>
              {this.state.link}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 22,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginBottom: 30,
  },
  section: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.text,
    marginBottom: 5,
  },
});

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    load: (onSuccess) => {
      dispatch({ type: LIST_RESTAURANTS, onSuccess });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicLinksScreen);
