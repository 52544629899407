import { OPTIONS_REQUEST, OPTIONS_SUCCESS, OPTIONS_FAIL, AVALIACAO_FAIL, AVALIACAO_SUCCESS, AVALIACAO_REQUEST } from "../constants";

const initialState = {
    loading: false,
    options: [],
    scores: [],

    badge: 0,
};

const getStateConditionalScreen = (action, state) => {
    var newState = {};
    if (action.screen === "avaliacao") {
        newState = { ...state };
    }
    return newState;
}

export const avaliacao = (state = initialState, action) => {
    switch (action.type) {
        case OPTIONS_REQUEST:
            return { ...state, ...getStateConditionalScreen(action, { loading: true, reservations: [], options: [] }) }
        case OPTIONS_SUCCESS:
            return { ...state, ...getStateConditionalScreen(action, { loading: false, options: action.options }) }
        case OPTIONS_FAIL:
            return { ...state, ...getStateConditionalScreen(action, { loading: false, options: [] }) }
        case AVALIACAO_REQUEST:
            return { ...state, loading: true, scores: [] }
        case AVALIACAO_SUCCESS:
            return { ...state, loading: false, scores: action.scores }
        case AVALIACAO_FAIL:
            return { ...state, loading: false, scores: [] }
        default:
            return state;
    }
};