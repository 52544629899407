import { StyleSheet } from "react-native";
import Fonts from "./Fonts";
// import Colors from './Colors'

export default StyleSheet.create({
  picker: {
    fontFamily: Fonts.type.regular,
    fontSize: 18,
  },
  pickerItem: {
    fontFamily: Fonts.type.regular,
    fontSize: 18,
  },
});
