import { call, put } from "redux-saga/effects";
import {
  RESERVATION_LIST_SUCCESS,
  RESERVATION_LIST_FAIL,
  RESERVATION_SAVE_SUCCESS,
  RESERVATION_SAVE_FAIL,
  ACOMPANHAMENTO_SUCCESS,
  ACOMPANHAMENTO_FAIL,
  // RESTAURANT_REQUEST,
} from "../constants";

export function* reservation(api, action) {
  try {
    const response = yield call(api.reservations, action.dates);
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: RESERVATION_LIST_SUCCESS,
      reservations: response.data.reservations,
      last_days: response.data.last_days,
    });
  } catch (e) {
    console.error(e);
    yield put({ type: RESERVATION_LIST_FAIL });
  }
}

export function* contestacao(api, action) {
  try {
    const { id, problem, seats, contacted, image, onSuccess } = action;
    const response = yield call(
      api.post_contestacao,
      id,
      problem,
      seats,
      contacted,
      image
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    if (onSuccess) onSuccess(response.data);
    yield put({ type: RESERVATION_SAVE_SUCCESS });
  } catch (e) {
    console.error(e);
    if (action.onFail) action.onFail();
    yield put({ type: RESERVATION_SAVE_FAIL });
  }
}

export function* acompanhamento(api, action) {
  try {
    const { mes, ano, initial, final, city_id } = action;
    const response = yield call(
      api.acompanhamento,
      mes,
      ano,
      initial,
      final,
      city_id
    );
    if (response.problem !== null) {
      throw new Error("");
    }
    yield put({
      type: ACOMPANHAMENTO_SUCCESS,
      acompanhamento: response.data.acompanhamento,
    });
  } catch (e) {
    yield put({ type: ACOMPANHAMENTO_FAIL });
  }
}

export function* acompanhamento_range(api, action) {
  try {
    const { initial, final, city_id, onSuccess } = action;
    const response = yield call(
      api.acompanhamento_range,
      initial,
      final,
      city_id
    );
    if (response.problem !== null) {
      throw new Error("");
    }

    onSuccess(response.data.restaurants);
  } catch (e) {
    action.onSuccess([]);
  }
}
