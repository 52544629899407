import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Animated,
  Image,
  TouchableOpacity,
  Switch,
  ActivityIndicator,
  Platform,
} from "react-native";
import Input from "../components/Input";
import { Fonts, Colors, Images } from "../themes";
import Lightbox from "react-images";
import { MEDIA_URL } from "../constants/server";

const OPTION_HEIGHT = 27;
const OPTIONS_COUNT = 4;
const OPTIONS_COLORS = [
  Colors.primary,
  Colors.danger,
  Colors.accent,
  Colors.accent,
];
const OPTIONS_DARK_COLORS = [
  Colors.primary_dark,
  Colors.danger_dark,
  Colors.accent_dark,
  Colors.accent_dark,
];

class Contestacao extends Component {
  static defaultProps = {
    reservation: {},
    restaurant: {},
    onSave: () => {},
  };

  state = {
    selected: 0,
    seats: 0,
    contacted: false,
    print: "",
    saved_date: "",
    show_image: false,
    show_input: false,
    loading: false,
    error: false,
    touched: false,
    heightAnimation: new Animated.Value(OPTION_HEIGHT),
  };

  componentDidMount() {
    if (Platform.OS === "web") {
      document.body.addEventListener(
        "click",
        (e) => {
          if (this.state.heightAnimation._value > OPTION_HEIGHT) {
            this.onDropdownClick();
          }
        },
        true
      );
    }
    var selected = 0;
    if (this.props.reservation.problem === 3) {
      selected = 1;
    }
    if (this.props.reservation.problem === 1) {
      selected = 2;
    }
    if (this.props.reservation.problem === 2) {
      selected = 3;
    }

    var print =
      this.props.reservation.print && this.props.reservation.print.length > 0
        ? `${MEDIA_URL}${this.props.reservation.print}`
        : "";
    this.setState({
      contacted: this.props.reservation.contacted,
      selected,
      print,
      saved_date: this.props.reservation.saved,
    });
  }

  save() {
    this.setState({ loading: true }, () => {
      var problem = 0;
      if (this.state.selected === 1) {
        problem = 3;
      }
      if (this.state.selected === 2) {
        problem = 1;
      }
      if (this.state.selected === 3) {
        problem = 2;
      }

      this.props.onSave(
        this.props.reservation.id,
        problem,
        this.state.seats,
        this.state.contacted,
        this.state.print,
        (data) => {
          this.setState({
            loading: false,
            error: false,
            touched: false,
            saved_date: data.saved,
          });
        },
        () => {
          this.setState({ loading: false, error: true });
        }
      );
    });
  }

  fileChangedHandler = (event) => {
    const file = event.target.files[0];

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({ print: reader.result, touched: true });
      };
      reader.onerror = (error) => {
        console.log("Error: ", error);
      };
    }
  };

  onSelect(index) {
    this.setState(
      {
        selected: index,
        touched: this.state.selected !== index ? true : this.state.touched,
      },
      () => {
        this.onDropdownClick();
      }
    );
  }

  onDropdownClick() {
    var value = OPTION_HEIGHT * OPTIONS_COUNT;
    if (this.state.heightAnimation._value > OPTION_HEIGHT) {
      value = OPTION_HEIGHT;
      this.setState({
        show_input: this.state.selected === 2 || this.state.selected === 3,
        seats: 0,
      });
    }

    Animated.parallel([
      Animated.spring(this.state.heightAnimation, {
        toValue: value,
      }),
    ]).start();
  }

  renderInput() {
    if (this.state.show_input) {
      return (
        <React.Fragment>
          <View style={styles.absoluteContainer} />
          <View style={styles.modalContainer}>
            <Input
              title="Digite o número de pessoas presentes"
              type="number"
              inputHeight={30}
              style={{ marginTop: 0 }}
              onChangeValue={(value) =>
                this.setState({ seats: parseInt(value), touched: true })
              }
            />
            <View style={{ flexDirection: "row" }}>
              <TouchableOpacity
                disabled={this.state.seats === 0}
                style={[
                  styles.modalButton,
                  { marginRight: 5, opacity: this.state.seats === 0 ? 0.3 : 1 },
                ]}
                onPress={() => {
                  this.setState({ show_input: false });
                }}
              >
                <Text style={styles.modalButtonText}>OK</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, { marginLeft: 5 }]}
                onPress={() => {
                  this.setState({ show_input: false });
                  this.onSelect(0);
                }}
              >
                <Text style={styles.modalButtonText}>Cancelar</Text>
              </TouchableOpacity>
            </View>
          </View>
        </React.Fragment>
      );
    }
  }

  render() {
    const dropdownStyle = {
      position: "absolute",
      overflow: "hidden",
      top: 8,
      right: 10,
      borderRadius: 3,
      backgroundColor: OPTIONS_COLORS[this.state.selected],
      height: this.state.heightAnimation,
      zIndex: 3000,
    };

    const downTextStyle = {
      color: OPTIONS_DARK_COLORS[this.state.selected],
    };

    const dropdownContainerStyle = {
      // marginRight: 15,
      transform: [
        {
          translateY: this.state.heightAnimation.interpolate({
            inputRange: [OPTION_HEIGHT, OPTION_HEIGHT * OPTIONS_COUNT],
            outputRange: [-1 * OPTION_HEIGHT * this.state.selected, 0],
          }),
        },
      ],
    };

    const downStyle = {
      tintColor: OPTIONS_DARK_COLORS[this.state.selected],
      transform: [
        {
          rotate: this.state.heightAnimation.interpolate({
            inputRange: [OPTION_HEIGHT, OPTION_HEIGHT * OPTIONS_COUNT],
            outputRange: ["0deg", "180deg"],
          }),
        },
      ],
    };

    const { reservation } = this.props;

    return (
      <View
        style={[styles.container, { height: this.state.touched ? 180 : 135 }]}
      >
        <View style={styles.itemContainer}>
          <Text style={styles.title}>{reservation.description}</Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.itemContainer}>
          <Text style={styles.title}>{reservation.phone}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text
              style={[
                styles.title,
                { opacity: this.state.contacted ? 0.3 : 1 },
              ]}
            >
              Não Encontrado
            </Text>
            <Switch
              style={{ marginLeft: 10, marginRight: 10 }}
              activeTrackColor={Colors.accent_light}
              activeThumbColor={Colors.accent_dark}
              value={this.state.contacted}
              onValueChange={() =>
                this.setState({
                  contacted: !this.state.contacted,
                  touched: true,
                })
              }
            />
            <Text
              style={[
                styles.title,
                { opacity: this.state.contacted ? 1 : 0.3 },
              ]}
            >
              Contactado
            </Text>
          </View>
        </View>
        <View style={styles.divider} />
        <View style={styles.itemContainer}>
          <Text
            style={[
              styles.title,
              { marginTop: this.state.saved_date.length > 0 ? 10 : 0 },
            ]}
          >
            Print de Comprovação
          </Text>
          <View style={{ flexDirection: "row" }}>
            <TouchableOpacity
              style={styles.anexarButton}
              onPress={() => this.file.click()}
            >
              <Image
                source={{ uri: Images.upload }}
                style={{ height: 10, width: 11, marginRight: 10 }}
              />
              <Text style={[styles.title, { fontSize: 11 }]}>
                {this.state.print && this.state.print.length > 0
                  ? "editar"
                  : "anexar arquivo de imagem"}
              </Text>
              {Platform.OS === "web" && (
                <input
                  style={{ display: "none" }}
                  ref={(ref) => (this.file = ref)}
                  type="file"
                  className={styles.photoButton}
                  onChange={(event) => this.fileChangedHandler(event)}
                ></input>
              )}
            </TouchableOpacity>
            {this.state.print !== undefined && this.state.print.length > 0 && (
              <TouchableOpacity
                onPress={() => this.setState({ show_image: true })}
                style={[
                  styles.anexarButton,
                  { marginLeft: 10, backgroundColor: Colors.accent },
                ]}
              >
                <Image
                  source={{ uri: Images.expand }}
                  style={{ height: 10, width: 10, marginRight: 10 }}
                />
                <Text
                  style={[styles.title, { fontSize: 11, color: Colors.white }]}
                >
                  visualizar imagem
                </Text>
              </TouchableOpacity>
            )}
          </View>
        </View>

        {this.state.saved_date.length > 0 && (
          <Text
            style={[
              styles.title,
              {
                width: "95%",
                textAlign: "right",
                marginBottom: 5,
                fontSize: 9,
                color: Colors.primary_dark,
              },
            ]}
          >
            {this.state.saved_date}
          </Text>
        )}

        {this.state.touched && (
          <React.Fragment>
            <View style={styles.divider} />
            <View
              style={{
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
                width: "95%",
                flexDirection: "row",
              }}
            >
              {this.state.loading && (
                <ActivityIndicator
                  style={{ marginRight: 10 }}
                  size="large"
                  color={Colors.primary_dark}
                />
              )}
              {this.state.error && (
                <Text
                  style={[
                    styles.title,
                    { color: Colors.danger, marginRight: 10 },
                  ]}
                >
                  Erro ao salvar Contestação
                </Text>
              )}
              <TouchableOpacity
                onPress={this.save.bind(this)}
                style={{
                  height: 35,
                  width: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.primary,
                  borderRadius: 5,
                }}
              >
                <Text style={[styles.title, { color: Colors.white }]}>
                  Salvar
                </Text>
              </TouchableOpacity>
            </View>
          </React.Fragment>
        )}

        <Animated.View style={dropdownStyle}>
          <Animated.View style={dropdownContainerStyle}>
            <TouchableOpacity
              style={styles.optionButton}
              onPress={() => this.onSelect(0)}
            >
              <Animated.Text style={[styles.optionText, downTextStyle]}>
                Comparecida
              </Animated.Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionButton}
              onPress={() => this.onSelect(1)}
            >
              <Animated.Text style={[styles.optionText, downTextStyle]}>
                Não comparecida
              </Animated.Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.optionButton}
              onPress={() => this.onSelect(2)}
            >
              <Animated.Text style={[styles.optionText, downTextStyle]}>
                Mais pessoas
              </Animated.Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.optionButton]}
              onPress={() => this.onSelect(3)}
            >
              <Animated.Text style={[styles.optionText, downTextStyle]}>
                Menos pessoas
              </Animated.Text>
            </TouchableOpacity>
          </Animated.View>
          <Animated.Image
            pointerEvents="none"
            source={{ uri: Images.down }}
            style={[
              downStyle,
              { width: 12, height: 8, position: "absolute", right: 10, top: 9 },
            ]}
          />
        </Animated.View>

        {this.renderInput()}
        {Platform.OS === "web" && (
          <Lightbox
            images={[{ src: this.state.print ? this.state.print : "" }]}
            isOpen={this.state.show_image}
            onClose={() => this.setState({ show_image: false })}
          />
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 135,
    borderWidth: 1,
    borderRadius: 3,
    overflow: "hidden",
    borderColor: Colors.divider,
    alignItems: "center",
    marginBottom: 5,
  },
  title: {
    fontFamily: Fonts.type.regular,
    fontSize: 15,
    color: Colors.text,
  },
  itemContainer: {
    flex: 1,
    width: "95%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: -1,
  },
  divider: {
    width: "95%",
    height: 1,
    backgroundColor: Colors.divider,
  },
  optionButton: {
    height: OPTION_HEIGHT,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 20,
    paddingRight: 35,
  },
  optionText: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.text,
  },
  absoluteContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: "black",
    opacity: 0.3,
    justifyContent: "center",
    alignItems: "center",
  },
  modalContainer: {
    position: "absolute",
    left: "25%",
    right: 0,
    top: "10%",
    bottom: 0,
    width: "50%",
    height: "80%",
    backgroundColor: Colors.white,
    borderWidth: 2,
    borderRadius: 5,
    overflow: "hidden",
    borderColor: Colors.divider,
    paddingLeft: 10,
    paddingRight: 10,
    justifyContent: "space-around",
    // alignItems: 'center'
  },
  modalButton: {
    flex: 1,
    backgroundColor: Colors.accent,
    borderRadius: 3,
    height: 30,
    marginBottom: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  modalButtonText: {
    fontFamily: Fonts.type.medium,
    fontSize: 12,
    color: Colors.white,
  },
  anexarButton: {
    height: 22,
    borderRadius: 11,
    paddingLeft: 10,
    paddingRight: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.fill,
  },
});

export default Contestacao;
