import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  ActivityIndicator,
  Picker,
} from "react-native";
import { connect } from "react-redux";
import Block from "../components/Block";
import { Colors, Fonts } from "../themes";
import moment from "moment";
import {
  ACOMPANHAMENTO_REQUEST,
  RESTAURANT_REQUEST,
  ACOMPANHAMENTO_RANGE_REQUEST,
} from "../constants";

// const csvData = [
//   ["firstname", "lastname", "email"],
//   ["Ahmed", "Tomi", "ah@smthing.co.com"],
//   ["Raed", "Labes", "rl@smthing.co.com"],
//   ["Yezzi", "Min l3b", "ymin@cocococo.com"],
// ];

class AcompanhamentoScreen extends Component {
  static defaultProps = {};

  state = {
    cidade: 1,
    ano: 2018,
    mes: 1,
    loadings: new Array(10),
    restaurants: new Array(10),
  };

  constructor(props) {
    super(props);
    this.meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    this.anos = [];

    var current_year = parseInt(moment(new Date()).format("YYYY"));
    var i = 0;
    for (i = 0; i < 11; i++) {
      this.anos.push(current_year - i);
    }
  }

  componentDidMount() {
    this.props.loadCities();
  }

  load() {
    this.props.load(
      this.state.mes,
      this.state.ano,
      this.initial_date,
      this.final_date,
      this.state.cidade
    );
  }

  onRangeClick(range, index) {
    const loadings = this.state.loadings;
    loadings[index] = true;
    this.setState({ loadings: [...loadings] }, () => {
      this.props.load_range(
        range.initial_complete,
        range.final_complete,
        this.state.cidade,
        (data) => {
          const loadings = this.state.loadings;
          loadings[index] = false;
          const restaurants = this.state.restaurants;
          restaurants[index] = data;
          this.setState({
            loadings: [...loadings],
            restaurants: [...restaurants],
          });
        }
      );
    });
  }

  renderItem(item, isHeader, isMonth, totalWeeks, range) {
    var {
      taxa,
      estabelecimento,
      seg,
      ter,
      qua,
      qui,
      sex,
      sab,
      dom,
      total_seats,
      total,
      weeks,
    } = item;

    if (isHeader) {
      taxa = "TAXA";
      estabelecimento = "ESTABELECIMENTO";
      seg = "S";
      ter = "T";
      qua = "Q";
      qui = "Q";
      sex = "S";
      sab = "S";
      dom = "D";
      total = "TOTAL";
      weeks = [];
      var i = 0;
      for (i = 0; i < totalWeeks - 1; i++) {
        weeks.push(`SEM ${i + 1}`);
      }
      weeks.push("MÊS");
    } else {
      total = total_seats * taxa;
      if (weeks.length < totalWeeks) {
        var k = 0;
        const totalAdd = totalWeeks - weeks.length;
        const month = weeks.splice(-1, 1);
        for (k = 0; k < totalAdd; k++) {
          weeks.push(0);
        }
        weeks.push(month);
      }
    }

    const itemStyle = [styles.item, isHeader ? styles.header : {}];
    const textStyle = [styles.text];

    return (
      <View style={[styles.row, { marginTop: isHeader ? 20 : 0 }]}>
        <View style={[itemStyle, { width: 62 }]}>
          <Text style={textStyle}>
            {isNaN(taxa) ? taxa : taxa.toFixed(2).replace(".", ",")}
          </Text>
        </View>
        <View
          style={[itemStyle, { flex: 1, flexDirection: "row", width: "100%" }]}
        >
          <Text style={textStyle}>{estabelecimento} </Text>

          {!isHeader && (
            <TouchableOpacity
              onPress={() => {
                window.open(
                  `http://guavadescontos.com/company/contract/report/download/csv?restaurant_id=${item.id}&day_from=${range.initial_complete}&day_to=${range.final_complete}`,
                  "_blank"
                );
              }}
              style={{
                backgroundColor: "transparent",
                height: 45,
                borderRadius: 5,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 20,
                paddingRight: 20,
                position: "absolute",
                right: 10,
              }}
            >
              <Text
                style={[
                  styles.section,
                  { color: Colors.primary_dark, marginBottom: 0 },
                ]}
              >
                Download
              </Text>
            </TouchableOpacity>
          )}
        </View>
        {isMonth ? (
          <React.Fragment>
            {weeks.map((count, index) => {
              return (
                <View style={[itemStyle, { width: 62 }]} key={index}>
                  <Text style={textStyle}>{count}</Text>
                </View>
              );
            })}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <View style={itemStyle}>
              <Text style={textStyle}>{seg}</Text>
            </View>
            <View style={itemStyle}>
              <Text style={textStyle}>{ter}</Text>
            </View>
            <View style={itemStyle}>
              <Text style={textStyle}>{qua}</Text>
            </View>
            <View style={itemStyle}>
              <Text style={textStyle}>{qui}</Text>
            </View>
            <View style={itemStyle}>
              <Text style={textStyle}>{sex}</Text>
            </View>
            <View style={itemStyle}>
              <Text style={textStyle}>{sab}</Text>
            </View>
            <View style={itemStyle}>
              <Text style={textStyle}>{dom}</Text>
            </View>
          </React.Fragment>
        )}

        <View style={[itemStyle, { width: 91 }]}>
          <Text style={textStyle}>
            {isNaN(total) ? total : `R$ ${total.toFixed(2).replace(".", ",")}`}
          </Text>
        </View>
      </View>
    );
  }

  renderFooter(range, restaurants, index) {
    var totalSeats = 0;
    var total = 0;
    restaurants.forEach((e) => {
      totalSeats += e.total_seats;
      total += e.total_seats * e.taxa;
    });

    const diferenceTotalSeatsLastWeek = totalSeats - this.totalSeats;
    const diferenceTotalLastWeek = total - this.total;

    this.totalSeats = totalSeats;
    this.total = total;

    return (
      <React.Fragment>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 30,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                marginRight: 10,
                backgroundColor: Colors.primary,
              }}
            />
            <Text>{`${totalSeats} pessoas ${
              index === 0 ? "no período" : `na SEMANA ${index}`
            } (${range.initial} ATÉ ${range.final})`}</Text>
          </View>
          <View style={{ flexDirection: "row", opacity: 0 }}>
            {index > 0 && (
              <Text>{`${Math.abs(diferenceTotalSeatsLastWeek)} pessoas ${
                diferenceTotalSeatsLastWeek > 0 ? "a mais" : "a menos"
              } que na semana passada`}</Text>
            )}
            {index > 0 && (
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  marginLeft: 10,
                  backgroundColor:
                    diferenceTotalSeatsLastWeek > 0
                      ? Colors.primary
                      : Colors.danger,
                }}
              />
            )}
          </View>
        </View>
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 5,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                width: 16,
                height: 16,
                borderRadius: 8,
                marginRight: 10,
                backgroundColor: Colors.primary,
              }}
            />
            <Text>{`${total.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            })} ${index === 0 ? "no período" : `na SEMANA ${index}`} (${
              range.initial
            } ATÉ ${range.final})`}</Text>
          </View>
          <View style={{ flexDirection: "row", opacity: 0 }}>
            {index > 0 && (
              <Text>{`${Math.abs(diferenceTotalLastWeek).toLocaleString(
                "pt-BR",
                { style: "currency", currency: "BRL" }
              )} ${
                diferenceTotalLastWeek > 0 ? "a mais" : "a menos"
              } que na semana passada`}</Text>
            )}
            {index > 0 && (
              <View
                style={{
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                  marginLeft: 10,
                  backgroundColor:
                    diferenceTotalLastWeek > 0 ? Colors.primary : Colors.danger,
                }}
              />
            )}
          </View>
        </View>
      </React.Fragment>
    );
  }

  render() {
    return (
      <View style={styles.container}>
        <Text style={styles.title}>Acompanhamento das Reservas</Text>

        {this.props.cities_list.length > 0 && (
          <View
            style={{ flexDirection: "row", width: "auto", marginBottom: 20 }}
          >
            <View style={{ marginRight: 10 }}>
              <Text style={[styles.section]}>Cidade</Text>
              <Picker
                style={{ fontSize: 14 }}
                selectedValue={this.props.cidade}
                onValueChange={(value) => {
                  this.setState({ cidade: value }, () => this.load());
                }}
              >
                {this.props.cities_list.map((city, index) => {
                  return (
                    <Picker.Item
                      key={index}
                      label={city.name}
                      value={city.id}
                      style={{ fontSize: 14 }}
                    />
                  );
                })}
              </Picker>
            </View>
            {/* <View style={{ marginRight: 10 }}>
                            <Text style={[styles.section]}>Mês</Text>
                            <Picker style={{ fontSize: 14 }} selectedValue={this.state.mes} onValueChange={(value) => { this.setState({ mes: value }, () => this.load()) }}>
                                {this.meses.map((mes, index) => {
                                    return (
                                        <Picker.Item key={index} label={mes} value={index + 1} style={{ fontSize: 14 }} />
                                    );
                                })}
                            </Picker>
                        </View>
                        <View style={{ marginRight: 10 }}>
                            <Text style={[styles.section]}>Ano</Text>
                            <Picker style={{ fontSize: 14 }} selectedValue={this.state.ano} onValueChange={(value) => { this.setState({ ano: value }, () => this.load()) }}>
                                {this.anos.map((ano, index) => {
                                    return (
                                        <Picker.Item key={index} label={ano} value={ano} style={{ fontSize: 14 }} />
                                    );
                                })}
                            </Picker>
                        </View> */}

            <View style={{ marginRight: 10 }}>
              <Text style={[styles.section]}>Data Inicial</Text>
              <input
                type="date"
                onChange={(event) => (this.initial_date = event.target.value)}
              />
            </View>
            <View style={{ marginRight: 10 }}>
              <Text style={[styles.section]}>Data Final</Text>
              <input
                type="date"
                onChange={(event) => (this.final_date = event.target.value)}
              />
            </View>

            <View style={{ marginRight: 10, marginTop: 20 }}>
              <TouchableOpacity
                onPress={() => this.load()}
                style={{
                  backgroundColor: Colors.primary,
                  height: 45,
                  borderRadius: 5,
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: 20,
                  paddingRight: 20,
                }}
              >
                <Text
                  style={[
                    styles.section,
                    { color: Colors.white, marginBottom: 0 },
                  ]}
                >
                  Filtrar
                </Text>
              </TouchableOpacity>
            </View>
          </View>
        )}

        {this.props.loading && (
          <ActivityIndicator
            style={{ alignSelf: "center" }}
            size="large"
            color={Colors.primary_dark}
          />
        )}

        {this.props.acompanhamento.map((range, index) => {
          return (
            <Block
              key={index}
              animation="timing"
              title={
                index === 0
                  ? `FATURAMENTO (${range.initial} ATÉ ${range.final})`
                  : `SEMANA ${index} (${range.initial} ATÉ ${range.final})`
              }
              color={"#CACFD5"}
              style={{ maxWidth: "auto" }}
              onOpenClick={() => this.onRangeClick(range, index)}
            >
              {this.renderItem(
                {},
                true,
                index === 0,
                this.props.acompanhamento.length,
                range
              )}
              {this.state.loadings[index] === true && (
                <ActivityIndicator
                  style={{
                    alignSelf: "center",
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                  size="large"
                  color={Colors.primary_dark}
                />
              )}
              {this.state.loadings[index] !== true &&
                this.state.restaurants[index] &&
                this.state.restaurants[index].length > 0 &&
                this.state.restaurants[index].map((item, itemIndex) => {
                  return (
                    <React.Fragment key={itemIndex}>
                      {this.renderItem(
                        item,
                        false,
                        index === 0,
                        this.props.acompanhamento.length,
                        range
                      )}
                    </React.Fragment>
                  );
                })}
              {this.state.loadings[index] !== true &&
                this.state.restaurants[index] &&
                this.state.restaurants[index].length > 0 &&
                this.renderFooter(range, this.state.restaurants[index], index)}
            </Block>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    padding: 22,
  },
  title: {
    fontFamily: Fonts.type.medium,
    fontSize: 20,
    color: Colors.text,
    marginBottom: 30,
  },
  section: {
    fontFamily: Fonts.type.medium,
    fontSize: 14,
    color: Colors.text,
    marginBottom: 5,
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  item: {
    justifyContent: "center",
    alignItems: "center",
    width: 46,
    height: 54,
    marginRight: 5,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: Colors.divider2,
    backgroundColor: Colors.fill2,
  },
  header: {
    backgroundColor: Colors.fill3,
  },
  text: {
    fontFamily: Fonts.type.bold,
    fontSize: 14,
    color: Colors.text,
  },
});

const mapStateToProps = (state) => ({
  loading: state.reservation.loading || state.restaurant.loading,
  acompanhamento: state.reservation.acompanhamento,
  cities_list: state.restaurant.cities_list,
});

const mapDispatchToProps = (dispatch) => {
  return {
    loadCities: () => {
      dispatch({ type: RESTAURANT_REQUEST, id: 87 });
    },
    load: (mes, ano, initial, final, city_id) => {
      dispatch({
        type: ACOMPANHAMENTO_REQUEST,
        mes,
        ano,
        initial,
        final,
        city_id,
      });
    },
    load_range: (initial, final, city_id, onSuccess) => {
      dispatch({
        type: ACOMPANHAMENTO_RANGE_REQUEST,
        initial,
        final,
        city_id,
        onSuccess,
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcompanhamentoScreen);
