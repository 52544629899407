import { RESERVATION_LIST_REQUEST, RESERVATION_LIST_SUCCESS, RESERVATION_LIST_FAIL, ACOMPANHAMENTO_REQUEST, ACOMPANHAMENTO_FAIL, ACOMPANHAMENTO_SUCCESS } from "../constants";

const initialState = {
    loading: false,
    reservations: [],
    last_days: [],
    acompanhamento: []
};
export const reservation = (state = initialState, action) => {
    switch (action.type) {
        case RESERVATION_LIST_REQUEST:
            return { ...state, loading: true }
        case RESERVATION_LIST_SUCCESS:
            return { ...state, loading: false, reservations: action.reservations, last_days: action.last_days }
        case RESERVATION_LIST_FAIL:
            return { ...state, loading: false, reservations: [] }

        case ACOMPANHAMENTO_REQUEST:
            return { ...state, loading: true, acompanhamento: [] }
        case ACOMPANHAMENTO_SUCCESS:
            return { ...state, loading: false, acompanhamento: action.acompanhamento }
        case ACOMPANHAMENTO_FAIL:
            return { ...state, loading: false, acompanhamento: [] }
        default:
            return state;
    }
};