import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Picker,
  TouchableOpacity,
} from "react-native";
// import styled from "styled-components/native";
import { Fonts, Colors, Images } from "../themes";
import moment from "moment";

class Gos extends Component {
  static defaultProps = {
    onChange: () => {},
  };

  state = {
    dayIndex: 0,
    desconto: 50,
    replicar: [],
    lugares: 4,
    selectedAll: false,
    days: [
      { name: "Segunda", gos: [] },
      { name: "Terça", gos: [] },
      { name: "Quarta", gos: [] },
      { name: "Quinta", gos: [] },
      { name: "Sexta", gos: [] },
      { name: "Sábado", gos: [] },
      { name: "Domingo", gos: [] },
    ],
  };

  constructor(props) {
    super(props);
    this.descontos = [50];
    this.lugares = [];
    this.hours = [];

    for (var i = 1; i <= 50; i++) {
      this.lugares.push(`${i < 10 ? "0" : ""}${i}`);
    }

    var m = moment("00:00", "HH:mm");
    while (true) {
      var hour = m.format("HH:mm");
      if (this.hours.includes(hour)) {
        break;
      } else {
        this.hours.push(hour);
        m = m.add(15, "minutes");
      }
    }
  }

  componentDidUpdate() {
    if (
      this.initial !== this.props.initial &&
      this.props.initial &&
      this.props.initial.length >= 7
    ) {
      this.initial = this.props.initial;
      this.setState({ days: this.initial });
    }
  }

  applyChanges(day) {
    const { days, maximo } = this.state;
    days[day].gos
      .filter((r) => r.selected)
      .forEach((r) => {
        r.maximo = maximo;
      });
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  applyReplication(dayIndex, replicar) {
    const { days } = this.state;
    const day = days[dayIndex];
    replicar.forEach((r) => {
      days[r].gos = day.gos;
    });
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  addReserve(day) {
    const { days } = this.state;

    const lastGo = days[day].gos.length
      ? days[day].gos[days[day].gos.length - 1]
      : null;

    const inicio = lastGo ? lastGo.fim : "12:00";

    const fim = moment(inicio, "HH:mm").add(120, "minutes").format("HH:mm");

    days[day].gos.push({
      inicio,
      fim,
      maximo: 24,
      lugares: 4,
      desconto: 50,
      selected: false,
    });
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  removeReserve(day, index) {
    const { days } = this.state;
    days[day].gos.splice(index, 1);
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  deleteAllReserves(day) {
    const { days } = this.state;
    days[day].gos = days[day].gos.filter((go) => !go.selected);
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  selectAllReserves(day, check) {
    const { days } = this.state;
    days[day].gos.forEach((reserva) => {
      reserva.selected = check;
    });

    this.setState({
      selectedAll: check,
      days: JSON.parse(JSON.stringify(days)),
    });
    this.props.onChange(days);
  }

  selectProperty(day, index, key, value) {
    if (key === "desconto") {
      this.desconto = 50;
    }
    const { days } = this.state;
    days[day].gos[index][key] = value;
    this.setState({ days: JSON.parse(JSON.stringify(days)) });
    this.props.onChange(days);
  }

  changeReplicar(day) {
    const { replicar } = this.state;
    if (replicar.includes(day)) {
      replicar.splice(replicar.indexOf(day), 1);
    } else {
      replicar.push(day);
    }
    this.setState({ replicar: JSON.parse(JSON.stringify(replicar)) });
  }

  renderDays() {
    const { dayIndex, days } = this.state;
    return (
      <View style={styles.daysContainer}>
        {days.map((day, index) => {
          return (
            <TouchableOpacity
              key={index}
              style={[
                styles.dayItem,
                dayIndex === index ? styles.dayItemSelected : {},
                index === days.length - 1 ? { marginRight: 0 } : {},
              ]}
              onPress={() => this.setState({ dayIndex: index, replicar: [] })}
            >
              <Text
                style={[
                  styles.dayTitle,
                  dayIndex === index ? styles.dayTitleSelected : {},
                ]}
              >
                {day.name.toUpperCase()}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  }

  renderGos() {
    const { days, dayIndex, selectedAll } = this.state;
    const gos = days[dayIndex].gos;

    return (
      <View>
        <View style={styles.reservaItem}>
          <View
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              onPress={() => this.selectAllReserves(dayIndex, !selectedAll)}
              style={[
                styles.checkButton,
                selectedAll ? styles.checkButtonSelected : {},
              ]}
            >
              {selectedAll && (
                <Image source={{ uri: Images.check }} style={styles.check} />
              )}
            </TouchableOpacity>
          </View>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            INÍCIO
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            FIM
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            MÁXIMO
          </Text>
          <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            LUGARES
          </Text>
          {/* <Text style={[styles.dayTitle, { textAlign: "center", flex: 3 }]}>
            DESCONTO
          </Text> */}
          <TouchableOpacity
            onPress={() => this.addReserve(dayIndex)}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={{ uri: Images.reserve_add }}
              style={{ width: 18, height: 18 }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => this.deleteAllReserves(dayIndex)}
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              source={{ uri: Images.reserve_remove }}
              style={{ width: 18, height: 18 }}
            />
          </TouchableOpacity>
        </View>
        {gos &&
          gos.length > 0 &&
          gos.map((go, index) => {
            return (
              <View style={styles.reservaItem} key={index} className="reservas">
                <View
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    onPress={() =>
                      this.selectProperty(
                        dayIndex,
                        index,
                        "selected",
                        !go.selected
                      )
                    }
                    style={[
                      styles.checkButton,
                      go.selected ? styles.checkButtonSelected : {},
                    ]}
                  >
                    {go.selected && (
                      <Image
                        source={{ uri: Images.check }}
                        style={styles.check}
                      />
                    )}
                  </TouchableOpacity>
                </View>
                <Picker
                  selectedValue={go.inicio}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(dayIndex, index, "inicio", itemValue)
                  }
                  style={[
                    {
                      color: Colors.text,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.hours.map((hora, index) => {
                    return (
                      <Picker.Item key={index} label={hora} value={hora} />
                    );
                  })}
                </Picker>
                <Picker
                  selectedValue={go.fim}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(dayIndex, index, "fim", itemValue)
                  }
                  style={[
                    {
                      color: Colors.text,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.hours
                    .filter((h, i) => i > this.hours.indexOf(go.inicio))
                    .map((hora, index) => {
                      return (
                        <Picker.Item key={index} label={hora} value={hora} />
                      );
                    })}
                </Picker>
                <Picker
                  selectedValue={go.maximo}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(
                      dayIndex,
                      index,
                      "maximo",
                      parseInt(itemValue)
                    )
                  }
                  style={[
                    {
                      color: Colors.accent_dark,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.lugares.map((lugares, index) => {
                    return (
                      <Picker.Item
                        key={index}
                        label={lugares}
                        value={parseInt(lugares)}
                      />
                    );
                  })}
                </Picker>
                <Text
                  style={[
                    styles.dayTitle,
                    { textAlign: "center", flex: 3, opacity: 0.3 },
                  ]}
                >
                  {go.lugares}
                </Text>
                {/* 
                <Picker
                  selectedValue={go.desconto}
                  onValueChange={(itemValue, itemIndex) =>
                    this.selectProperty(
                      dayIndex,
                      index,
                      "desconto",
                      parseInt(itemValue)
                    )
                  }
                  style={[
                    {
                      color: Colors.accent_dark,
                      minHeight: 25,
                      flex: 3,
                      border: "none",
                      textAlign: "right",
                      marginRight: 10,
                    },
                  ]}
                >
                  {this.descontos.map((desconto, index) => {
                    return (
                      <Picker.Item
                        key={index}
                        label={desconto}
                        value={parseInt(desconto)}
                      />
                    );
                  })}
                </Picker> */}

                <TouchableOpacity
                  onPress={() => this.addReserve(dayIndex)}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{ uri: Images.reserve_add }}
                    style={{ width: 18, height: 18 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => this.removeReserve(dayIndex, index)}
                  style={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    source={{ uri: Images.reserve_remove }}
                    style={{ width: 18, height: 18 }}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
        {(!gos || gos.length === 0) && (
          <Text
            style={[
              styles.subtitle,
              { textAlign: "center", marginTop: 20, marginBottom: 20 },
            ]}
          >
            Nenhum 2 por 1 adicionado neste dia
          </Text>
        )}
      </View>
    );
  }

  renderReplicar() {
    const { days, dayIndex } = this.state;
    return (
      <View style={[styles.descontoContainer, { marginTop: 5 }]}>
        <Text style={[styles.subtitle, { marginLeft: 5, marginRight: 5 }]}>
          Replicar
        </Text>
        <View
          style={[
            styles.descontoItem,
            {
              width: "auto",
              height: 25,
              paddingLeft: 8,
              paddingRight: 8,
              borderRadius: 3,
            },
          ]}
        >
          <Text style={styles.descontoItemTitle}>
            {days[dayIndex].name.toUpperCase()}
          </Text>
        </View>
        <Text style={[styles.subtitle, { marginLeft: 5, marginRight: 5 }]}>
          em
        </Text>
        {days.map((day, index) => {
          if (index !== dayIndex) {
            const isSelected = this.state.replicar.includes(index);
            return (
              <TouchableOpacity
                key={index}
                onPress={() => this.changeReplicar(index)}
                style={[
                  styles.descontoItem,
                  isSelected ? styles.descontoItemSelected : {},
                  {
                    width: "auto",
                    height: 25,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: 3,
                  },
                ]}
              >
                <Text style={styles.descontoItemTitle}>
                  {day.name.toUpperCase()}
                </Text>
              </TouchableOpacity>
            );
          }
          return null;
        })}
        <TouchableOpacity
          style={styles.aplicarButton}
          onPress={() => this.applyReplication(dayIndex, this.state.replicar)}
        >
          <Text style={styles.dayTitle}>Aplicar</Text>
        </TouchableOpacity>
      </View>
    );
  }

  render() {
    return (
      <View style={[styles.container]}>
        {this.renderDays()}
        {this.renderGos()}
        {this.renderReplicar()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    // minWidth: 570,
  },
  daysContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  dayItem: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 34,
    borderRadius: 6,
    minWidth: 70,
    marginBottom: 5,
    marginRight: 2,
    backgroundColor: Colors.fill,
  },
  dayItemSelected: {
    backgroundColor: Colors.accent,
  },
  dayTitle: {
    fontFamily: Fonts.type.medium,
    fontSize: 12,
    color: Colors.text,
  },
  dayTitleSelected: {
    color: Colors.white,
  },
  descontoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 35,
    borderWidth: 2,
    borderRadius: 3,
    borderColor: Colors.border,
    backgroundColor: Colors.fill,
  },
  listaDescontoContainer: {
    display: "flex",
    flexDirection: "row",
    minWidth: 235,
    justifyContent: "center",
    alignItems: "center",
  },
  subtitle: {
    fontFamily: Fonts.type.regular,
    fontSize: 12,
    color: Colors.text,
  },
  descontoItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 18,
    width: 29,
    marginLeft: 3,
    borderRadius: 1,
    backgroundColor: Colors.accent,
  },
  descontoItemSelected: {
    paddingTop: 4,
    borderBottomWidth: 4,
    borderColor: Colors.accent_dark,
  },
  descontoItemTitle: {
    fontFamily: Fonts.type.medium,
    fontSize: 10,
    color: Colors.white,
  },
  aplicarButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    height: 35,
    minWidth: 40,
    marginLeft: 3,
    borderLeftWidth: 2,
    borderColor: Colors.border,
  },
  reservaItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 35,
    borderWidth: 2,
    borderColor: Colors.border,
    borderRadius: 3,
    marginTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  checkButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 18,
    height: 18,
    borderRadius: 3,
    backgroundColor: Colors.fill,
  },
  checkButtonSelected: {
    backgroundColor: Colors.primary,
  },
  check: {
    width: 11,
    height: 8,
  },
});

export default Gos;
